import React, {ChangeEvent} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {Box, IconButton, TextField} from "@mui/material";
import {SimCard} from "../../modules/simcard/models/Models";
import {downloadExcelFile} from "../xlsx/xlsxExport";
import ItemListFile, {filterByUploadedItems} from "../../modules/simcard/components/upload/ItemListFile";
import {GridApiRef, GridToolbarColumnsButton, GridToolbarFilterButton, useGridApiContext} from "@mui/x-data-grid";

interface ToolbarWithQuickSearchProps {
    // Строка поиска
    searchText: string
    // Очистка поля ввода живого поиска
    clearSearch: () => void
    // Живой поиск при вводе
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    // Скачивание всех (отфильтрованных) записей на главной
    onClickDownloadButton: (apiRef: GridApiRef) => void
    // Фильтрация по списку номеров или симкарт
    onFileSelect: (uploadedItems: string[]) => void
}

interface IToolbarWithQuickSearchConfig {
    searchText: string
    searchData: any[]
    hiddenCols: (string | null) []
    setRows: (rows: any[]) => void
    setSearchText: (searchText: string) => void
    filteredSimCardList: SimCard[]
}

interface IGridToolbarDownloadButtonProps {
    onClick: () => void
}

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

/**
 * Склеивает элементы массива в строку для обеспечения поиска
 *
 * @param array
 */
const reduceArrayToString = (array: any[]) =>
    array.reduce((previous, item) => {
        let output: string = ''
        for (const param in item) {
            // По полю id не ищем
            if (param !== 'id') {
                output += item[param]?.toString()
            }
        }
        return previous + output
    }, '')

const requestSearch = ({searchText, searchData, hiddenCols, setRows, setSearchText}: IToolbarWithQuickSearchConfig) => {
    setSearchText(searchText);

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
    const filteredRows = searchData?.filter((row: any) => {
        return Object.keys(row).some((field: any) => {
            // По полю status (активность сим-карты) не ищем
            if (field !== 'status' && hiddenCols.indexOf(field) === -1) {
                if (Array.isArray(row[field])) {
                    return searchRegex.test(reduceArrayToString(row[field]));
                }
                return searchRegex.test(row[field]?.toString())
            }
            return false
        });
    });
    setRows(filteredRows);
};

export const toolbarConfig = ({searchText, searchData, hiddenCols, setRows, setSearchText, filteredSimCardList}: IToolbarWithQuickSearchConfig): ToolbarWithQuickSearchProps => {
    return {
        searchText,
        onChange: (event) => requestSearch({
            searchText: event.target.value,
            searchData,
            hiddenCols,
            setRows,
            setSearchText,
            filteredSimCardList
        }),
        clearSearch: () => {
            setSearchText('');
            setRows(searchData)
        },
        onClickDownloadButton: (apiRef) => downloadExcelFile({apiRef, simCardList: filteredSimCardList}),
        onFileSelect: (uploadedItems) => filterByUploadedItems({
            uploadedItems,
            currentRows: searchData,
            setRows})
    }
}

/** Опции выгрузки в CSV-формат (отключена в lk_abon_610) */
// const csvOptions: GridCsvExportOptions = {
//     // Вывод только тех колонок, которые указаны в поле fields ниже
//     allColumns: true,
//     // Для обеспечения вывода на русском языке
//     utf8WithBom: true,
//     delimiter: ';',
//     fileName: 'Список SIM-карт',
//     fields: [
//         'msisdn',
//         'balance',
//         'moboperator',
//         'status',
//         'tarif',
//         'device_name',
//         'traffic',
//         'device_address',
//         'imsi',
//         'imei',
//         'iccid',
//         'apn',
//         'ip',
//         'activation_date',
//         'last_activity_ts',
//         'groups',
//         'description'
//     ]
// }

/**
 * Кнопка выгрузки на главной
 * Она hardcoded, поскольку вставляется в mui-компонент.
 *
 * @param simCardList
 * @constructor
 */
export function GridToolbarDownloadButton({onClick}: IGridToolbarDownloadButtonProps) {
    return <button
        className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  ExcelExport-MuiButtonBase-root-MuiButton-root"
        type="button" onClick={onClick}><span
        className="MuiButton-startIcon MuiButton-iconSizeSmall ExcelExport-MuiButton-startIcon"><svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium ExcelExport-MuiSvgIcon-root" focusable="false"
        viewBox="0 0 24 24" aria-hidden="true" data-testid="SaveAltIcon"><path
        d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"></path></svg></span>Экспорт<span
        className="MuiTouchRipple-root ExcelExport-MuiTouchRipple-root"></span></button>
}

/**
 * Панель инструментов с живым поиском
 */
export function ToolbarWithQuickSearch({searchText, onChange, clearSearch, onClickDownloadButton, onFileSelect}: ToolbarWithQuickSearchProps) {
    const apiRef = useGridApiContext();

    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
            }}
        >
            <div style={{display: "flex"}}>
                <TextField
                    variant="standard"
                    value={searchText}
                    onChange={onChange}
                    placeholder="Живой поиск…"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small"/>,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{visibility: searchText ? 'visible' : 'hidden'}}
                                onClick={clearSearch}
                            >
                                <ClearIcon fontSize="small"/>
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: {
                            xs: 1,
                            sm: 'auto',
                        },
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0.5,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                        '& .MuiInput-root': {
                            fontSize: 'unset',
                            paddingBottom: '5px',
                            marginTop: '-4px'
                        },
                    }}
                />

                <ItemListFile onFileSelect={(uploadedItems) => onFileSelect(uploadedItems)}/>
            </div>

            <div style={{paddingTop: "10px"}}>
                <GridToolbarFilterButton/>
                <GridToolbarColumnsButton/>
                {/*<GridToolbarExport/>*/}
                <GridToolbarDownloadButton onClick={() => onClickDownloadButton(apiRef)}/>
            </div>
        </Box>
    );
}
