/* eslint-disable jsx-a11y/anchor-is-valid */
import {listContact} from "../api";
import React, {useEffect, useState} from 'react'
import {ThemeProvider} from "@mui/material";
import {SectionHeader} from "../SectionHeader";
import {SimCardContact} from "../models/Models";
import {PageTitle} from "../../../../_metronic/layout/core";
import {CreateContact} from "./create/CreateContact";
import {DeleteContact} from "./delete/DeleteContact";
import {EditContact} from "./edit/EditContact";
import AlertDismissible from "../../../components/alerts/AlertDismissible";
import {getColumns, theme} from "./SimCardContactListDataGridConfig";
import {DataGrid, GridCellParams, GridSelectionModel, ruRU} from "@mui/x-data-grid";

export function SimCardContactListPage() {
    const [refresh, setRefresh] = useState(Date.now())
    const [message, setMessage] = useState('')
    const [showEditForm, setShowEditForm] = useState(false)
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [contactToEdit, setContactToEdit] = useState<SimCardContact | undefined>(undefined)
    const [contactList, setContactList] = useState<SimCardContact[] | undefined>([])

    useEffect(() => {
        const getResponse = async () => {
            const response = await listContact()
            switch (response.status) {
                case "ok":
                    setContactList(response.data.data.items as SimCardContact[])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getResponse()
    }, [refresh])

    const editContact = (params: GridCellParams) => {
        // Разрешаем редактирование лишь при клике по названию контакта
        if (params.field === 'name') {
            setContactToEdit(params.row)
            setShowEditForm(true)
        }
    }

    const refreshPage = () => {
        setShowEditForm(false)
        setRefresh(Date.now())
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Контакты</PageTitle>

            <div className="card">
                <SectionHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body d-flex justify-content">
                    <div className="w-800px">
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                autoHeight
                                hideFooter
                                checkboxSelection
                                disableColumnMenu
                                disableSelectionOnClick
                                onCellClick={editContact}
                                columns={getColumns()}
                                rows={contactList || []}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div>
                        <CreateContact onFinish={refreshPage}/>
                        {showEditForm &&
                        <EditContact contact={contactToEdit} showEditForm={showEditForm} onClose={() => setShowEditForm(false)} onFinish={refreshPage}/>
                        }
                        {selectionModel.length !== 0 &&
                        <DeleteContact contactIdList={selectionModel as number[]} onFinish={refreshPage}/>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
