import {Modal} from "react-bootstrap-v5";
import {DeviceType} from "../../../models/Models";
import React, {useEffect, useRef, useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {listDeviceType, setDeviceType} from "../../../api";
import {IDeviceProps} from "./Device";
import Alert from "../../../../../components/alerts/Alert";
import {AlertType} from "../../../../../components/alerts";

interface IDeviceAssignmentFormProps extends IDeviceProps {
    show: boolean
    handleClose: (updatedDeviceType?: DeviceType) => void
}

/**
 * Форма привязки номера к типу устройств
 */
export const DeviceAssignmentForm = ({msisdn, deviceType, show, handleClose}: IDeviceAssignmentFormProps) => {
    const [message, setMessage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const deviceTypeList = useRef<DeviceType[]>()
    const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState<number | undefined>(undefined)
    const [isSubmitted, setIsSubmitted] = useState(false)

    /**
     * Подгрузка существующих типов устройств
     */
    useEffect(() => {
            const getResponse = async () => {
                const response = await listDeviceType()
                switch (response.status) {
                    case "ok":
                        const devTypeList: DeviceType[] = response.data.data.items
                        if (devTypeList) {
                            if (devTypeList.length !== 0) {
                                deviceTypeList.current = devTypeList
                                // Установка начального значения селектора
                                setSelectedDeviceTypeId(deviceType?.id)
                            } else {
                                deviceTypeList.current = []
                            }
                        }
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер сообщил об ошибке. Попробуйте выполнить операцию позднее.")
                        return false
                }
            }

            getResponse()
        },
        // eslint-disable-next-line
        []
    )

    /**
     * Сохранение выбранной привязки
     */
    useEffect(() => {
        const getResponse = async () => {
            const response = await setDeviceType(msisdn!, selectedDeviceTypeId?.toString())
            switch (response.status) {
                case "ok":
                    handleClose(deviceTypeList.current!.filter((item) => item.id === selectedDeviceTypeId)[0])
                    return true
                case "error":
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте выполнить операцию позднее.")
                    return false
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])   // eslint-disable-line react-hooks/exhaustive-deps

    const onDeviceTypeSelect = (event: any) => {
        setSelectedDeviceTypeId((prevState) => {
            const target = event.currentTarget || event.target
            const selectedDeviceTypeId = prevState === target.value ? undefined : target.value
            setDisabled(selectedDeviceTypeId === deviceType?.id?.toString())
            return selectedDeviceTypeId
        })
    }

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setDisabled(true)
        setIsSubmitted(false)

        // Для перерисовки компонента при отсутствующем deviceType
        deviceType === undefined && setSelectedDeviceTypeId(0)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={() => handleClose(deviceType)}
        >
            <>
                <div className='modal-header py-4'>
                    <h3>Привязки к типу устройств</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose(deviceType)}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body  pt-5 px-10 center'>
                    {deviceTypeList.current && deviceTypeList.current.length !== 0 &&
                        <>
                            <div id="deviceTypeContainer" className="d-flex flex-column mb-5">
                                {deviceTypeList.current?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <input
                                                type='radio'
                                                className='btn-check'
                                                name="selectedDeviceType"
                                                id={'_' + index}
                                                value={item.id}
                                                onClick={onDeviceTypeSelect}
                                                defaultChecked={deviceType?.id === item.id}
                                            />
                                            <label
                                                className={`btn btn-lg btn-outline btn-bg-light btn-color-gray-600 border border-active px-4 m-2
                                            ${
                                                    selectedDeviceTypeId === item.id ? 'active btn-active-light-primary' : ''
                                                }
                                            `}
                                                htmlFor={'_' + index}
                                            >
                                                <span className='text-gray-800 fw-bold'>{item.name}</span>
                                            </label>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" onClick={() => setIsSubmitted(true)} disabled={disabled}>Сохранить</button>
                            </div>
                        </>
                    }

                    {deviceTypeList.current && deviceTypeList.current.length === 0 &&
                        <Alert
                            type={AlertType.INFO}
                            additionalClassNames={"p-5 mb-0"}
                            message={"Вы пока не создали ни одного типа устройств. Это можно сделать в разделе <a href='/simcard/deviceTypes'>Типы устройств</a>"}
                        />
                    }

                    <div>
                        {message && <Alert message={message}/>}
                    </div>
                </div>
            </>
        </Modal>
    )
}
