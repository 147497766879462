import React, {useEffect, useState} from "react";
import {imeiAdd, imeiDelete, imeiList as fetchImeiList} from "../../../../api";
import {Modal} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../../../_metronic/helpers";
import Alert from "../../../../../../components/alerts/Alert";
import {ThemeProvider} from "@mui/material";
import {DataGrid, GridSelectionModel, ruRU} from "@mui/x-data-grid";
import {ModalProps} from "../../../../models/Modal";
import {getColumns, getRows, theme} from "./ImeiListDataGridConfig";
import * as Yup from "yup";
import {useFormik} from "formik";
import clsx from "clsx";

interface IEditImeiProps extends ModalProps {
    msisdn?: string | undefined
}

const schema = Yup.object().shape({
    imei: Yup.number()
        .min(100000000000000, "15 или 16 цифр")
        .max(9999999999999999, "15 или 16 цифр")
        .required("15 или 16 цифр")
})

/**
 * Редактирование списка доверенных IMEI
 */
export function EditImei({msisdn, show, handleClose}: IEditImeiProps) {
    const [imeiList, setImeiList] = useState([])
    const [addImei, setAddImei] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const formik = useFormik({
        initialValues: {imei: ''},
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            setLoading(true)
            const response = await imeiAdd(msisdn!!, [values.imei])
            switch (response.status) {
                case "ok":
                    setLoading(false)
                    setAddImei(values.imei)
                    return true
                default:
                    setLoading(false)
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        },
    })

    useEffect(() => {
        const getImeiList = async () => {
            if (msisdn) {
                const response = await fetchImeiList(msisdn)
                switch (response.status) {
                    case "ok":
                        setImeiList(response.data.imei)
                        formik.resetForm()
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу и попробовать снова.")
                        return false;
                }
            }
        }

        getImeiList()
    }, [addImei])  // eslint-disable-line react-hooks/exhaustive-deps

    const deleteImei = async () => {
        setLoading(true)
        const response = await imeiDelete(msisdn!!, selectionModel as string[])
        switch (response.status) {
            case "ok":
                setAddImei('')
                setLoading(false)
                return true
            default:
                console.log(response)
                setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу и попробовать снова.")
                return false;
        }
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-400px"
            onHide={handleClose}
        >
            <div className='modal-header py-4'>
                <h3>Изменение списка доверенных IMEI</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body  pt-5 px-10 center'>

                {message && <Alert additionalClassNames='mt-0 mb-5' message={message}/>}

                <form
                    className='form'
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className='d-flex justify-content-around align-items-center'>
                        <div className='fv-row addImei' style={{marginRight: "1rem"}}>
                            <input
                                type='number'
                                autoComplete='off'
                                placeholder='Введите новый IMEI'
                                {...formik.getFieldProps('imei')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.imei && formik.errors.imei
                                    },
                                    {
                                        'is-valid': formik.touched.imei && !formik.errors.imei,
                                    }
                                )}
                            />
                        </div>

                        <div className='text-center'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                style={{width: '106px'}}
                                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                            >
                                {!loading && <span className='indicator-label'>Добавить</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                                    </span>
                                )}
                            </button>

                        </div>
                    </div>
                    {formik.touched.imei && formik.errors.imei && (
                        <div className='fv-plugins-message-container px-6'>
                            <div className='fv-help-block error-color'>
                                <span role='alert'>{formik.errors.imei}</span>
                            </div>
                        </div>
                    )}

                </form>

                <div className="card-body px-0">
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            autoHeight
                            hideFooter
                            disableColumnMenu
                            disableSelectionOnClick
                            checkboxSelection
                            loading={loading}
                            columns={getColumns(deleteImei, selectionModel.length !== 0)}
                            rows={getRows(imeiList)}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </Modal>
    )
}
