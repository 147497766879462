import axios from "axios";
import {API_URL} from "../../../../config";
import {AddEventSubscriptionProps} from "../models/EventSubscription";
import {InternalEventLogRequest, InternalEventLogResponse} from "../models/InternalEvent";
import {CommonError, ErrorResponse, OkResponse, processResponse} from "../../../api/CommonApi";

/** События и подписки на них */
export const EVENT_LIST = `${API_URL}/m2m/api/events/list.cgi`
export const EVENT_SUBSCRIPTION_LIST = `${API_URL}/m2m/api/events/subscriptions_list.cgi`
export const EVENT_SUBSCRIPTION_ADD = `${API_URL}/m2m/api/events/subscriptions_add.cgi`
export const EVENT_SUBSCRIPTION_DELETE = `${API_URL}/m2m/api/events/subscriptions_del.cgi`

/**
 * Список событий
 */
export function fetchEventList({limit = 1000, offset = 0}: InternalEventLogRequest): Promise<OkResponse<InternalEventLogResponse> | ErrorResponse<CommonError>> {
    const response = axios.post(EVENT_LIST, {limit, offset})
    return processResponse<InternalEventLogResponse, CommonError>(response)
}

/**
 * Список подписок на события
 */
export function fetchEventSubscriptionList() {
    const response = axios.post(EVENT_SUBSCRIPTION_LIST, {})
    return processResponse(response)
}

/**
 * Создание подписки на события
 */
export function eventSubscriptionAdd({name, events, sms, email}: AddEventSubscriptionProps) {
    const response = axios.post(EVENT_SUBSCRIPTION_ADD, {name, events, sms, email})
    return processResponse(response)
}

/**
 * Удаление подписки на события
 */
export function eventSubscriptionDelete(id: string) {
    const response = axios.post(EVENT_SUBSCRIPTION_DELETE, {id})
    return processResponse(response)
}
