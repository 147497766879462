/* eslint-disable jsx-a11y/anchor-is-valid */
import {fetchSimCardInfo, limitList} from "../../api";
import React, {useEffect, useRef, useState} from 'react'
import {ThemeProvider} from "@mui/material";
import {SectionHeader} from "../../SectionHeader";
import {SimCard} from "../../models/Models";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {CreateLimit} from "./create/CreateLimit";
import {DeleteLimit} from "./delete/DeleteLimit";
import {useParams} from "react-router-dom";
import {InnerButtons} from "../overview/InnerButtons";
import {SimCardHeader} from "../../list/SimCardHeader";
import {getColumns, theme} from "./LimitListDataGridConfig";
import {DataGrid, GridSelectionModel, ruRU} from "@mui/x-data-grid";
import AlertDismissible from "../../../../components/alerts/AlertDismissible";
import {formatPhoneNumberFrom10Digits} from "../../../../helpers/Formatter";
import {SimCardLimit, SimCardLimitFromApi} from "../../models/SimCardLimit";

export function LimitListPage() {
    const [refresher, refreshInfo] = useState(Date.now())
    const [message, setMessage] = useState('')
    const params: { msisdn?: string | undefined } = useParams();
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [cardInfo, setCardInfo] = useState<SimCard | undefined>(undefined)
    const [updatedLimitList, setUpdatedLimitList] = useState<SimCardLimit[] | undefined>([])
    const msisdn = useRef(formatPhoneNumberFrom10Digits(params.msisdn))

    useEffect(() => {
        const getSimCardInfo = async () => {
            const response = await fetchSimCardInfo({msisdn: params.msisdn})
            switch (response.status) {
                case "ok":
                    const simCardList = response.data.data.items as SimCard[]
                    setCardInfo(simCardList[0])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }
        getSimCardInfo()

        const getLimitList = async () => {
            const response = await limitList(params.msisdn!!)
            switch (response.status) {
                case "ok":
                    const simCardLimitList: SimCardLimit[] = response.data.data.items.map((item: SimCardLimitFromApi) => {
                        const {id, quote, period_type: limitPeriod, interval_duration: intervalDuration, limit_measure: limitMeasure, action_type: limitAction} = item
                        return {id, quote, limitPeriod, intervalDuration, limitMeasure, limitAction}
                    })
                    setUpdatedLimitList(simCardLimitList)
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getLimitList()
    }, [refresher]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <PageTitle breadcrumbs={[]}>Лимитные правила на номере {msisdn.current}</PageTitle>

            <div className="card">
                <SectionHeader/>

                <div className="card-body d-flex align-items-center justify-content-between pt-5 pb-0">
                    <h1 style={{lineHeight: '1.6'}} className="text-gray-600">{msisdn.current}</h1>
                    <InnerButtons
                        msisdn={params.msisdn}
                        cardInfo={cardInfo}
                        onFinish={() => refreshInfo(Date.now())}
                    />
                </div>

                <SimCardHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body d-flex justify-content">
                    <div className="w-600px">
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                autoHeight
                                hideFooter
                                checkboxSelection
                                disableColumnMenu
                                disableSelectionOnClick
                                columns={getColumns()}
                                rows={updatedLimitList || []}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div>
                        <CreateLimit msisdn={params.msisdn} onFinish={() => refreshInfo(Date.now())}/>
                        {/* В API пока не реализовано
                        {showEditForm &&
                        <EditLimit limit={limitToEdit} showEditForm={showEditForm} onClose={() => setShowEditForm(false)} onFinish={() => refreshInfo(Date.now())}/>
                        }
*/}
                        {selectionModel.length !== 0 &&
                        <DeleteLimit limitIdList={selectionModel as number[]} onFinish={() => refreshInfo(Date.now())}/>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
