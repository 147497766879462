import React, { useState } from 'react'
import { ru } from 'date-fns/locale'
import {formatRFC3339} from 'date-fns'
import 'react-nice-dates/build/style.css'
import {DateRangePicker as Widget} from 'react-nice-dates'
import {Rest} from "../../models/common";

const START_DATE = "startDate"
const END_DATE = "endDate"
const FORMAT = "dd.MM.yyyy"

type DateRange = {
    startDate?: Date | null
    endDate?: Date | null
}

type DateRangeFormatted = [string | undefined, string | undefined]

interface IDateRangePickerProps extends DateRange, Rest {
    onChange: (startDate?: string, endDate?: string) => void
}

export function DateRangePicker({startDate: sDate, endDate: eDate, onChange, ...rest}: IDateRangePickerProps) {
    const [startDate, setStartDate] = useState<Date | null>(sDate!)
    const [endDate, setEndDate] = useState<Date | null>(eDate!)

    const onStartDateChange = (startDate: Date | null) => {
        setStartDate(startDate)
        return onChange(...formatDates({startDate, endDate}))
    }

    const onEndDateChange = (endDate: Date | null) => {
        setEndDate(endDate)
        return onChange(...formatDates({startDate, endDate}))
    }

    const formatDates = ({startDate, endDate}: DateRange): DateRangeFormatted => {
        const startDateFormatted = startDate ? formatRFC3339(startDate) : undefined
        const endDateFormatted = endDate ? formatRFC3339(endDate) : undefined
        return [startDateFormatted, endDateFormatted]
    }

    return (
        <Widget
            locale={ru}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            format={FORMAT}
        >
            {({ startDateInputProps, endDateInputProps, focus }) => (
                <div className='date-range'>
                    <input
                        className={rest?.className + ' input' + (focus === START_DATE ? ' -focused' : '')}
                        {...startDateInputProps}
                        placeholder='От'
                    />
                    <input
                        className={rest?.className + ' input' + (focus === END_DATE ? ' -focused' : '')}
                        {...endDateInputProps}
                        placeholder='До'
                    />
                </div>
            )}
        </Widget>
    )
}
