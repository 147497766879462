import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {SendFeedback} from "../../../../app/modules/feedback/SendFeedback";

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            <SendFeedback/>
            <div className='topbar d-flex align-items-stretch flex-shrink-0'>

                {/* begin::User */}
                <div
                    className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                    id='kt_header_user_menu_toggle'
                >
                    {/* begin::Toggle */}
                    <div
                        className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='bottom'
                    >
                        <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-2tx svg-icon-white me-4'
                        />
                    </div>
                    <HeaderUserMenu/>
                    {/* end::Toggle */}
                </div>
                {/* end::User */}
            </div>
        </div>
    )
}

export {Topbar}
