import {useEffect, useState} from "react";
import {DeviceType} from "../../models/Models";
import {DeviceTypeForm} from "../create/DeviceTypeForm";
import {IHandleCloseProps} from "../../models/Modal";

interface IEditDeviceTypeProps {
    showEditForm: boolean
    onFinish: () => void
    onClose: () => void
    deviceType: DeviceType | undefined
}

/**
 * Редактирование типа устройств
 */
export function EditDeviceType({deviceType, showEditForm, onClose, onFinish}: IEditDeviceTypeProps) {
    const [show, setShow] = useState(showEditForm)

    useEffect(() => {
        setShow(showEditForm)
    }, [showEditForm])

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        onClose()
        isSubmitted && onFinish()
    }

    return  <DeviceTypeForm deviceType={deviceType} show={show} handleClose={closeModal}/>
}
