import {useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {DeviceTypeForm} from "./DeviceTypeForm";
import {IHandleCloseProps} from "../../models/Modal";

export interface ICreateDeviceTypeProps {
    onFinish: () => void
}

/**
 * Создание типа устройств
 */
export function CreateDeviceType({onFinish}: ICreateDeviceTypeProps) {
    const [show, setShow] = useState(false)

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        isSubmitted && onFinish()
    }

    return (
        <div className="px-5 mt-5">
            <div>
                <span className="d-flex align-items-center text-gray-600 fs-6" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                    <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                    <span>Создать&nbsp;тип&nbsp;устройств</span>
                </span>
            </div>
            <DeviceTypeForm show={show} handleClose={closeModal}/>
        </div>
    )
}
