export type AlertProps = {
    message: string;
    type?: AlertType;
    additionalClassNames?: string | undefined;
};

export enum AlertType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SUCCESS = "success",
    WARNING = "warning",
    DANGER = "danger",
    INFO = "info",
    LIGHT = "light",
    DARK = "dark",
}

export namespace AlertType {
    /**
     * Подгружает разные иконки в зависимости от AlertType
     * @param alertType
     */
    export function getIconClass(alertType: AlertType): string {
        switch (alertType) {
            case AlertType.INFO:
                return "fa-info-circle"
            case AlertType.DANGER:
                return "fa-times-circle"
            case AlertType.WARNING:
                return "fa-exclamation-circle"
            case AlertType.SUCCESS:
                return "fa-check-circle"
            default:
                return ""
        }
    }
}
