import {InternalEventType} from "./InternalEvent";

/**
 * Подписка на события
 */
export interface EventSubscription {
    /** Идентификатор подписки */
    id: number
    /** Наименование подписки */
    name: string
    /** Список типов событий, для которых активна подписка */
    events: InternalEventType[]
    /** Данные для отправки SMS */
    sms: EventSubscriptionSMS
    /** Данные для отправки E-mail */
    email: EventSubscriptionEmail
}

/**
 * Данные для отправки SMS
 */
export interface EventSubscriptionSMS {
    /** Флаг - отправлять SMS или нет */
    send_sms: boolean
    /** Периодичность отправки SMS в минутах (минимальное значение 30 мин) */
    periodicity: number
    /** Дни, в которые выполнять отправку */
    send_days: SendDays
    /** Номера телефонов для отправки */
    phones: string
}

/**
 * Данные для отправки E-mail
 */
export interface EventSubscriptionEmail {
    /** Флаг - отправлять E-mail или нет */
    send_email: boolean
    /** Периодичность отправки SMS в минутах (минимальное значение 30 мин) */
    periodicity: number
    /** Дни, в которые выполнять отправку */
    send_days: SendDays
    /** Почтовые адреса */
    emails: string
}

/** Периодичность отправки SMS или E-mail */
export enum SendDays {
    /** По будним дням */
    WEEKDAYS='weekdays',
    /** Ежедневно */
    EVERYDAY='everyday'
}

export interface AddEventSubscriptionProps {
    name: string
    events: InternalEventType[]
    sms: EventSubscriptionSMS
    email: EventSubscriptionEmail
}
