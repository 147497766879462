import {useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {nl2br} from "../../../../../helpers/Formatter";
import {GeoPositionForm} from "./GeoPositionForm";

export interface IGeoPositionProps {
    msisdn?: string | undefined
    geoPosition?: string | undefined
}

/**
 * Геолокация устройства
 */
export function GeoPosition({msisdn, geoPosition}: IGeoPositionProps) {
    const [show, setShow] = useState(false)
    const [updatedGeoPosition, setUpdatedGeoPosition] = useState<string | undefined>(undefined)

    const closeModal = (updatedGeoPosition?: string) => {
        setShow(false)
        setUpdatedGeoPosition(updatedGeoPosition)
    }

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0 pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">Местоположение
                    <span
                        style={{cursor: "pointer"}}
                        onClick={() => setShow(true)}
                    ><KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-1 me-3 mx-3'/></span>
                </h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                <span>{updatedGeoPosition ? nl2br(updatedGeoPosition) : nl2br(geoPosition)}</span>
            </div>
            <GeoPositionForm msisdn={msisdn} geoPosition={updatedGeoPosition !== undefined ? updatedGeoPosition : geoPosition} show={show} handleClose={closeModal}/>
        </div>
    )
}
