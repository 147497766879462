/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import {DeviceTypeListPage} from "./DeviceTypeListPage";

export const DeviceTypeListWrapper: FC = (props) => {
    return (
        <Switch>
            <Route exact path='/simcard/deviceTypes'>
                <DeviceTypeListPage/>
            </Route>
            <Redirect from='/simcard/deviceTypes/' to='/simcard/deviceTypes' />
        </Switch>
    )
}
