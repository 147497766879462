/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button} from "react-bootstrap-v5";
import {SimCard} from "../../models/Models";
import {fetchBillItemization, fetchSimCardInfo} from "../../api";
import {ThemeProvider} from "@mui/material";
import React, {useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {SectionHeader} from "../../SectionHeader";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {SimCardHeader} from "../../list/SimCardHeader";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {formatPhoneNumberFrom10Digits} from "../../../../helpers/Formatter";
import AlertDismissible from "../../../../components/alerts/AlertDismissible";
import AlertString from "../../../../components/alerts/AlertString";
import {getColumns, getRows, theme} from "./DetailDataGridConfig";
import {BillItemizationItem} from "../../models/BillItemization";
import {DateRangePicker} from "../../../../components/dateRangePicker/DateRangePicker";
import {SendBillItemizationForm} from "./SendBillItemizationForm";
import {formatRFC3339} from "date-fns";
import {InnerButtons} from "../overview/InnerButtons";

export function DetailPage() {
    const now = new Date()
    const params: { msisdn?: string | undefined } = useParams();
    const [refresher, refreshInfo] = useState(Date.now())
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const [alertStringMessage, setAlertStringMessage] = useState('')
    const [cardInfo, setCardInfo] = useState<SimCard | undefined>(undefined)
    const [itemization, setItemization] = useState<BillItemizationItem[] | undefined>([])

    const [year, setYear] = useState(now.getFullYear())
    const [month, setMonth] = useState(('0' + (now.getMonth() + 1)).slice(-2))
    // eslint-disable-next-line
    const [day, setDay] = useState(('0' + now.getDate()).slice(-2))

    const firstDayOfMonth = formatRFC3339(Date.parse(year + '-' + month + '-01 00:00:00'));
    const currentDay = formatRFC3339(Date.parse(year + '-' + month + '-' + day + ' 23:59:59'));
    const [startDate, setStartDate] = useState<string>(firstDayOfMonth)
    const [endDate, setEndDate] = useState<string>(currentDay)

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [show, setShow] = useState(false)
    const msisdn = useRef(formatPhoneNumberFrom10Digits(params.msisdn))

    useEffect(() => {
        const getSimCardInfo = async () => {
            const response = await fetchSimCardInfo({msisdn: params.msisdn})
            switch (response.status) {
                case "ok":
                    const simCardList = response.data.data.items as SimCard[]
                    setCardInfo(simCardList[0])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getSimCardInfo()
    }, [refresher])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getBillItemization = async () => {
            if (params.msisdn) {
                setLoading(true)
                const response = await fetchBillItemization({
                    msisdn: Number(params.msisdn),
                    date_from: startDate,
                    date_to: endDate
                })
                setIsSubmitted(false)
                switch (response.status) {
                    case "ok":
                        setLoading(false)
                        setItemization(response.data.data.items as BillItemizationItem[])
                        return true
                    default:
                        setLoading(false)
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                        return false;
                }
            }
        }

        isSubmitted && getBillItemization()
    }, [isSubmitted]) // eslint-disable-line react-hooks/exhaustive-deps

    const onDateRangeSet = (updatedStartDate: string | undefined, updatedEndDate: string | undefined) => {
        // Если одно из полей пустое, отключаем кнопки
        if (!updatedStartDate || !updatedStartDate.trim() || !updatedEndDate || !updatedEndDate.trim()) {
            setDisabled(true)
            return false
        }

        if (new Date(Date.parse(updatedStartDate)).getMonth() !== new Date(Date.parse(updatedEndDate)).getMonth()) {
            setAlertStringMessage('Даты должны находиться в пределах одного календарного месяца!')
            setDisabled(true)
            return false
        }

        alertStringMessage && setAlertStringMessage('')
        disabled && setDisabled(false)

        if (updatedStartDate !== startDate) {
            setStartDate(updatedStartDate)
        }
        if (updatedEndDate !== endDate) {
            setEndDate(updatedEndDate)
        }
        // Год и месяц должны быть одинаковы независимо от того, обновляется дата начала или окончания периода
        setMonth(('0' + (new Date(Date.parse(updatedStartDate)).getMonth() + 1)).slice(-2))
        setYear((new Date(Date.parse(updatedStartDate)).getFullYear()))
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Детализация расходов на номере {msisdn.current}</PageTitle>
            <div className="card">
                <SectionHeader/>

                <div className="card-body d-flex align-items-center justify-content-between pt-5 pb-0">
                    <h1 style={{lineHeight: '1.6'}} className="text-gray-600">{msisdn.current}</h1>
                    <InnerButtons
                        msisdn={params.msisdn}
                        cardInfo={cardInfo}
                        onFinish={() => refreshInfo(Date.now())}
                    />
                </div>

                <SimCardHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body">

                    {alertStringMessage && <AlertString additionalClassNames={"mb-5 mt-0 mx-3"} message={alertStringMessage}/>}

                    <div className="d-flex align-items-center justify-content-between w-750px mb-5">
                        <span className='detailPageDateRangeLabel'>Выберите период детализации:<br/>
                            <span style={{fontSize: "1rem"}}>(в пределах одного месяца)</span>
                        </span>
                        <DateRangePicker
                            className={'form-control form-control-solid nice-dates-detail'}
                            startDate={new Date(Date.parse(startDate))}
                            endDate={new Date(Date.parse(endDate))}
                            onChange={onDateRangeSet}
                        />
                        <Button size="sm" disabled={disabled} onClick={() => setIsSubmitted(true)}>Вывести</Button>
                        или
                        <Button size="sm" disabled={disabled} onClick={() => setShow(true)}>Отправить на E-mail</Button>
                        {show &&
                        <SendBillItemizationForm msisdn={params.msisdn} period={year + month} show={show} handleClose={() => setShow(false)}/>
                        }
                    </div>

                    <ThemeProvider theme={theme}>
                        <DataGrid
                            autoHeight
                            hideFooter
                            loading={loading}
                            disableColumnMenu
                            columns={getColumns()}
                            rows={getRows(itemization)}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </>
    )
}
