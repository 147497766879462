import {Field} from "formik";
import React, {ChangeEvent} from "react";

export interface IMsisdnListFileProps {
    onFileSelect: (msisdnList: number[]) => void
}

/**
 * Компонент для загрузки файла с номерами, их извлечения и передачи в родительский компонент
 *
 * @param onFileSelect
 * @constructor
 */
export default function MsisdnListFile({onFileSelect}: IMsisdnListFileProps) {
    return (
        <Field name="file">
            {({
                  field, // { name, value, onChange, onBlur }
                  form: {setFieldValue, touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
              }) => {

                /**
                 * Извлекает из файла номера и передаёт их в родительский компонент
                 *
                 * @param file
                 */
                const msisdnFormatter = (file: File | undefined = undefined) => {
                    if (!file) {
                        onFileSelect([])
                        return
                    }

                    // Обрабатываем файлы только с указанными расширениями
                    const allowedExtensionList = ['txt', 'csv', 'xls', 'xlsx', 'ods']

                    // Извлекаем расширение из названия файла
                    const extension = file.name.split('.').pop()?.toLowerCase();

                    if (extension && allowedExtensionList.indexOf(extension) === -1) {
                        onFileSelect([])
                        return
                    }

                    const fileReader = new FileReader()
                    // Простой текстовый файл
                    if (file.type === 'text/plain') {
                        fileReader.readAsText(file)
                        fileReader.onload = () => {
                            const fileContent = fileReader.result
                            if (typeof fileContent === 'string') {
                                fillMsisdnList(fileContent)
                            }
                        }
                        // Файл Excel
                    } else {
                        fileReader.readAsArrayBuffer(file)
                        fileReader.onload = async () => {
                            const XLSX = await import ("../../../components/xlsx/xlsxWrapper");
                            const fileContent = fileReader.result
                            const workbook = XLSX.read(fileContent, {type: 'buffer'})
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
                            const csvContent = XLSX.utils.sheet_to_csv(worksheet, {FS: ",", RS: ",", blankrows: false})
                            fillMsisdnList(csvContent)
                        }
                    }
                }

                const fillMsisdnList = (content: string) => {
                    // Отфильтровываем записи, являющиеся номерами телефонов
                    const itemsWithDuplicates = content?.split(new RegExp(/[\s,;]/)).filter((item) => {
                        return /\d{10}/.test(item.trim())
                    })

                    // Избавляемся от дубликатов
                    const itemsWithoutDuplicates = new Set(itemsWithDuplicates)

                    if (itemsWithoutDuplicates.size === 0) {
                        // Если в итоге ничего не осталось, очищаем список номеров
                        onFileSelect([])
                    } else {
                        onFileSelect(Array.from(itemsWithoutDuplicates).map((item) => Number(item.trim())))
                    }
                }

                /**
                 * Обработчик загрузки файла
                 *
                 * @param event
                 */
                const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
                    const file = event.target.files && event.target.files.length !== 0 ? event.target.files[0] : undefined
                    setFieldValue("file", file)
                    msisdnFormatter(file)
                }

                return (
                    <div>
                        <input
                            type="file"
                            className={'file-form-control form-control form-control-solid'}
                            onChange={onFileChange}
                        />
{/* Сообщение о необходимости загрузки файла излишнее, так как кнопка отправки при этом деактивирована
                        {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                        )}
*/}
                    </div>
                )
            }}
        </Field>
    )
}
