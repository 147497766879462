import {useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {CreateGroupForm} from "./CreateGroupForm";
import {IHandleCloseProps} from "../../models/Modal";

export interface ICreateGroupProps {
    groupName: string
    onCreate: () => void
}

/**
 * Создание группы
 */
export function CreateGroup({groupName = '', onCreate}: ICreateGroupProps) {
    const [show, setShow] = useState(false)
    const [updatedGroupName, setUpdatedGroupName] = useState('')

    const closeModal = ({isSubmitted, ...rest}: IHandleCloseProps) => {
        setShow(false)
        rest.updatedGroupName && setUpdatedGroupName(rest.updatedGroupName)
        isSubmitted && onCreate()
    }

    return (
        <>
            <div className="px-5 mt-6">
                <div>
                <span className="d-flex align-items-center text-gray-600 fs-6" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                    <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                    <span>Создать&nbsp;группу</span>
                </span>
                </div>
                <CreateGroupForm groupName={updatedGroupName || groupName} show={show} handleClose={closeModal}/>
            </div>
        </>
    )
}
