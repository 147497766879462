import {useState} from "react";
import {CommentForm} from "./CommentForm";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {nl2br} from "../../../../../helpers/Formatter";

export interface ICommentProps {
    msisdn?: string | undefined
    description?: string | undefined
}

/**
 * Компонент комментария к симкарте
 */
export function Comment({msisdn, description}: ICommentProps) {
    const [show, setShow] = useState(false)
    const [updatedDescription, setUpdatedDescription] = useState<string | undefined>(undefined)

    const closeModal = (updatedDescription?: string) => {
        setShow(false)
        setUpdatedDescription(updatedDescription)
    }

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0 pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">Комментарий
                    <span
                        style={{cursor: "pointer"}}
                        onClick={() => setShow(true)}
                    ><KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-1 me-3 mx-3'/></span>
                </h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                <span>{updatedDescription ? nl2br(updatedDescription) : nl2br(description)}</span>
            </div>
            <CommentForm msisdn={msisdn} description={updatedDescription !== undefined ? updatedDescription : description} show={show} handleClose={closeModal}/>
        </div>
    )
}
