/* eslint-disable jsx-a11y/anchor-is-valid */
import {SimCard, SimCardBalance} from "../../models/Models";
import {fetchSimCardBalanceHistory, fetchSimCardInfo} from "../../api";
import {ThemeProvider} from "@mui/material";
import React, {useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {SectionHeader} from "../../SectionHeader";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {SimCardHeader} from "../../list/SimCardHeader";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {formatPhoneNumberFrom10Digits} from "../../../../helpers/Formatter";
import AlertDismissible from "../../../../components/alerts/AlertDismissible";
import {getColumns, getRows, theme} from "./SimCardBalanceHistoryDataGridConfig";
import {InnerButtons} from "../overview/InnerButtons";

export function SimCardBalanceHistory() {
    const params: { msisdn?: string | undefined } = useParams();
    const [refresher, refreshInfo] = useState(Date.now())
    const [message, setMessage] = useState('')
    const [cardInfo, setCardInfo] = useState<SimCard | undefined>(undefined)
    const [balanceHistory, setBalanceHistory] = useState<SimCardBalance[] | undefined>([])
    const msisdn = useRef(formatPhoneNumberFrom10Digits(params.msisdn))

    useEffect(() => {
        const getSimCardInfo = async () => {
            const response = await fetchSimCardInfo({msisdn: params.msisdn})
            switch (response.status) {
                case "ok":
                    const simCardList = response.data.data.items as SimCard[]
                    setCardInfo(simCardList[0])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }
        getSimCardInfo()

        const getBalanceHistory = async () => {
            if (params.msisdn) {
                const response = await fetchSimCardBalanceHistory({msisdn: params.msisdn})
                switch (response.status) {
                    case "ok":
                        setBalanceHistory(response.data.data.items as SimCardBalance[])
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                        return false;
                }
            }
        }

        getBalanceHistory()
    }, [refresher])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <PageTitle breadcrumbs={[]}>История изменения баланса SIM-карты для номера {msisdn.current}</PageTitle>
            <div className="card">
                <SectionHeader/>

                <div className="card-body d-flex align-items-center justify-content-between pt-5 pb-0">
                    <h1 style={{lineHeight: '1.6'}} className="text-gray-600">{msisdn.current}</h1>
                    <InnerButtons
                        msisdn={params.msisdn}
                        cardInfo={cardInfo}
                        onFinish={() => refreshInfo(Date.now())}
                    />
                </div>

                <SimCardHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body">
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            autoHeight
                            hideFooter
                            disableColumnMenu
                            columns={getColumns()}
                            rows={getRows(balanceHistory)}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </>
    )
}
