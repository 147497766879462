/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react";
import {Link, useLocation} from 'react-router-dom'

const SectionHeader: FC = () => {
    const location = useLocation()

    return (
        <div className='card-header pt-5 pb-0'>
            <div className='d-flex overflow-auto h-55px'>

                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (/simcard\/list/.test(location.pathname) && 'active')
                            }
                            to='/simcard/list'
                        >
                            Список SIM-карт
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (/simcard\/groups/.test(location.pathname) && 'active')
                            }
                            to='/simcard/groups'
                        >
                            Группы
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (/simcard\/deviceTypes/.test(location.pathname) && 'active')
                            }
                            to='/simcard/deviceTypes'
                        >
                            Типы устройств
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (/simcard\/contacts/.test(location.pathname) && 'active')
                            }
                            to='/simcard/contacts'
                        >
                            Контакты
                        </Link>
                    </li>
                </ul>

            </div>
        </div>
    )
}

export {SectionHeader}
