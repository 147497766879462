import {SlicedRequest, SlicedResponse} from "../../../models/common";

export interface InternalEvent {
    /** Идентификатор события */
    id: number
    /**
     * Номер, с которым связано событие
     * Может быть null, если событие системное (например, ошибка авторизации в ЛК)
     */
    msisdn?: string | undefined
    /** Дата и время события */
    date: string | undefined
    /** Название источника события или имени пользователя, сгенерировавшего событие */
    source_name: string
    /** Тип события */
    type: InternalEventType
    /** Описание события. Может генерироваться для уточнения данных по нему. */
    descr: string
}

export interface InternalEventLogRequest extends SlicedRequest {
}

export interface InternalEventLogResponse extends SlicedResponse {
    data: {
        items: InternalEvent[]
    }
}

/**
 * Типы событий
 */
export enum InternalEventType {
    AUTH_FAILED = 'auth_failed',
    LIMIT_EXCEEDED = 'limit_exceeded',
    MSISDN_ACTIVATED = 'msisdn_activated',
    MSISDN_BLOCKED = 'msisdn_blocked',
    IMEI_LOCK_BLOCK = 'imei_lock_block',
    API_DIAGNOSTIC_CHECK = 'api_diagnostic_check',
    API_DIAGNOSTIC_SMS_TEST = 'api_diagnostic_sms_test',
    API_DIAGNOSTIC_CANCEL_LOCATION = 'api_diagnostic_cancel_location'
}

export namespace InternalEventType {
    /**
     * Карта названий типов событий
     */
    const labelMap = new Map([
        [InternalEventType.AUTH_FAILED, "Авторизация с неверным паролем"],
        [InternalEventType.LIMIT_EXCEEDED, "Достижение лимита"],
        [InternalEventType.MSISDN_ACTIVATED, "Разблокировка номера"],
        [InternalEventType.MSISDN_BLOCKED, "Блокировка номера"],
        [InternalEventType.IMEI_LOCK_BLOCK, "IMEI-lock блокировка"],
        [InternalEventType.API_DIAGNOSTIC_CHECK, "Проверка регистрации в сети"],
        [InternalEventType.API_DIAGNOSTIC_SMS_TEST, "SMS тест"],
        [InternalEventType.API_DIAGNOSTIC_CANCEL_LOCATION, "Cancel location"],
    ])

    /**
     * Наименование типа события
     */
    export function getLabel(type: InternalEventType) {
        return labelMap.get(type) ?? ''
    }

    /**
     * Список всех типов событий
     */
    export function getList(): InternalEventType[] {
        return Array.from(labelMap.keys())
    }
}
