/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {fetchActiveServiceList, fetchSimCardInfo} from "../../api";
import {useParams} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {Service} from "../../models/Services";
import {SectionHeader} from "../../SectionHeader";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {DeactivateServices} from "./deactivate/DeactivateServices";
import {getColumns, getRows, theme} from "./ServiceListDataGridConfig";
import AlertDismissible from "../../../../components/alerts/AlertDismissible";
import {DataGrid, GridRowParams, GridSelectionModel, ruRU} from "@mui/x-data-grid";
import {formatPhoneNumberFrom10Digits} from "../../../../helpers/Formatter";
import {SimCardHeader} from "../../list/SimCardHeader";
import {SimCard} from "../../models/Models";
import {KTSVG} from "../../../../../_metronic/helpers";
import {ActivateServices} from "./activate/ActivateService";
import {InnerButtons} from "../overview/InnerButtons";

export function ServiceListPage() {
    const params: { msisdn?: string | undefined } = useParams();
    const [show, setShow] = useState(false)
    const [refreshTable, setRefreshTable] = useState(Date.now())
    const [refreshCardInfo, setRefreshCardInfo] = useState(Date.now())
    const [message, setMessage] = useState('')
    const [cardInfo, setCardInfo] = useState<SimCard | undefined>(undefined)
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [serviceList, setServiceList] = useState<Service[] | undefined>(undefined)
    const msisdn = useRef(formatPhoneNumberFrom10Digits(params.msisdn))

    useEffect(() => {
        const getSimCardInfo = async () => {
            const response = await fetchSimCardInfo({msisdn: params.msisdn})
            switch (response.status) {
                case "ok":
                    const simCardList = response.data.data.items as SimCard[]
                    setCardInfo(simCardList[0])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }
        getSimCardInfo()
    }, [refreshCardInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getActiveServiceList = async () => {
            const response = await fetchActiveServiceList(Number(params.msisdn))
            switch (response.status) {
                case "ok":
                    setServiceList(getRows(response.data.data.items))
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getActiveServiceList()
    }, [refreshTable]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Разрешаем выбирать на отключение только те услуги, которые уже подключены и допускают изменения
     *
     * @param params
     */
    const isRowSelectable = (params: GridRowParams) => !!params.row.activated_date && params.row.can_change

    return (
        <>
            <PageTitle breadcrumbs={[]}>Услуги на номере {msisdn.current}</PageTitle>

            <div className="card">
                <SectionHeader/>

                {message !== '' && <AlertDismissible message={message}/>}

                <div className="card-body d-flex align-items-center justify-content-between pt-5 pb-0">
                    <h1 style={{lineHeight: '1.6'}} className="text-gray-600">{msisdn.current}</h1>
                    <InnerButtons
                        msisdn={params.msisdn}
                        cardInfo={cardInfo}
                        onFinish={() => setRefreshCardInfo(Date.now())}
                    />
                </div>

                <SimCardHeader/>

                <div className="card-body d-flex justify-content">
                    <div className="w-750px">
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                autoHeight
                                hideFooter
                                checkboxSelection
                                disableColumnMenu
                                disableSelectionOnClick
                                columns={getColumns()}
                                rows={serviceList || []}
                                isRowSelectable={isRowSelectable}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div>
                        <span className="d-flex align-items-center text-gray-600 fs-6 mt-5" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                            <span>Добавить</span>
                        </span>
                        {selectionModel.length !== 0 &&
                        <DeactivateServices msisdn={Number(params.msisdn)} serviceIdList={selectionModel as number[]} onFinish={() => setRefreshTable(Date.now())}/>
                        }
                    </div>
                    {show &&
                    <ActivateServices msisdn={Number(params.msisdn)} show={show} handleClose={() => {
                        setShow(false);
                        setRefreshTable(Date.now())
                    }}/>
                    }
                </div>
            </div>
        </>
    )
}
