import React, {useState} from "react";
import clsx from "clsx";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Modal} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {limitCreate} from "../../../api";
import {ExtendedModalProps} from "../../../models/Modal";
import Alert from "../../../../../components/alerts/Alert";
import {LimitAction, LimitMeasure, LimitPeriod, SimCardLimit} from "../../../models/SimCardLimit";

interface ILimitForm extends ExtendedModalProps {
    msisdn: number | string | undefined
    limit?: SimCardLimit | undefined
}

const schema = Yup.object().shape({
    quote: Yup.number()
        .positive('Укажите значение лимита (больше нуля)')
        .required('Укажите значение лимита (больше нуля)'),
    intervalDuration: Yup.number()
        .when('limitPeriod', {
        is: LimitPeriod.INTERVAL,
        then: Yup.number()
            .required('Необходимо указать период от 1 до ' + LimitPeriod.MAX_VALUE_IN_DAYS + ' дней')
            .min(1, 'Необходимо указать период от 1 до ' + LimitPeriod.MAX_VALUE_IN_DAYS + ' дней')
            .max(LimitPeriod.MAX_VALUE_IN_DAYS, 'Необходимо указать период от 1 до ' + LimitPeriod.MAX_VALUE_IN_DAYS + ' дней')
    }),
    limitPeriod: Yup.string()
        .required('Обязательное поле'),
    limitMeasure: Yup.string()
        .required('Обязательное поле'),
    limitAction: Yup.string()
        .required('Обязательное поле'),
})

const initialValues = {
    quote: 0,
    intervalDuration: 0,
    limitPeriod: LimitPeriod.MONTH,
    limitMeasure: LimitMeasure.MONEY,
    limitAction: LimitAction.INFO
} as Partial<SimCardLimit>

/**
 * Форма для создания лимитного правила
 */
export const LimitForm = ({msisdn, limit, show, handleClose}: ILimitForm) => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        formik.resetForm()
        formik.validateForm()
    }

    const formik = useFormik({
        initialValues: limit || initialValues,
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            const response = await limitCreate({
                msisdn,
                ...values as Partial<SimCardLimit>,
                quote: values.limitMeasure === LimitMeasure.MONEY
                    ? Number(values.quote)
                    : SimCardLimit.convertLimitValueToApi(values.limitMeasure!, Number(values.quote)),
                intervalDuration: Number(values.intervalDuration),
                limitMeasure: values.limitMeasure === LimitMeasure.MONEY
                    ? LimitMeasure.MONEY
                    : LimitMeasure.TRAFFIC
            })
            if (response) {
                switch (response.status) {
                    case "ok":
                        setLoading(false)
                        handleClose({isSubmitted: true})
                        return true
                    case "error":
                        if (response.data.error === "already_exists")
                        setLoading(false)
                        setSubmitting(false)
                        console.log(response.data.error)
                        setMessage("Вы уже создали лимитное правило аналогичного типа. Необходимо удалить его, чтобы создать новое.")
                        return false
                    default:
                        setLoading(false)
                        setSubmitting(false)
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте выполнить операцию позднее.")
                        return false
                }
            }
        },
    })

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted: false})
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-header py-4'>
                <h3>Создание лимитного правила</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-5 center'>

                {message !== "" && <Alert additionalClassNames='p-5 mb-5 mt-0' message={message}/>}

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <label className='form-label fs-6 fw-bolder text-dark px-1'>Период действия лимита</label>
                    <div className='fv-row mb-5'>

                        <input
                            type='radio'
                            id='limitPeriodMonth'
                            className='btn-check'
                            {...formik.getFieldProps('limitPeriod')}
                            value='month'
                            onClick={(event) => {
                                formik.setFieldValue("limitMeasure", LimitMeasure.MONEY)
                                formik.setFieldValue("limitAction", LimitAction.INFO)
                            }}
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitPeriod === LimitPeriod.MONTH ? 'active' : ''
                            }`}
                            htmlFor='limitPeriodMonth'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitPeriod.getLabel(LimitPeriod.MONTH)}</span>
                        </label>

                        <input
                            type='radio'
                            id='limitPeriodInterval'
                            className='btn-check'
                            {...formik.getFieldProps('limitPeriod')}
                            value='interval'
                            onClick={(event) => {
                                formik.setFieldValue("limitMeasure", LimitMeasure.TRAFFICMB)
                                formik.setFieldValue("limitAction", LimitAction.BLOCK)
                            }}
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitPeriod === LimitPeriod.INTERVAL ? 'active' : ''
                            }`}
                            htmlFor='limitPeriodInterval'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitPeriod.getLabel(LimitPeriod.INTERVAL)}</span>
                        </label>
                    </div>

                    {formik.values.limitPeriod === LimitPeriod.INTERVAL && (
                    <div className='fv-row  mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Интервал в днях</label>
                        <input
                            type='number'
                            min={0}
                            max={LimitPeriod.MAX_VALUE_IN_DAYS}
                            autoComplete='off'
                            {...formik.getFieldProps('intervalDuration')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.errors.intervalDuration
                                },
                                {
                                    'is-valid': !formik.errors.intervalDuration,
                                }
                            )}
                        />
                    </div>
                    )}
                    {formik.errors.intervalDuration && (
                        <div className='fv-plugins-message-container px-1 mb-5'>
                            <div className='fv-help-block error-color'>
                                <span role='alert'>{formik.errors.intervalDuration}</span>
                            </div>
                        </div>
                    )}

                    <label className='form-label fs-6 fw-bolder text-dark px-1'>Единицы измерения</label>
                    <div className='fv-row mb-5'>
                        <input
                            type='radio'
                            id='limitMeasureMoney'
                            className='btn-check'
                            {...formik.getFieldProps('limitMeasure')}
                            value='money'
                            disabled={formik.values.limitPeriod === LimitPeriod.INTERVAL}
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitMeasure === LimitMeasure.MONEY ? 'active' : ''
                            }`}
                            htmlFor='limitMeasureMoney'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitMeasure.getLabel(LimitMeasure.MONEY)}</span>
                        </label>

                        <input
                            type='radio'
                            id='limitMeasureTraffic'
                            className='btn-check'
                            {...formik.getFieldProps('limitMeasure')}
                            value='traf'
                            disabled={formik.values.limitPeriod === LimitPeriod.INTERVAL}
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitMeasure === LimitMeasure.TRAFFIC ? 'active' : ''
                            }`}
                            htmlFor='limitMeasureTraffic'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitMeasure.getLabel(LimitMeasure.TRAFFIC)}</span>
                        </label>

                        <input
                            type='radio'
                            id='limitMeasureTrafficMb'
                            className='btn-check'
                            {...formik.getFieldProps('limitMeasure')}
                            value='trafmb'
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitMeasure === LimitMeasure.TRAFFICMB? 'active' : ''
                            }`}
                            htmlFor='limitMeasureTrafficMb'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitMeasure.getLabel(LimitMeasure.TRAFFICMB)}</span>
                        </label>

                        <input
                            type='radio'
                            id='limitMeasureTrafficGb'
                            className='btn-check'
                            {...formik.getFieldProps('limitMeasure')}
                            value='trafgb'
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitMeasure === LimitMeasure.TRAFFICGB? 'active' : ''
                            }`}
                            htmlFor='limitMeasureTrafficGb'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitMeasure.getLabel(LimitMeasure.TRAFFICGB)}</span>
                        </label>
                    </div>

                    <div className='fv-row  mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Значение лимита</label>
                        <input
                            type='number'
                            min={0}
                            autoComplete='off'
                            {...formik.getFieldProps('quote')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.errors.quote
                                },
                                {
                                    'is-valid': !formik.errors.quote,
                                }
                            )}
                        />
                    </div>
                    {formik.errors.quote && (
                        <div className='fv-plugins-message-container px-1 mb-5'>
                            <div className='fv-help-block error-color'>
                                <span role='alert'>{formik.errors.quote}</span>
                            </div>
                        </div>
                    )}

                    <label className='form-label fs-6 fw-bolder text-dark px-1'>Тип действия</label>
                    <div className='fv-row pb-1'>
                        <input
                            type='radio'
                            id='limitActionInfo'
                            className='btn-check'
                            {...formik.getFieldProps('limitAction')}
                            value='info'
                            disabled={formik.values.limitPeriod === LimitPeriod.INTERVAL}
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitAction === LimitAction.INFO ? 'active' : ''
                            }`}
                            htmlFor='limitActionInfo'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitAction.getLabel(LimitAction.INFO)}</span>
                        </label>
                        <input
                            type='radio'
                            id='limitActionBlock'
                            className='btn-check'
                            {...formik.getFieldProps('limitAction')}
                            value='block'
                        />
                        <label
                            className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                formik.values.limitAction === LimitAction.BLOCK ? 'active' : ''
                            }`}
                            htmlFor='limitActionBlock'
                        >
                            <span className='text-gray-800 fw-bold'>{LimitAction.getLabel(LimitAction.BLOCK)}</span>
                        </label>
                    </div>

                    <div className='text-center d-flex justify-content-end pt-5'>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                        >
                            {!loading && <span className='indicator-label'>Сохранить</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>Обрабатываем...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                        </span>
                            )}
                        </button>

                    </div>
                </form>
            </div>
        </Modal>
    )
}
