/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import {InternalEventListPage} from "./InternalEventListPage";

export const InternalEventListWrapper: FC = (props) => {
    return (
        <Switch>
            <Route exact path='/event/list'>
                <InternalEventListPage/>
            </Route>
            <Redirect from='/event/list/' to='/event/list' />
        </Switch>
    )
}
