import {useEffect, useState} from "react";
import {listContactAssignments} from "../../api";
import {SimCard, SimCardContact} from "../../models/Models";
import {Comment} from "./comment/Comment";
import {GroupList} from "./groupList/GroupList";
import {GeneralInfo} from "./generalinfo/GeneralInfo";
import {ContactList} from "./contactList/ContactList";
import {Imei} from "./imei/Imei";
import {Device} from "./device/Device";
import {Network} from "./network/Network";
import AlertDismissible from "../../../../components/alerts/AlertDismissible";

interface IOverviewProps {
    msisdn?: string | undefined
    cardInfo?: SimCard | undefined
}

/**
 * Обзорная страница по номеру
 */
export function Overview({msisdn, cardInfo}: IOverviewProps) {
    const [message, setMessage] = useState('')
    const [cardContactList, setCardContactList] = useState<SimCardContact[] | undefined>(undefined)

    useEffect(() => {
        const getContactList = async (msisdn: string) => {
            const response = await listContactAssignments(Number(msisdn))
            switch (response.status) {
                case "ok":
                    const simCardList = response.data.data.items as SimCardContact[]
                    setCardContactList(simCardList)
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог получить список контактов для номера. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getContactList(msisdn!)
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {message && <AlertDismissible message={message}/>}
            <div className="row">
                {cardInfo &&
                <>
                    <div className="col-lg-4">
                        <GeneralInfo cardInfo={cardInfo}/>
                        <Device msisdn={msisdn} deviceType={cardInfo?.device_type}/>
                    </div>
                    <div className="col-lg-4">
                        <Imei msisdn={msisdn} imei={cardInfo?.imei}/>
                        <Network cardInfo={cardInfo}/>
                    </div>
                    <div className="col-lg-4">
                        <GroupList msisdn={msisdn} groupList={cardInfo?.groups}/>
                        <ContactList msisdn={msisdn} contactList={cardContactList}/>
                        <Comment msisdn={msisdn} description={cardInfo?.description}/>
                    </div>
                </>
                }
            </div>
        </>
    )
}
