import {activateSim} from "../../../api";
import React, {useEffect, useState} from "react";
import {ExtendedModalProps} from "../../../models/Modal";
import {Modal} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../../_metronic/helpers";
import Alert from "../../../../../components/alerts/Alert";

interface IActivateFormProps extends ExtendedModalProps {
    msisdn: string | undefined
}

/**
 * Модальное окно активации номера (SIM-карты)
 */
export const ActivateForm = ({msisdn, show, handleClose}: IActivateFormProps) => {
    const [message, setMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(false)

    useEffect(() => {
        /**
         * Активация номера
         */
        const setSimActive = async (msisdn: string) => {
            const response = await activateSim(Number(msisdn))
            switch (response.status) {
                case "ok":
                    return handleClose({isSubmitted})
                default:
                    setMessage("Не удалось активировать номер. Попробуйте обновить страницу и выполнить эту операцию повторно.")
                    return <KTSVG path='/media/icons/duotune/arrows/arr015.svg'
                                  className='svg-icon svg-icon-1 svg-icon-danger me-3'/>
            }
        }

        isSubmitted && setSimActive(msisdn!!)
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {
        setIsSubmitted(true)
        setSubmitDisabled(true)
    }

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
        setSubmitDisabled(false)
    }

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted})
    }

    return (
        <Modal
            className='modal fade'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-300px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-header py-4'>
                <h3>Активация номера</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-10 pb-5'>
                <p>Подтвердите активацию номера</p>
                <div className="d-flex justify-content-evenly align-items-center mb-5">
                    <div>
                        <button className="btn btn-primary px-4 mx-3" onClick={submit}
                                disabled={submitDisabled}>
                            {'Активировать'}
                        </button>
                    </div>
                </div>
                {message && <Alert message={message}/>}
            </div>
        </Modal>
    )
}
