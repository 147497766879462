/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

const HeaderUserMenu: FC = () => {
    // const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const dispatch = useDispatch()
    const logout = () => {
        dispatch(auth.actions.logout())
    }

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-225px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>

                <div className='menu-item px-5'>
                    <Link to={'/profile/settings'} className='menu-link px-5'>
                        Настройки профиля
                    </Link>
                </div>

                <div className='separator my-2'/>

                <div className='menu-item px-5'>
                    <a onClick={logout} className='menu-link px-5'>
                        Выйти
                    </a>
                </div>
            </div>
        </div>
    )
}

export {HeaderUserMenu}
