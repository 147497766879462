import {useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {ImeiHistory} from "./history/ImeiHistory";
import {EditImei} from "./edit/EditImei";

export interface IImeiProps {
    msisdn?: string | undefined
    imei?: number | undefined
}

/**
 * IMEI
 */
export function Imei({msisdn, imei}: IImeiProps) {
    const [showEditForm, setShowEditForm] = useState(false)
    const [showHistory, setShowHistory] = useState(false)

    const closeModal = () => {
        setShowEditForm(false)
        setShowHistory(false)
    }

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0 pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">IMEI
                    <span
                        style={{cursor: "pointer"}}
                        onClick={() => setShowEditForm(true)}
                    ><KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-1 me-3 mx-3'/></span>
                </h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                <div className=" mb-2">
                    <span className="fw-bolder text-gray-800">Текущий: </span> <span> {imei ? imei : "Не определён"}</span>
                </div>
{/* История смены IMEI пока не готова на бекенде
                <div className=" mb-2">
                    <span className="fw text-info cursor-pointer"><span onClick={() => setShowHistory(true)}>Показать историю IMEI</span></span>
                </div>
*/}
            </div>
            {showEditForm &&
                <EditImei msisdn={msisdn} show={showEditForm} handleClose={closeModal}/>
            }
            {showHistory &&
            <ImeiHistory msisdn={msisdn} show={showHistory} handleClose={closeModal}/>
            }
        </div>
    )
}
