import {Tooltip, Zoom} from "@mui/material";
import {KTSVG} from "../../../../../_metronic/helpers";

/**
 * Переименование группы
 */
export function RenameGroupTooltip() {
    return (
        <div className="px-5 mt-6">
            <div>
                <Tooltip title="Доступно по двойному щелчку на названии группы" TransitionComponent={Zoom}>
                    <span className="d-flex align-items-center text-gray-600 fs-6">
                        <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                        <span>Переименовать группу</span>
                    </span>
                </Tooltip>
            </div>
        </div>
    )
}
