import {ReportType} from "../models/ReportType";
import {SimCardStatus} from "../../simcard/models/SimCardStatus";
import {InternalEventType} from "../../event/models/InternalEvent";
import * as XLSX from "xlsx";
import {WorkSheet} from "xlsx";

export namespace ExcelReportHelper {
    /**
     * Заголовок файла в зависимости от типа отчёта
     */
    function getExcelHeader(reportType: ReportType, startDate: string, endDate: string) {
        const topRows = [
            [
                'Отчётный период: ' + (new Date(startDate)).toLocaleDateString() + '-' + (new Date(endDate)).toLocaleDateString(),
            ],
            [
                'В отчёте представлены лишь те номера, по которым имеются данные'
            ],
            []
        ]

        switch (reportType) {
            case ReportType.INTERNET:
                return [
                    ...topRows,
                    [
                        'Номер телефона',
                        'Начисления',
                        'IP адрес',
                        'Тип звонка',
                        'Описание звонка',
                        'Тип соединения',
                        'Местонахождение абонента',
                        'Объем МБ'
                    ]
                ]
            case ReportType.SMS:
                return [
                    ...topRows,
                    [
                        'Номер телефона',
                        'Стоимость',
                        'Исходящий номер',
                        'Тип звонка',
                        'Тип соединения',
                        'Объем SMS'
                    ]
                ]
            case ReportType.STATUS:
                return [
                    [
                        'В отчёте представлены лишь те номера, по которым имеются данные'
                    ],
                    [],
                    [
                        'Номер телефона',
                        'Дата и время смены статуса',
                        'Статус',
                        'Дата последней активности',
                    ]
                ]
            case ReportType.DIAGNOSTICS:
                return [
                    ...topRows,
                    [
                        'Номер телефона',
                        'Тип диагностики',
                        'Дата последней проверки',
                        'Количество',
                    ]
                ]
        }
    }

    /**
     * Карта названий листов Excel
     * Все названия совпадают с типами отчётов кроме Диагностики, где нужно уложиться в 31 символ
     * @private
     */
    const worksheetNameMap = new Map<ReportType, string>([
        [ReportType.INTERNET, ReportType.getLabel(ReportType.INTERNET)],
        [ReportType.SMS, ReportType.getLabel(ReportType.SMS)],
        [ReportType.STATUS, ReportType.getLabel(ReportType.STATUS)],
        [ReportType.DIAGNOSTICS, 'Отчёт по инстр-там диагностики'],
    ])

    /**
     * Название листа Excel для указанного типа отчёта
     * @param reportType
     */
    export function getWorkSheetName(reportType: ReportType) {
        return worksheetNameMap.get(reportType)
    }

    /**
     * Заполнение строки Excel форматированными значениями
     * @param reportType
     * @param row
     */
    export function fillRow(reportType: ReportType, row) {
        switch (reportType) {
            case ReportType.INTERNET:
                return [
                    row.msisdn,
                    row.total_cost,
                    row.src_phone,
                    row.calltype.name,
                    row.connection_descr.name,
                    row.connection_type.name,
                    row.abonent_location.name,
                    row.total_traf
                ]
            case ReportType.SMS:
                return [
                    row.msisdn,
                    row.total_cost,
                    row.src_phone,
                    row.calltype.name,
                    row.connection_type.name,
                    row.total_count
                ]
            case ReportType.STATUS:
                const row_date = (new Date(row.date))
                const row_last_activity_ts = (new Date(row.last_activity_ts))
                return [
                    row.msisdn,
                    row_date.toLocaleDateString() + ' ' + row_date.toLocaleTimeString(),
                    SimCardStatus.getLabel(row.value),
                    row_last_activity_ts.toLocaleDateString() + ' ' + row_last_activity_ts.toLocaleTimeString(),
                ]
            case ReportType.DIAGNOSTICS:
                const last_check = (new Date(row.last_check))
                return [
                    row.msisdn,
                    InternalEventType.getLabel(row.event.type),
                    last_check.toLocaleDateString() + ' ' + last_check.toLocaleTimeString(),
                    row.count,
                ]
            default:
                return []
        }
    }

    export function setColumnWidths(worksheet: WorkSheet, reportType: ReportType) {
        switch (reportType) {
            case ReportType.INTERNET:
                worksheet["!cols"] = [
                    {wch: 16},
                    {wch: 12},
                    {wch: 14},
                    {wch: 14},
                    {wch: 24},
                    {wch: 20},
                    {wch: 26},
                    {wch: 12},
                ]
                break
            case ReportType.SMS:
                worksheet["!cols"] = [
                    {wch: 16},
                    {wch: 12},
                    {wch: 17},
                    {wch: 12},
                    {wch: 20},
                    {wch: 11},
                ]
                break
            case ReportType.STATUS:
                worksheet["!cols"] = [
                    {wch: 16},
                    {wch: 26},
                    {wch: 13},
                    {wch: 26}
                ]
                break
            case ReportType.DIAGNOSTICS:
                worksheet["!cols"] = [
                    {wch: 16},
                ]
                break
        }
    }

    type BuildReportArguments = {
        reportType: ReportType,
        startDate: string,
        endDate: string,
        rows: any[][]
    }

    export const buildReport = ({reportType, startDate, endDate, rows}: BuildReportArguments) => {

        // Заголовок листа
        let worksheet = XLSX.utils.aoa_to_sheet(getExcelHeader(reportType, startDate, endDate));

        // Настройки ширины столбцов
        ExcelReportHelper.setColumnWidths(worksheet, reportType)

        // Настройки слияния ячеек
        // if (dateRangeRequired(reportType))
        //     worksheet["!merges"] = [
        //         {s: {r: 0, c: 0}, e: {r: 0, c: 7}}
        //     ]

        for (let keyIn in rows) {
            for (let keyOut in rows[keyIn]) {
                XLSX.utils.sheet_add_aoa(worksheet, [
                        ExcelReportHelper.fillRow(reportType, rows[keyIn][keyOut])
                    ],
                    {origin: -1});
            }
        }

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, ExcelReportHelper.getWorkSheetName(reportType))
        XLSX.writeFileXLSX(workbook, "Report.xlsx")
    }
}
