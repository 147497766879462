import {useState} from "react";
import {SimCard} from "../../../models/Models";
import {ReplaceSimForm} from "./ReplaceSimForm";
import {EditInventoryNumberForm} from "./EditInventoryNumberForm";
import {KTSVG} from "../../../../../../_metronic/helpers";

export interface IGeneralInfoProps {
    cardInfo: SimCard | undefined
}

/**
 * Общая информация о SIM-карте
 */
export function GeneralInfo({cardInfo}: IGeneralInfoProps) {
    const [showReplaceSimForm, setShowReplaceSimForm] = useState(false)
    const [showEditInventoryNumberForm, setShowEditInventoryNumberForm] = useState(false)
    const [updatedInventoryNumber, setUpdatedInventoryNumber] = useState(cardInfo?.inventory_number)

    const closeShowReplaceSimForm = (updatedInventoryNumber?: string | undefined) => {
        setUpdatedInventoryNumber(updatedInventoryNumber ? updatedInventoryNumber : '')
        setShowEditInventoryNumberForm(false)
    }

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0 pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">Основные сведения </h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                <div className=" mb-2">
                    <span className="fw-bolder text-gray-800">Мобильный оператор: </span> <span> {cardInfo?.moboperator}</span>
                </div>
                <div className=" mb-2">
                    <span className="fw-bolder text-gray-800">ICCID: </span> <span> {cardInfo?.iccid}</span>
                </div>
                <div className=" mb-2">
                    <span className="fw-bolder text-gray-800">IMSI: </span> <span> {cardInfo?.imsi}</span>
                </div>
                <div className=" mb-2">
                    <span className="fw-bolder text-gray-800">Тарифный план: </span> <span> {cardInfo?.tarif}</span>
                </div>

                {updatedInventoryNumber
                    ? <div className=" mb-2">
                        <span className="fw-bolder text-gray-800">Инвентарный номер: </span> <span> {updatedInventoryNumber}</span>
                        <span
                            style={{cursor: "pointer"}}
                            onClick={() => setShowEditInventoryNumberForm(true)}
                        ><KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-2 me-3 mx-3'/></span>
                    </div>
                    : <div className=" mb-2">
                        <span className="fw text-info cursor-pointer"><span onClick={() => setShowEditInventoryNumberForm(true)}>Задать инвентарный номер</span></span>
                    </div>
                }
                {showEditInventoryNumberForm &&
                <EditInventoryNumberForm
                    msisdn={cardInfo?.msisdn}
                    inventoryNumber={updatedInventoryNumber || undefined}
                    show={showEditInventoryNumberForm}
                    handleClose={closeShowReplaceSimForm}
                />
                }

                <div className=" mb-2">
                    <span className="fw text-info cursor-pointer"><span onClick={() => setShowReplaceSimForm(true)}>Заменить SIM-карту</span></span>
                </div>
                {showReplaceSimForm &&
                <ReplaceSimForm
                    msisdn={cardInfo?.msisdn}
                    show={showReplaceSimForm}
                    handleClose={() => setShowReplaceSimForm(false)}
                />
                }
            </div>
        </div>
    )
}
