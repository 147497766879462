import clsx from "clsx";
import * as Yup from "yup";
import {useFormik} from "formik";
import {sendBillItemization} from "../../api";
import React, {useState} from "react";
import {Modal} from "react-bootstrap-v5";
import {ModalProps} from "../../models/Modal";
import {KTSVG} from "../../../../../_metronic/helpers";
import Alert from "../../../../components/alerts/Alert";
import {AlertType} from "../../../../components/alerts";

interface ISendBillItemizationFormProps extends ModalProps {
    msisdn: string | undefined
    period: string
}

const schema = Yup.object().shape({
    email: Yup.string()
        .email('Введите корректный адрес e-mail')
        .required("Введите корректный адрес e-mail")
})

/**
 * Форма для отправки расширенной детализации на E-mail
 */
export const SendBillItemizationForm = ({msisdn, period, show, handleClose}: ISendBillItemizationFormProps) => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const formik = useFormik({
        initialValues: {email: ''},
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            setLoading(true)
            const response = await sendBillItemization({msisdn: Number(msisdn), email: values.email, period})
            switch (response.status) {
                case "ok":
                    setLoading(false)
                    setSuccessMessage("Запрос об отправке расширенной детализации на указанный e-mail успешно выполнен.")
                    return true
                default:
                    setLoading(false)
                    console.log(response)
                    setErrorMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        },
    })

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setErrorMessage('')
        setSuccessMessage('')
        setLoading(false)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-450px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-content'>
                <div className='modal-header py-4'>
                    <h3>Расширенная детализация за месяц</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose()}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body pt-5 px-8 center'>
                    <div className="d-flex justify-content-between mb-5">
                        Введите адрес для отправки расширенной детализации за выбранный месяц
                    </div>

                    <form
                        className='form'
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <div className='d-flex justify-content-around align-items-center'>
                            <div className='fv-row w-100' style={{marginRight: "0.75rem"}}>
                                <input
                                    type='emial'
                                    autoComplete='off'
                                    placeholder='me@someaddress.com'
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.email && formik.errors.email
                                        },
                                        {
                                            'is-valid': formik.touched.email && !formik.errors.email,
                                        }
                                    )}
                                />
                            </div>

                            <div className='text-center'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{width: '106px'}}
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                                >
                                    {!loading && <span className='indicator-label'>Отправить</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                                    </span>
                                    )}
                                </button>

                            </div>
                        </div>
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container px-3'>
                                <div className='fv-help-block error-color'>
                                    <span role='alert'>{formik.errors.email}</span>
                                </div>
                            </div>
                        )}

                    </form>

                    <div>
                        {errorMessage && <Alert message={errorMessage}/>}
                        {successMessage && <Alert type={AlertType.SUCCESS} message={successMessage}/>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
