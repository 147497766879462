import {useState} from "react";
import {ActivateForm} from "./ActivateForm";
import {Rest} from "../../../../../models/common";
import {IHandleCloseProps} from "../../../models/Modal";

interface IActivateProps extends Rest {
    msisdn: string | undefined
    notActivated: boolean
    onFinish: () => void
}

/**
 * Активация номера (SIM-карты)
 */
export const Activate = ({msisdn, notActivated, onFinish, ...rest}: IActivateProps) => {
    const [show, setShow] = useState(false)

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        isSubmitted && onFinish()
    }

    return (
        <>
            {notActivated &&
            <button
                type='button'
                style={{marginLeft: "10px"}}
                className={rest.className || 'btn btn-primary'}
                onClick={() => setShow(true)}
            >
                {'Активировать'}
            </button>
            }
            {show &&
            <ActivateForm
                msisdn={msisdn}
                show={show}
                handleClose={closeModal}
                onChange={onFinish}
            />
            }
        </>
    )
}
