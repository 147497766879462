/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import SimCardPage from "./SimCardPage";
import {SimCardListPage} from "./SimCardListPage";
import {Redirect, Route, Switch} from "react-router-dom";
import {DetailPage} from "../components/detail/DetailPage";
import {LimitListPage} from "../components/limit/LimitListPage";
import {GeoPage} from "../components/geo/GeoPage";
import {ServiceListPage} from "../components/services/ServiceListPage";
import {SimCardBalanceHistory} from "../components/balance/BalanceHistoryPage";

export const SimCardListWrapper: FC = (props) => {
    return (
        <Switch>
            <Route exact path='/simcard/list'>
                <SimCardListPage/>
            </Route>
            <Route path='/simcard/list/:msisdn/details'>
                <SimCardPage />
            </Route>
            <Route path='/simcard/list/:msisdn/services'>
                <ServiceListPage />
            </Route>
            <Route path='/simcard/list/:msisdn/balance'>
                <SimCardBalanceHistory />
            </Route>
            <Route path='/simcard/list/:msisdn/limit'>
                <LimitListPage />
            </Route>
            <Route path='/simcard/list/:msisdn/geo'>
                <GeoPage />
            </Route>
            <Route path='/simcard/list/:msisdn/detail'>
                <DetailPage />
            </Route>
            <Redirect from='/simcard' to='/simcard/list' />
            <Redirect from='/simcard/' to='/simcard/list' />
            <Redirect to='/simcard/list' />
        </Switch>
    )
}
