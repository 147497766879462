import {deleteDeviceType} from "../../api";
import {Modal} from "react-bootstrap-v5";
import {useEffect, useState} from "react";
import {ExtendedModalProps} from "../../models/Modal";
import {KTSVG} from "../../../../../_metronic/helpers";
import Alert from "../../../../components/alerts/Alert";

interface IDeleteDeviceTypeFormProps extends ExtendedModalProps {
    deviceTypeIdList: number[]
}

/**
 * Форма удаления типов устройств
 */
export const DeleteDeviceTypeForm = ({deviceTypeIdList, show, handleClose}: IDeleteDeviceTypeFormProps) => {
    const [message, setMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(() => {
        const getResponse = async () => {
            if (deviceTypeIdList && deviceTypeIdList.length !== 0) {
                let countDown = deviceTypeIdList.length
                for (const devict_type_id of deviceTypeIdList) {
                    const response = await deleteDeviceType(devict_type_id)
                    switch (response.status) {
                        case "ok":
                            if (--countDown === 0) {
                                handleClose({isSubmitted})
                            }
                            continue;
                        case "error":
                            console.log(response)
                            setMessage("Сервер не смог обработать запрос. Попробуйте удалить типы устройств позднее.")
                            return false;
                    }
                }
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted})
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-content'>
                <div className='modal-header py-4'>
                    <h3>Удаление типов устройств</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body pt-5 px-10 center'>
                    <div className="d-flex justify-content-between mb-5">
                        Подтвердите удаление
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary" onClick={() => setIsSubmitted(true)}>Подтвердить</button>
                    </div>
                    <div>
                        {message && <Alert message={message}/>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
