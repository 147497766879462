import {useEffect, useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {formatListHTML} from "../../../../../helpers/Formatter";
import {GroupListForm, SimCardGroupWithCheckedItems} from "./GroupListForm";

export interface IGroupListProps {
    msisdn?: string | undefined
    groupList?: SimCardGroupWithCheckedItems[]
}

/**
 * Компонент для добавления/удаления номера SIM-карты в существующие группы
 */
export function GroupList({msisdn, groupList}: IGroupListProps) {
    const [show, setShow] = useState(false)
    const [updatedGroupList, setUpdatedGroupList] = useState<SimCardGroupWithCheckedItems[] | undefined>([])

    const closeModal = (updatedGroupList?: SimCardGroupWithCheckedItems[]) => {
        setShow(false)
        updatedGroupList && setUpdatedGroupList(updatedGroupList)
    }

    useEffect(() => {
        setUpdatedGroupList(groupList)
    }, [groupList])

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0 pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">Группы
                    <span
                        style={{cursor: "pointer"}}
                        onClick={() => setShow(true)}
                    ><KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-1 me-3 mx-3'/></span>
                </h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                <span>{updatedGroupList?.length !== 0 && formatListHTML(updatedGroupList, 'name')}</span>
            </div>
            {show &&
            <GroupListForm msisdn={msisdn} groupList={updatedGroupList} show={show} handleClose={closeModal}/>
            }
        </div>
    )
}
