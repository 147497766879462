import {SimCardStatus} from "./SimCardStatus";
import {SlicedRequest, SlicedResponse} from "../../../models/common";

export interface SimCard {
    msisdn: number
    /** Баланс */
    balance: number
    /** Статус номера */
    status: SimCardStatus
    /** Тарифный план */
    tarif: string
    /** Трафик с 1 числа календарного месяца, в байтах */
    traffic: number
    /** Наименование устройства */
    device_name: string
    /** Устройство */
    device_type: DeviceType
    /** Адрес устройства */
    device_address: string
    /** Комментарий к адресу устройства */
    device_address_comment: string
    /** Комментарий (текстовое описание устройства) */
    description: string
    /** Инвентарный номер */
    inventory_number?: string | undefined
    imsi: number
    imei: number
    iccid: string
    /** APN */
    apn: string
    ip?: string
    /** Дата активации номера */
    activation_date: string
    /** Дата последней активности на номере */
    last_activity_ts: string
    /** Список групп, в которых состоит номер */
    groups: SimCardGroup[]
    /** Флаг, определяющий, может ли быть изменен статус номера: 0 - нет, 1 - да.  */
    flag_can_status_change: number
    moboperator: string
}

export interface SimCardGroup {
    /** Идентификатор группы */
    id: number
    /** Название группы */
    name: string
}

export interface SimCardBalance {
    /** Значение баланса */
    value: number
    /** Дата изменения баланса */
    date: string
}

export interface SimCardContact {
    /** Идентификатор контакта */
    id: number
    /** Название контакта */
    name: string
    /** E-mail */
    email: string
    /** Телефон */
    phone?: string
    /** Описание */
    descr?: string
}

export interface DeviceType {
    /** Идентификатор типа устройств */
    id: number
    /** Название типа устройств */
    name: string
    /** Марка типа устройств */
    device_brand?: string
    /** Модель типа устройств */
    device_model?: string
    /** Поддерживаемые поколения связи */
    generation?: string[]
    /** Описание типа устройств */
    descr?: string
}

export interface SimCardBalanceHistoryResponse {
    data: {
        items: SimCardBalance[]
    }
}

export interface SimCardListRequest extends SlicedRequest {
    /**
     * Данные только по определенному номеру в десятизначном формате.
     */
    msisdn?: string | undefined
    /**
     * Идентификатор группы, по которой требуется вернуть данные.
     */
    group_id?: number | undefined
}

export interface SimCardListResponse extends SlicedResponse {
    data: {
        items: SimCard[]
    }
}

/**
 * Общие действия по добавлению / удалению объектов с помощью методов API
 */
export enum Action {
    ADD = 'add',
    DELETE = 'delete'
}

export type ActionOrNull = Action | null

/**
 * Степень вхождения выделенных номеров в обобщённую группу (абстрактную общность)
 * 1. full - все выделенные номера входят в группу (кнопка выбора группы в виджете назначения групп нажата полностью)
 * 2. partial - только часть номеров входит в группу (кнопка выбора группы нажата частично, соответствует чекбоксу в промежуточном состоянии (с минусом))
 * 3. null - ни один номер не входит в группу (кнопка полностью отжата).
 */
export enum Scope {
    FULL = 'full',
    PARTIAL = 'partial'
}

export type ScopeOrNull = Scope | null

/**
 * Запись об изменении IMEI на номере
 */
export interface SimCardImeiHistoryItem {
    imei: string
    date_start: string
    date_end: string
}

/**
 * Обратная связь
 */
export interface Feedback {
    name: string
    phone?: string | undefined
    email?: string | undefined
    text: string
}
