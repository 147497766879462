/* eslint-disable jsx-a11y/anchor-is-valid */
import {Redirect, Route, Switch} from "react-router-dom";
import {ReportPage} from "./ReportPage";

export function SectionWrapper() {
    return (
        <Switch>
            <Route exact path='/report'>
                <ReportPage/>
            </Route>
            <Redirect from='/report/' to='/report'/>
            <Redirect to='/report'/>
        </Switch>
    )
}
