import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {FC, useEffect} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";

interface ITopSliderMenuProps {
    show: boolean
    simCardCounter: number
    onClose: () => void
}

export interface ITopSliderMenuItemProps {
    label: string
    onFinish?: () => void
}

/**
 * Выдвижное меню
 */
const TopSliderMenu: FC<ITopSliderMenuProps> = ({show, simCardCounter, onClose, children}) => {
    const [state, setState] = React.useState(show);

    useEffect(() => setState(show), [show])

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState(open);
        };

    const menu = () => (
        <Box
            sx={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                background: 'none'
            }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
        >
            <div style={{width: "100%"}}>
                <div className="container d-flex flex-stack h-50px bg-white">
                    <div className="d-flex align-items-center justify-content-between flex-grow-1 py-2">
                        <div className="d-flex align-items-center">
                            <h4 className="text-gray-600 mb-0">Выбрано симкарт: {simCardCounter}</h4>
                        </div>
                        <div className="d-flex align-items-center h-30px">
                            <div>
                                {children}
                            </div>
                            <div>
                            <button
                                type='button'
                                onClick={onClose}
                                style={{marginLeft: "20px", marginBottom: "2px"}}
                                className='btn btn-sm btn-icon btn-active-light-primary'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );

    return (
        <div>
            <Drawer
                anchor={'top'}
                hideBackdrop
                open={state}
                onClose={toggleDrawer(false)}
                onClick={(event) => event.stopPropagation()}
            >
                {menu()}
            </Drawer>
        </div>
    );
}

export default TopSliderMenu