import axios from 'axios'
import {API_URL} from "../../../../config";
import {UserModel, AuthRequest, AuthResponse, AuthError} from "../Models";
import {OkResponse, ErrorResponse, processResponse} from "../../../api/CommonApi";

export const LOGIN_URL = `${API_URL}/m2m/auth/login.cgi`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/m2m/api/user/get_info.cgi`

/**
 * Запрос токена авторизации по логину/паролю
 */
export function fetchTokenByLoginPassword({
                                              username,
                                              password,
                                              client_id = 'anything',
                                              grant_type = 'password'
                                          }: Partial<AuthRequest>): Promise<OkResponse<AuthResponse> | ErrorResponse<AuthError>> {
    const response = axios.post<AuthResponse>(LOGIN_URL, {username, password, client_id, grant_type})
    return processResponse<AuthResponse, AuthError>(response)
}

/**
 * Обновление токена авторизации с помощью refresh-токена
 */
export function fetchTokenByRefreshToken({
                                             refresh_token,
                                             client_id = 'anything',
                                             grant_type = 'refresh_token'
                                         }: Partial<AuthRequest>): Promise<OkResponse<AuthResponse> | ErrorResponse<AuthError>> {
    const response = axios.post<AuthResponse>(LOGIN_URL, {refresh_token, client_id, grant_type})
    return processResponse<AuthResponse, AuthError>(response)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {email})
}

/**
 * Токен для авторизации через заголовок "Authorization" подставляется в interceptor-е (cм. setupAxios).
 */
export function getUserByToken() {
    return axios.get<{ user: UserModel }>(GET_USER_BY_ACCESSTOKEN_URL)
}
