import {createTheme} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {dateTimeFormatter} from "../../../../helpers/Formatter";
import {Service} from "../../models/Services";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'},
                    whiteSpace: 'unset',
                    lineHeight: '1.2'
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = (): GridColDef[] => [
    {
        field: 'name',
        headerName: 'Наименование услуги',
        width: 290
    },
    {
        field: 'requested_date',
        headerName: 'Заявка подана',
        headerAlign: 'center',
        align: 'center',
        width: 155,
        hide: true,
        renderCell: (params) => dateTimeFormatter(params.value),
    },
    {
        field: 'activated_date',
        headerName: 'Дата активации',
        headerAlign: 'center',
        align: 'center',
        width: 155,
        renderCell: (params) => params.value ? dateTimeFormatter(params.value) : <><span>активация...</span><span className='spinner-border spinner-border-sm align-middle ms-2'> </span></>
    },
    {
        field: 'period_fee',
        headerName: 'Стоимость',
        width: 100,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => Math.round(params.value)
    },
    {
        field: 'period_type',
        headerName: 'Тип периода',
        width: 200,
        hide: true,
        renderCell: (params) => params.row.name
    },
    {
        field: 'connection_fee',
        headerName: 'Плата за подключение услуги',
        width: 200,
        hide: true
    },
    {
        field: 'desc',
        headerName: 'Описание услуги',
        width: 200,
        hide: true
    },
    {
        field: 'can_change',
        type: 'boolean',
        headerName: 'Отключаемая',
        width: 150
    },
    {
        field: 'category_type',
        type: 'boolean',
        headerName: 'Категория услуг',
        width: 150,
        hide: true,
        renderCell: (params) => params.row.name
    },
]

export const getRows = (serviceList: Service[] | undefined): any => serviceList?.map((service) =>
    ({
        ...service,
        can_change: service.can_change === 1
    })
)
