import {useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {IHandleCloseProps} from "../../../simcard/models/Modal";
import {DeleteEventSubscriptionForm} from "./DeleteEventSubscriptionForm";

export interface IDeleteEventSubscriptionProps {
    subscriptionIdList: number[]
    onFinish: () => void
}

/**
 * Удаление подписок на события
 */
export function DeleteEventSubscription({subscriptionIdList = [], onFinish}: IDeleteEventSubscriptionProps) {
    const [show, setShow] = useState(false)

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        isSubmitted && onFinish()
    }

    return (
        <div className="px-5 mt-6">
            <div>
                <span className="d-flex align-items-center text-gray-600 fs-6" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                    <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                    <span>Удалить</span>
                </span>
            </div>
            <DeleteEventSubscriptionForm subscriptionIdList={subscriptionIdList} show={show} handleClose={closeModal}/>
        </div>
    )
}
