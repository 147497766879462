import React, {FC, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom';
import {fetchSimCardInfo} from "../api";
import {SimCard} from "../models/Models";
import {SectionHeader} from "../SectionHeader";
import {SimCardHeader} from "./SimCardHeader";
import {PageTitle} from "../../../../_metronic/layout/core";
import {Overview} from "../components/overview/Overview";
import {formatPhoneNumberFrom10Digits} from "../../../helpers/Formatter";
import AlertDismissible from "../../../components/alerts/AlertDismissible";
import {InnerButtons} from "../components/overview/InnerButtons";

const SimCardPage: FC = () => {
    const [refresher, refreshInfo] = useState(Date.now())
    const [message, setMessage] = useState('')
    const params: { msisdn?: string | undefined } = useParams();
    const [cardInfo, setCardInfo] = useState<SimCard | undefined>(undefined)
    const msisdn = useRef(formatPhoneNumberFrom10Digits(params.msisdn))

    useEffect(() => {
        const getSimCardInfo = async () => {
            const response = await fetchSimCardInfo({msisdn: params.msisdn})
            switch (response.status) {
                case "ok":
                    const simCardList = response.data.data.items as SimCard[]
                    setCardInfo(simCardList[0])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }
        getSimCardInfo()
    }, [refresher])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <PageTitle breadcrumbs={[]}>Подробная информация по SIM-карте для номера {msisdn.current}</PageTitle>
            <div className="card">
                <SectionHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body d-flex align-items-center justify-content-between pt-5 pb-0">
                    <h1 style={{lineHeight: '1.6'}} className="text-gray-600">{msisdn.current}</h1>
                    <InnerButtons
                        msisdn={params.msisdn}
                        cardInfo={cardInfo}
                        onFinish={() => refreshInfo(Date.now())}
                    />
                </div>

                <SimCardHeader/>

                <div className="card-body pt-0">
                    <Overview msisdn={params.msisdn} cardInfo={cardInfo}/>
                </div>
            </div>
        </>
    )
}

export default SimCardPage
