/* eslint-disable jsx-a11y/anchor-is-valid */
import {SimCardImeiHistoryItem} from "../../../../models/Models";
import {imeiHistory as fetchImeiHistory} from "../../../../api";
import {ThemeProvider} from "@mui/material";
import React, {useEffect, useState} from 'react'
import {DataGrid, ruRU} from "@mui/x-data-grid";
import Alert from "../../../../../../components/alerts/Alert";
import {getColumns, getRows, theme} from "./ImeiHistoryDataGridConfig";
import {ModalProps} from "../../../../models/Modal";
import {Modal} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../../../_metronic/helpers";

interface IImeiHistoryProps extends ModalProps {
    msisdn?: string | number | undefined
}

export function ImeiHistory({msisdn, show, handleClose}: IImeiHistoryProps) {
    const [message, setMessage] = useState('')
    const [imeiHistory, setImeiHistory] = useState<SimCardImeiHistoryItem[] | undefined>([])

    useEffect(() => {
        const getImeiHistory = async () => {
            if (msisdn) {
                const response = await fetchImeiHistory(msisdn)
                switch (response.status) {
                    case "ok":
                        setImeiHistory(response.data.data.items as SimCardImeiHistoryItem[])
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                        return false;
                }
            }
        }

        getImeiHistory()
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            className='modal'
            style={{maxWidth: "unset"}}
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-600px"
            onHide={handleClose}
        >
            <div className='modal-header py-4'>
                <h3>История смены IMEI</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose()}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body  pt-5 px-10 center'>
                {message && <Alert additionalClassNames='mb-0' message={message}/>}

                <div className="card-body px-0">
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            autoHeight
                            hideFooter
                            disableColumnMenu
                            columns={getColumns()}
                            rows={getRows(imeiHistory)}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </Modal>
    )
}
