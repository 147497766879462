import {AxiosResponse} from "axios";
import {pause} from "../helpers/GeneralHelper";
import {SlicedResponse} from "../models/common";

export type OkResponse<T> = {
    status: 'ok'
    data: T
}

export type ErrorResponse<T> = {
    status: 'error'
    data: T
}

export interface GenericResponse<T> {
    data: {
        items: T[]
    }
}

export interface GenericSlicedResponse<T> extends SlicedResponse{
    data: {
        items: T[]
    }
}

export type AuthorizationError = 'authorization_failed'

export type CommonErrorTypes = 'permission_denied' | 'invalid_request' | 'internal_error' | 'generalError'

export type CommonError = {
    error: CommonErrorTypes
}

export type GeneralError = {
    error: 'generalError'
}

/**
 * Обработка ответа сервера
 */
export function processResponse<T, E>(response: Promise<AxiosResponse<T>>): Promise<OkResponse<T> | ErrorResponse<E|GeneralError>> {
    return response
        .then((response) => ({
            status: 'ok',
            data: response.data
        } as OkResponse<T>))
        .catch(async (error) => {
            // Если это ошибка обработки запроса бекендом
            if (error.response?.data.error) {
                switch (error.response?.data.error) {
                    // При сбое авторизации по токену перезагружаем страницу для его обновления
                    case 'authorization_failed':
                        window.location.reload()
                        // Притормаживаем срабатывание интерфейса перед началом перезагрузки страницы, чтобы не выводить лишних предупреждений
                        await pause(2000)
                        break
                    default:
                        return {
                            status: 'error',
                            data: error.response?.data
                        } as ErrorResponse<E>
                }
            }
            // Если это общая ошибка сервера
            return {
                status: 'error',
                data: {
                    error: "generalError"
                }
            }  as ErrorResponse<GeneralError>
        })
}
