import {SimCard, SimCardGroup} from "../models/Models";
import {createTheme, Tooltip, Zoom} from "@mui/material";
import {KTSVG} from "../../../../_metronic/helpers";
import {SimCardStatus} from "../models/SimCardStatus";
import {GridCellValue, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {dateTimeFormatterWithFilter, formatListHTML, formatListPlainText, nl2br} from "../../../helpers/Formatter";

export interface GridSimCard extends SimCard {
    id: number
    trafficKb: number
    trafficMb: number
    trafficGb: number
}

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.05rem',
                    border: 'none',
                    classes: 'text-gray-600'
                },
                cell: {
                    ':focus': {outline: 'none'},
                    ':focus-within': {outline: 'none'},
                    display: 'flex',
                    whiteSpace: 'unset',
                    alignItems: 'center',
                    lineHeight: '1.2'
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ':focus': {outline: 'none'},
                    ':focus-within': {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
                columnHeaders: {
                    zIndex: 1250
                },
                columnHeadersInner: {
                    zIndex: 1250
                }
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3',
                    // Чтобы иметь возможность выделять строки таблицы над оверлеем всплывающего меню
                    zIndex: 1250
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem'
                },
                selectLabel: {
                    fontSize: '1.075rem',
                    marginTop: '12px'
                },
                displayedRows: {
                    fontSize: '1.075rem',
                    marginTop: '14px'
                }
            }
        }
    },
});

const getMsisdnWithIcon = (params: GridRenderCellParams) => {
    return params.row.status === 'active'
        ?
        <Tooltip
            placement={'top'}
            TransitionComponent={Zoom}
            title="Номер активен"
        >
            <span>
            <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon-1 svg-icon-success'
            />
            </span>
        </Tooltip>
        :
        <Tooltip
            placement={'top'}
            TransitionComponent={Zoom}
            title="Номер заблокирован"
        >
            <span>
            <KTSVG
                path='/media/icons/duotune/general/gen047.svg'
                className='svg-icon-1'
            />
            </span>
        </Tooltip>
}

const getMsisdnLink = (params: GridRenderCellParams) => {
    return <>
        {params.row.msisdn}
        <a
            href={'/simcard/list/' + params.row.msisdn + '/details'}
            style={{marginBottom: "2px"}}
            target={"_blank"}
            rel="noreferrer"
        >
        &nbsp;
            <i className="fas fa-external-link-alt"></i>
        </a>
    </>
}

export const getColumns = (): GridColDef[] => [
    {
        field: 'status',
        type: 'singleSelect',
        hide: false,
        valueOptions: [SimCardStatus.ACTIVE, SimCardStatus.BLOCKED],
        headerName: 'Статус',
        width: 40,
        // Не выводим название в заголовке таблицы ради экономии места
        renderHeader: (params) => ' ',
        renderCell: getMsisdnWithIcon,
        valueFormatter: ({value}: { value: GridCellValue }) => value === 'active' ? 'Активен' : 'Блокирован',
    },
    {
        field: 'activation_date',
        hide: true,
        headerName: 'Дата активации',
        width: 155,
        valueFormatter: ({value}: { value: GridCellValue }) => value ? new Date(value as string).toLocaleDateString() : ''
    },
    {
        field: 'last_activity_ts',
        hide: true,
        headerName: 'Последняя активность',
        width: 180,
        valueFormatter: ({value}: { value: GridCellValue }) => dateTimeFormatterWithFilter(value),
    },
    {
        field: 'msisdn',
        hide: false,
        hideable: false,
        headerName: 'MSISDN',
        width: 110,
        renderCell: getMsisdnLink,
    },
    {
        field: 'balance',
        hide: false,
        headerAlign: 'center',
        align: 'center',
        headerName: 'Баланс',
        width: 100,
    },
    {
        field: 'moboperator',
        hide: false,
        headerName: 'Оператор',
        headerAlign: 'center',
        align: 'center',
        width: 110
    },
    {
        field: 'iccid',
        hide: false,
        headerName: 'ICCID',
        width: 180
    },
    {
        field: 'imsi',
        hide: false,
        headerName: 'IMSI',
        width: 165
    },
    {
        field: 'imei',
        hide: false,
        headerName: 'IMEI',
        width: 140
    },
    {
        field: 'tarif',
        hide: false,
        headerName: 'Тарифный план',
        width: 140
    },

    /**
     * Эти столбцы (trafficColumns) переключаются по логике радиокнопки:
     * видимым может быть лишь один, либо все скрыты
     */
    {
        field: 'traffic',
        hide: true,
        headerName: 'Трафик (байт)',
        width: 130,
    },
    {
        field: 'trafficKb',
        hide: true,
        headerName: 'Трафик (Кб)',
        width: 130,
    },
    {
        field: 'trafficMb',
        hide: true,
        headerName: 'Трафик (Mб)',
        width: 130,
    },
    {
        field: 'trafficGb',
        hide: true,
        headerName: 'Трафик (Гб)',
        width: 130,
    },
    /* End trafficColumns */

    {
        field: 'device_name',
        hide: false,
        headerName: "Устройство",
        description: 'Название устройства',
        width: 130
    },
    {
        field: 'apn',
        hide: false,
        headerName: "APN",
        width: 140
    },
    {
        field: 'ip',
        hide: false,
        headerName: "IP",
        width: 140,
        sortable: false
    },
    {
        field: 'groups',
        hide: false,
        headerName: "Группы",
        width: 160,
        renderCell: (params: GridRenderCellParams) => formatListHTML(params.row.groups, 'name'),
        valueGetter: (params) => formatListPlainText(params.row.groups as SimCardGroup[], 'name')
    },
    {
        field: 'device_address',
        hide: false,
        headerName: "Адрес устройства",
        width: 160
    },
    {
        field: 'description',
        hide: false,
        headerName: "Комментарий",
        description: 'Текстовое описание устройства',
        width: 200,
        renderCell: (params: GridRenderCellParams) => nl2br(params.row.description)
    },
]

export const getRows = (cardList: SimCard[] | undefined): Partial<GridSimCard>[] | undefined =>
    cardList?.map((simCard) =>
        ({
            ...simCard,
            id: simCard.msisdn,
            trafficKb: Number((simCard.traffic / 1024).toFixed(0)),
            trafficMb: Number((simCard.traffic / 1048576).toFixed(0)),
            trafficGb: Number((simCard.traffic / 1073741824).toFixed(2))
        })
    )
