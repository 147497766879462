/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {fetchEventList} from "../api";
import {ThemeProvider} from "@mui/material";
import {SectionHeader} from "../SectionHeader";
import {PageTitle} from "../../../../_metronic/layout/core";
import AlertDismissible from "../../../components/alerts/AlertDismissible";
import {getColumns, getRows, theme} from "./InternalEventListDataGridConfig";
import {DataGrid, GridToolbarContainer, GridToolbarFilterButton, ruRU} from "@mui/x-data-grid";
import {InternalEvent} from "../models/InternalEvent";

export function InternalEventListPage() {
    const [message, setMessage] = useState('')
    const [eventList, setEventList] = useState<InternalEvent[] | undefined>(undefined)

    useEffect(() => {
        const getResponse = async () => {
            const response = await fetchEventList({})
            switch (response.status) {
                case "ok":
                    setEventList(response.data.data.items as InternalEvent[])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getResponse()
    }, [])

    return (
        <>
            <PageTitle breadcrumbs={[]}>События</PageTitle>

            <div className="card">
                <SectionHeader/>

                {message && <AlertDismissible message={message}/>}

                <div style={{height: "80vh"}} className="card-body d-flex">
                    <div style={{flexGrow: 1}}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                density='compact'
                                autoPageSize={true}
                                disableSelectionOnClick
                                disableColumnMenu
                                columns={getColumns()}
                                rows={getRows(eventList) || []}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                components={{
                                    Toolbar: () => (
                                        <GridToolbarContainer style={{float: 'right'}}>
                                            <GridToolbarFilterButton/>
                                        </GridToolbarContainer>
                                    ),
                                }}
                            />
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </>
    )
}
