/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {SimCardGroup} from "../models/Models";
import {fetchGroupList, renameGroup} from "../api";
import {ThemeProvider} from "@mui/material";
import {SectionHeader} from "../SectionHeader";
import {CreateGroup} from "./create/CreateGroup";
import {PageTitle} from "../../../../_metronic/layout/core";
import {DeleteGroup} from "./delete/DeleteGroup";
import {RenameGroupTooltip} from "./rename/RenameGroupTooltip";
import {getColumns, getRows, theme} from "./SimCardGroupDataGridConfig";
import {DataGrid, GridCellEditCommitParams, GridSelectionModel, ruRU} from "@mui/x-data-grid";
import AlertDismissible from "../../../components/alerts/AlertDismissible";

export function SimCardGroupListPage() {
    const [refresh, setRefresh] = useState(Date.now());
    const [message, setMessage] = useState('')
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [groupList, setGroupList] = useState<SimCardGroup[] | undefined>([])

    useEffect(() => {
        const getResponse = async () => {
            const response = await fetchGroupList()
            switch (response.status) {
                case "ok":
                    setGroupList(response.data.data.items as SimCardGroup[])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getResponse()
    }, [refresh])

    // Переименование группы в ячейке таблицы
    const rename = async (params: GridCellEditCommitParams) => {
        const response = await renameGroup(params.id.toString(), params.value as string)
        switch (response.status) {
            case "ok":
                return setRefresh(Date.now())
            case "error":
                console.log(response)
                setMessage("Сервер не смог обработать запрос. Попробуйте переименовать группу позднее.")
                return false
        }
    }


    const refreshPage = () => {
        setRefresh(Date.now())
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Группы</PageTitle>

            <div className="card">
                <SectionHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body d-flex justify-content">
                    <div className="w-450px">
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                autoHeight
                                hideFooter
                                editMode={'cell'}
                                onCellEditCommit={rename}
                                checkboxSelection
                                disableColumnMenu
                                disableSelectionOnClick
                                columns={getColumns()}
                                rows={getRows(groupList)}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div>
                        <CreateGroup groupName={''} onCreate={refreshPage}/>
                        <RenameGroupTooltip/>
                        {selectionModel.length !== 0 &&
                        <DeleteGroup groupIdList={selectionModel as number[]} onFinish={refreshPage}/>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
