import {useState} from "react";
import {SimCard} from "../../models/Models";
import {ITopSliderMenuItemProps} from "../topSliderMenu";
import {SetGroupAssignmentsForm} from "./SetGroupAssignmentsForm";
import {Rest} from "../../../../models/common";

export interface ISetGroupAssignmentsProps extends ITopSliderMenuItemProps, Rest {
    selectedRows: Partial<SimCard>[]
}

/**
 * Управление вхождением номера в группы
 */
export const SetGroupAssignments = ({label, msisdnList, selectedRows, onFinish, ...rest}: ISetGroupAssignmentsProps) => {
    const [show, setShow] = useState(false)
    const closeModal = () => setShow(false)

    return (
        <>
            <button
                type='button'
                className={rest.className || ""}
                onClick={() => setShow(true)}
            >
                {label}
            </button>
            {show &&
            <SetGroupAssignmentsForm
                selectedRows={selectedRows}
                show={show}
                handleClose={closeModal}
                onChange={onFinish}
            />
            }
        </>
    )
}
