/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {fetchTokenByLoginPassword} from '../api'

const loginSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Логин должен содержать минимум 3 символа')
        .max(25, 'Логин может содержать максимум 25 символов')
        .required('Введите правильный логин'),
    password: Yup.string()
        .min(8, 'Пароль должен содержать минимум 8 символов')
        .max(30, 'Пароль может содержать максимум 30 символов')
        .required('Введите правильный пароль'),
})

const initialValues = {
    username: '',
    password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            const {username, password} = values
            setLoading(true)
            const response = await fetchTokenByLoginPassword({username, password})
            switch (response.status) {
                case "ok":
                    setLoading(false)
                    dispatch(auth.actions.fetchTokens(response.data.access_token, response.data.refresh_token))
                    return
                case "error":
                    setLoading(false)
                    setSubmitting(false)
                    if (response.data.error === 'invalid_scope') {
                        setStatus('Введён неверный логин или пароль.')
                        return
                    }

                    if (response.data.error === 'generalError') {
                        setStatus('Ошибка сервера. Попробуйте войти позже.')
                        return
                    }
            }
        },
    })

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Вход</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                    M2M-портал для корпоративных клиентов
                </div>
            </div>
            {/* begin::Heading */}

            {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>
                        Для входа необходимы <strong>логин</strong> и <strong>пароль</strong>, указанные в
                        регистрационных данных к сервису.
                    </div>
                </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Логин</label>
                <input
                    placeholder=''
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.username && formik.errors.username
                        },
                        {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }
                    )}
                    type='text'
                    name='username'
                    autoComplete='off'
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container error-color'>
                        <div className='fv-help-block error-color'>
                            <span role='alert'>{formik.errors.username}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>

                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Пароль</label>
                        {/* end::Label */}
                    </div>

                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Link */}
                        {/*
                        <Link
                            to='/auth/forgot-password'
                            className='link-primary fs-6 fw-bolder'
                            style={{marginLeft: '5px'}}
                        >
                            Забыли пароль?
                        </Link>
*/}
                        {/* end::Link */}
                    </div>

                </div>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block error-color'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Войти</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>Обрабатываем...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                        </span>
                    )}
                </button>

            </div>
            {/* end::Action */}
        </form>
    )
}
