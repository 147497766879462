import {Modal} from "react-bootstrap-v5";
import {IGroupListProps} from "./GroupList";
import {Action, ActionOrNull, SimCardGroup} from "../../../models/Models";
import React, {useEffect, useRef, useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {editGroup, fetchGroupList} from "../../../api";
import Alert from "../../../../../components/alerts/Alert";
import {AlertType} from "../../../../../components/alerts";

export interface SimCardGroupWithCheckedItems extends SimCardGroup {
    checked?: boolean
}

interface IGroupListFormProps extends IGroupListProps {
    show: boolean
    handleClose: (updatedGroupList?: SimCardGroupWithCheckedItems[]) => void
}

/**
 * Форма редактирования вхождения номера в группы
 */
export const GroupListForm = ({msisdn, groupList, show, handleClose}: IGroupListFormProps) => {
    const [, refresh] = useState(0)
    const [message, setMessage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const diffMap = useRef<Map<number, ActionOrNull>>(new Map())
    const initialGroupList = useRef<SimCardGroupWithCheckedItems[]>([])
    const [updatedGroupList, setUpdatedGroupList] = useState<SimCardGroupWithCheckedItems[] | undefined>()

    /**
     * 1. Добавление в группы свойства checked согласно вхождению номера в группы при первоначальной загрузке
     * 2. Заполнение initialGroupList и diffMap
     */
    useEffect(() => {
            const getCheckedState = (result: SimCardGroup[], updatedItem: SimCardGroupWithCheckedItems): boolean => {
                if (groupList && groupList.length !== 0) {
                    return groupList.filter((initialItem) => initialItem.id === updatedItem.id).length !== 0
                }
                return false
            }

            const getResponse = async () => {
                const response = (await fetchGroupList())
                switch (response.status) {
                    case "ok":
                        const groupList: SimCardGroup[] = response.data.data.items
                        if (groupList) {
                            if (groupList.length !== 0) {
                                setUpdatedGroupList(
                                    groupList.map((item, index) => {
                                        diffMap.current.set(item.id, null)
                                        initialGroupList.current[index] = {
                                            ...item,
                                            checked: getCheckedState(groupList, item)
                                        }
                                        let clone: SimCardGroupWithCheckedItems = {id: 0, name: ''}
                                        Object.assign(clone, initialGroupList.current[index])
                                        return clone
                                    })
                                )
                            } else {
                                // Для вывода сообщения об отсутствии групп лишь после того, как отработает запрос
                                setUpdatedGroupList([])
                            }
                        }
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер сообщил об ошибке. Попробуйте выполнить операцию позднее.")
                        return false
                }
            }

            getResponse()
        },
        [groupList]
    )

    const buildDiffMap = () => {
        updatedGroupList?.forEach((item, index) => {
            const diff = +item.checked! - +initialGroupList.current[index].checked!
            switch (diff) {
                case 1:
                    diffMap.current.set(item.id, Action.ADD)
                    break
                case -1:
                    diffMap.current.set(item.id, Action.DELETE)
                    break
                default: {
                    diffMap.current.set(item.id, null)
                }
            }
        })
    }

    useEffect(() => {
        const getResponse = () => {
            // Список ID групп, по которым имеются изменения
            const diff = Array.from(diffMap.current)
                .filter((item) => item[1] !== null)

            if (diff.length !== 0) {
                let countDown = diff.length
                updatedGroupList?.forEach(async (item) => {
                    if (diffMap.current.get(item.id) !== null) {
                        const response = await editGroup(item.id, diffMap.current.get(item.id)!, [msisdn!])
                        switch (response.status) {
                            case "ok":
                                if (--countDown === 0) {
                                    handleClose(updatedGroupList?.filter((item) => item.checked === true))
                                }
                                return
                            case "error":
                                console.log(response)
                                setMessage("Сервер не смог обработать запрос. Попробуйте выполнить сохранение в группу позднее.")
                                return false
                        }
                    }
                })
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    const onGroupSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetGroup = updatedGroupList?.find((item) => item.id.toString() === event.currentTarget.value) as SimCardGroupWithCheckedItems
        targetGroup.checked = !targetGroup.checked
        buildDiffMap()
        setButtonStatus()
        refresh(Math.random())
    }

    /**
     * Дизейблим кнопку сохранения при отсутствии изменений
     */
    const setButtonStatus = () => {
        const diff = Array.from(diffMap.current.values()).filter((item) => item !== null).length
        setDisabled(diff === 0)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={handleClose}
        >
            <>
                <div className='modal-header py-4'>
                    <h3>Вхождение номера в группы</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose()}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body  pt-5 px-10 center'>
                    {updatedGroupList && updatedGroupList.length !== 0 &&
                    <>
                        <div className="d-flex flex-column mb-5">
                            {updatedGroupList?.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <input
                                            type='checkbox'
                                            className='btn-check'
                                            name='activityStatus'
                                            id={'_' + index}
                                            value={item.id}
                                            onFocus={(event) => event.stopPropagation()}
                                            onChange={onGroupSelect}
                                            checked={updatedGroupList && updatedGroupList[index].checked}
                                        />
                                        <label
                                            className={`btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary  border border-active px-4 m-2
                                            ${
                                                updatedGroupList[index].checked ? 'active' : ''
                                            }
                                            `}
                                            htmlFor={'_' + index}
                                        >
                                            <span className='text-gray-800 fw-bold'>{item.name}</span>
                                        </label>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary" onClick={() => setIsSubmitted(true)} disabled={disabled}>Сохранить</button>
                        </div>
                    </>
                    }

                    {updatedGroupList && updatedGroupList.length === 0 &&
                        <Alert
                            type={AlertType.INFO}
                            additionalClassNames={"p-5 mb-0"}
                            message={"Вы пока не создали ни одной группы. Это можно сделать в разделе <a href='/simcard/groups'>Группы SIM-карт</a>"}
                        />
                    }

                    <div>
                        {message && <Alert message={message}/>}
                    </div>
                </div>
            </>
        </Modal>
    )
}
