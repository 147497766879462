import React, {ChangeEvent, useState} from "react";
import {SimCard} from "../../models/Models";
import {IconButton, Tooltip, Zoom} from "@mui/material";
import {KTSVG} from "../../../../../_metronic/helpers";
import ClearIcon from "@mui/icons-material/Clear";

export interface IMsisdnListFileProps {
    onFileSelect: (msisdnList: string[]) => void
}

export type FilterByUploadedItemsProps = {
    uploadedItems: string[]
    currentRows?: SimCard[] | undefined
    setRows: (rows: any[]) => void
}

/**
 * Фильтрация реестра согласно загруженным номерам телефонов или сим-карт
 */
export const filterByUploadedItems = ({uploadedItems, currentRows, setRows}: FilterByUploadedItemsProps) => {
    if (currentRows !== undefined) {
        if (uploadedItems.length !== 0) {
            // Если количество символов равно 10, то это список телефонов
            if (uploadedItems[0].length === 10) {
                setRows(currentRows.filter((item) => uploadedItems.some(msisdn => msisdn === item.msisdn.toString())))
                return
                // иначе загружен список сим-карт
            } else {
                setRows(currentRows.filter((item) => uploadedItems.some(iccid => iccid === item.iccid)))
                return
            }
        }
        setRows(currentRows)
    }
}

/**
 * Компонент для загрузки файла с номерами телефонов или сим-карт, их извлечения и передачи в родительский компонент
 *
 * @param onFileSelect
 * @constructor
 */
export default function ItemListFile({onFileSelect}: IMsisdnListFileProps) {
    const [file, setFile] = useState<File | undefined>()

    /**
     * Извлекает из файла номера телефонов или сим-карт и передаёт их в родительский компонент
     *
     * @param file
     */
    const processFile = (file: File | undefined = undefined) => {
        if (!file) {
            onFileSelect([])
            return
        }

        // Обрабатываем файлы только с указанными расширениями
        const allowedExtensionList = ['txt', 'csv', 'xls', 'xlsx', 'ods']

        // Извлекаем расширение из названия файла
        const extension = file.name.split('.').pop()?.toLowerCase();

        if (extension && allowedExtensionList.indexOf(extension) === -1) {
            onFileSelect([])
            return
        }

        const fileReader = new FileReader()
        // Простой текстовый файл
        if (file.type === 'text/plain') {
            fileReader.readAsText(file)
            fileReader.onload = () => {
                const fileContent = fileReader.result
                if (typeof fileContent === 'string') {
                    extractItems(fileContent)
                }
            }
            // Файл Excel
        } else {
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = async () => {
                const XLSX = await import ("../../../../components/xlsx/xlsxWrapper");
                const fileContent = fileReader.result
                const workbook = XLSX.read(fileContent, {type: 'buffer'})
                const worksheet = workbook.Sheets[workbook.SheetNames[0]]
                const csvContent = XLSX.utils.sheet_to_csv(worksheet, {FS: ",", RS: ",", blankrows: false})
                extractItems(csvContent)
            }
        }
    }

    /**
     * Форматирует список телефонов или номеров карт для передачи на фильтрацию по списку
     * @param content
     */
    const extractItems = (content: string) => {
        // Отфильтровываем записи, являющиеся номерами телефонов
        const itemsWithDuplicates = content?.split(new RegExp(/[,;\s]/)).filter((item) => {
            return /\d{10}/.test(item.trim())
        })

        // Избавляемся от дубликатов
        let itemsWithoutDuplicates = new Set(itemsWithDuplicates)

        // Если ничего не нашли, то это может быть список номеров карт
        if (itemsWithoutDuplicates.size === 0) {
            itemsWithoutDuplicates = new Set(content?.split(new RegExp(/[,;\s]/)))
        }

        if (itemsWithoutDuplicates.size === 0) {
            // Если так ничего и не нашли, очищаем список номеров
            onFileSelect([])
        } else {
            onFileSelect(Array.from(itemsWithoutDuplicates).map((item) => item.trim()))
        }
    }

    /**
     * Обработчик загрузки файла
     *
     * @param event
     */
    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files.length !== 0 ? event.target.files[0] : undefined
        setFile(file)
        processFile(file)
    }

    const clearFileInput = () => {
        // Прячем иконку сброса
        setFile(undefined)
        // Возвращаем реестр к прежнему состоянию
        onFileSelect([]);
        // Только так можно очистить input типа "file"
        (document.getElementById('fileUploadInput') as HTMLInputElement).value = ''
    }

    return (
        <div className={'simCardListPageUploadInputContainer'}>
            <input
                id='fileUploadInput'
                type='file'
                className={'file-form-control form-control simCardListPageUploadInput'}
                onChange={onFileChange}
            />
            {file !== undefined &&
                <IconButton
                    title="Сбросить"
                    aria-label="Сбросить"
                    size="small"
                    style={{marginLeft: '-35px'}}
                    // style={{visibility: searchText ? 'visible' : 'hidden'}}
                    onClick={clearFileInput}
                >
                    <ClearIcon fontSize="small"/>
                </IconButton>
            }
            <Tooltip
                placement={'top'}
                TransitionComponent={Zoom}
                title={(
                    <div className={'tooltipPopup'}>
                        Фильтр по списку либо номеров телефонов, либо номеров сим-карт (ICCID).
                        <ul>
                            <li> В формате Excel - построчно</li>
                            <li> В формате TXT/CSV - подряд с любым разделителем, либо построчно</li>
                        </ul>
                    </div>
                )}
            >
                <span className="fs-6">
                    <KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                </span>
            </Tooltip>
        </div>
    )
}
