import {createTheme} from "@mui/material";
import {BillItemizationConnectionTypeItemLabels, BillItemizationConnectionTypeItemMeasure, BillItemizationItem} from "../../models/BillItemization";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = () => [
    {
        field: 'connection_type',
        headerName: 'Статья расходов',
        width: 250
    },
    {
        field: 'cost',
        headerName: 'Стоимость',
        sortable: false,
        width: 150
    },
    {
        field: 'value',
        headerName: 'Значение',
        width: 150
    },
]

export const getRows = (billItemization: BillItemizationItem[] | undefined): any => billItemization?.map((item, index) =>
    ({
        id: index,
        connection_type: BillItemizationConnectionTypeItemLabels.get(item.connection_type.id),
        value: item.value + ' ' + BillItemizationConnectionTypeItemMeasure.get(item.connection_type.id),
        cost: item.cost + ' руб./' + BillItemizationConnectionTypeItemMeasure.get(item.connection_type.id),
        dateRangePicker: ''
    })
)
