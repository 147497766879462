import {createDeviceType, editDeviceType} from "../../api";
import {Modal} from "react-bootstrap-v5";
import React, {useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {ExtendedModalProps} from "../../models/Modal";
import {DeviceType} from "../../models/Models";
import clsx from "clsx";
import * as Yup from "yup";
import {useFormik} from "formik";
import Alert from "../../../../components/alerts/Alert";

interface IDeviceTypeForm extends ExtendedModalProps {
    deviceType?: DeviceType | undefined
}

const schema = Yup.object().shape({
    name: Yup.string().required('Обязательное поле'),
})

const initialValues = {
    name: '',
    device_brand: '',
    device_model: '',
    generation: [],
    descr: ''
} as Partial<DeviceType>

/**
 * Форма создания типа устройств
 */
export const DeviceTypeForm = ({deviceType, show, handleClose}: IDeviceTypeForm) => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setLoading(false)
        formik.resetForm()
    }

    const formik = useFormik({
        initialValues: deviceType || initialValues,
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            const response = (deviceType)
                ? await editDeviceType({...values, id: deviceType.id})
                : await createDeviceType(values)
            switch (response.status) {
                case "ok":
                    setLoading(false)
                    handleClose({isSubmitted: true})
                    return true
                default:
                    setLoading(false)
                    setSubmitting(false)
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте выполнить операцию позднее.")
                    return false
            }
        },
    })

    /**
     * Возвращает состояние чекбокса (отмечен/не отмечен)
     *
     * @param checkboxValue
     */
    const getCheckedState = (checkboxValue: string): boolean => formik.values.generation!.includes(checkboxValue)

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted: false})
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-header py-4'>
                <h3>{deviceType ? 'Изменение' : 'Создание'} типа устройств</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-5 center'>

                {message !== "" && <Alert additionalClassNames='p-5 mb-5 mt-0' message={message}/>}

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Наименование типа устройства</label>
                        <input
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('name')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.name && formik.errors.name
                                },
                                {
                                    'is-valid': formik.touched.name && !formik.errors.name,
                                }
                            )}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block error-color'>
                                    <span role='alert'>{formik.errors.name}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Марка</label>
                        <input
                            {...formik.getFieldProps('device_brand')}
                            className='form-control form-control-lg form-control-solid'
                        />
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Модель</label>
                        <input
                            {...formik.getFieldProps('device_model')}
                            className='form-control form-control-lg form-control-solid'
                        />
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Поддерживаемые поколения связи</label>
                        {[2, 3, 4, 5].map((item, index) => {
                            return (
                                <div key={index} className="form-check form-check-custom form-check-solid pt-1">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="generation"
                                        value={`${item}G`}
                                        checked={getCheckedState(`${item}G`)}
                                        onChange={formik.handleChange}
                                        id={`checkbox${item}G`}/>
                                    <label className="form-check-label" htmlFor={`checkbox${item}G`}>
                                        {`${item}G`}
                                    </label>
                                </div>
                            )
                        })
                        }
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Описание</label>
                        <textarea
                            {...formik.getFieldProps('descr')}
                            className='form-control form-control-lg form-control-solid'
                        />
                    </div>

                    <div className='text-center d-flex justify-content-end'>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                        >
                            {!loading && <span className='indicator-label'>Сохранить</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>Обрабатываем...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                        </span>
                            )}
                        </button>

                    </div>
                </form>
            </div>
        </Modal>
    )
}
