import axios from 'axios'
import {API_URL} from "../../../../config";
import {processResponse} from "../../../api/CommonApi";
import {Feedback} from "../../simcard/models/Models";

export const FEEDBACK = `${API_URL}/m2m/api/user/feedback.cgi`

/**
 * Обратная связь
 */
export function sendFeedback({name, phone, email, text,}: Feedback) {
    const response = axios.post(FEEDBACK, {name, phone, email, text})
    return processResponse(response)
}
