import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'

import 'bootstrap/dist/js/bootstrap.esm'

// Apps
import {App} from './app/App'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {Spinner} from "react-bootstrap-v5";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

ReactDOM.render(
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div><Spinner animation="grow"/></div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>,
  document.getElementById('root')
)
