import {CommonErrorTypes} from "../../../api/CommonApi";

/**
 * Лимитные правила
 */
export interface SimCardLimit {
    /** Идентификатор лимита */
    id: number
    /** Значение границы лимита */
    quote: number
    /** Период действия правила */
    limitPeriod: LimitPeriod
    /** Интервальный период действия (в днях) */
    intervalDuration: number
    /** Признак единиц измерения */
    limitMeasure: LimitMeasure
    /** Тип действия по лимиту */
    limitAction: LimitAction
}

export interface SimCardLimitFromApi {
    id: number
    quote: number
    period_type: LimitPeriod
    limit_measure: LimitMeasure
    action_type: LimitAction
    interval_duration: number
}

export enum LimitPeriod {
    DAY = 'day',
    MONTH = 'month',
    INTERVAL = 'interval'
}

export namespace LimitPeriod {
    export const ONE_MONTH = 1
    export const MAX_VALUE_IN_DAYS = 182

    const labels: Map<LimitPeriod, string> = new Map([
        [LimitPeriod.DAY, 'Сутки'],
        [LimitPeriod.MONTH, 'Ежемесячно'],
        [LimitPeriod.INTERVAL, 'Интервал в днях']
    ])

    export function getLabel(limitPeriod: LimitPeriod) {
        return labels.get(limitPeriod) ?? ''
    }

    // Учёт падежей и множественного числа кол-ва дней
    export function getLabelInDays(days: number | string): string {
        switch (Number(days)) {
            case 1:
            case 21: return days + " день"
            case 2:
            case 3:
            case 4:
            case 22:
            case 23:
            case 24: return days + " дня"
            default: return days + " дней"
        }
    }
}

export enum LimitMeasure {
    MONEY = 'money',
    TRAFFIC = 'traf',
    TRAFFICMB = 'trafmb',
    TRAFFICGB = 'trafgb'
}

export namespace LimitMeasure {
    export const labels: Map<LimitMeasure, string> = new Map([
        [LimitMeasure.MONEY, 'Руб'],
        [LimitMeasure.TRAFFIC, 'Кб'],
        [LimitMeasure.TRAFFICMB, 'Мб'],
        [LimitMeasure.TRAFFICGB, 'Гб']
    ])

    export function getLabel(limitMeasure: LimitMeasure) {
        return labels.get(limitMeasure) ?? ''
    }
}

export enum LimitAction {
    INFO = 'info',
    BLOCK = 'block'
}

export namespace LimitAction {
    export const labels: Map<LimitAction, string> = new Map([
        [LimitAction.INFO, 'Инфо'],
        [LimitAction.BLOCK, 'Инфо с блокировкой']
    ])

    export function getLabel(limitAction: LimitAction) {
        return labels.get(limitAction) ?? ''
    }
}

export interface SimCardLimitRequestProps extends SimCardLimit {
    msisdn: number | string | undefined
}

export type SimCardLimitResponseError = {
    error: 'already_exists' | CommonErrorTypes
}

export namespace SimCardLimit {
    /**
     * Преобразование значения лимита в выбранных единицах в байты перед отправкой на бекенд
     * @param limitMeasure
     * @param limitValue
     */
    export function convertLimitValueToApi(limitMeasure: LimitMeasure, limitValue: number) {
        switch (limitMeasure) {
            case LimitMeasure.TRAFFIC:
                return limitValue * 1024
            case LimitMeasure.TRAFFICMB:
                return limitValue * 1024 * 1024
            case LimitMeasure.TRAFFICGB:
                return limitValue * 1024 * 1024 * 1024
            default:
                return 0
        }
    }

    /**
     * Преобразование значения лимита из байтов в выбранные единицы для отображения на фронтенде
     * @param limitValue
     */
    export function getLimitValueFromApi(limitValue: number): [number, LimitMeasure] {
        const KB = 1024
        const MB = 1048576
        const GB = 1073741824

        if (limitValue >= KB && limitValue < MB) {
            return [limitValue / KB, LimitMeasure.TRAFFIC]
        }

        if (limitValue >= MB && limitValue < GB) {
            return [limitValue / MB, LimitMeasure.TRAFFICMB]
        }

        return [limitValue / GB, LimitMeasure.TRAFFICGB]
    }
}
