/* eslint-disable jsx-a11y/anchor-is-valid */
import {Redirect, Route, Switch} from "react-router-dom";
import {InternalEventListWrapper} from "./internalEvent/InternalEventListWrapper";
import {EventSubscriptionListWrapper} from "./subscription/EventSubscriptionListWrapper";

export function SectionWrapper() {
    return (
        <Switch>
            <Route path='/event/list'>
                <InternalEventListWrapper/>
            </Route>
            <Route path='/event/subscriptions'>
                <EventSubscriptionListWrapper/>
            </Route>
            <Redirect from='/event' to='/event/list'/>
            <Redirect from='/event/' to='/event/list'/>
            <Redirect to='/event/list'/>
        </Switch>
    )
}
