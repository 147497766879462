/* eslint-disable jsx-a11y/anchor-is-valid */
import {fetchSimCardInfo} from "../../api";
import React, {useEffect, useRef, useState} from 'react'
import {SectionHeader} from "../../SectionHeader";
import {SimCard} from "../../models/Models";
import {PageTitle} from "../../../../../_metronic/layout/core";
import AlertDismissible from "../../../../components/alerts/AlertDismissible";
import {useParams} from "react-router-dom";
import {SimCardHeader} from "../../list/SimCardHeader";
import {formatPhoneNumberFrom10Digits} from "../../../../helpers/Formatter";
import {GeoComment} from "./comment/GeoComment";
import {GeoPosition} from "./position/GeoPosition";
import {InnerButtons} from "../overview/InnerButtons";

/**
 * Геолокация устройства
 */
export function GeoPage() {
    const params: { msisdn: string | undefined } = useParams();
    const [refresher, refreshInfo] = useState(Date.now())
    const [message, setMessage] = useState('')
    const [cardInfo, setCardInfo] = useState<SimCard | undefined>(undefined)
    const msisdn = useRef(formatPhoneNumberFrom10Digits(params.msisdn))

    useEffect(() => {
        const getSimCardInfo = async () => {
            const response = await fetchSimCardInfo({msisdn: params.msisdn})
            switch (response.status) {
                case "ok":
                    const simCardList = response.data.data.items as SimCard[]
                    setCardInfo(simCardList[0])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }
        getSimCardInfo()
    }, [refresher])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <PageTitle breadcrumbs={[]}>Геолокация устройства с номером {params.msisdn}</PageTitle>

            <div className="card">
                <SectionHeader/>

                <div className="card-body d-flex align-items-center justify-content-between pt-5 pb-0">
                    <h1 style={{lineHeight: '1.6'}} className="text-gray-600">{msisdn.current}</h1>
                    <InnerButtons
                        msisdn={params.msisdn}
                        cardInfo={cardInfo}
                        onFinish={() => refreshInfo(Date.now())}
                    />
                </div>

                <SimCardHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body pt-0">
                    <div className="row">
                        <div className="col-lg-4">
                            <GeoPosition msisdn={params.msisdn} geoPosition={cardInfo?.device_address}/>
                            <GeoComment msisdn={params.msisdn} comment={cardInfo?.device_address_comment}/>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
