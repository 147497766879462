import {Modal} from "react-bootstrap-v5";
import {IContactListProps} from "./ContactList";
import {Action, ActionOrNull, SimCardContact} from "../../../models/Models";
import React, {useEffect, useRef, useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {editContactAssignments, listContact} from "../../../api";
import Alert from "../../../../../components/alerts/Alert";
import {AlertType} from "../../../../../components/alerts";

export interface SimCardContactWithCheckedItems extends SimCardContact {
    checked?: boolean
}

interface IContactListFormProps extends IContactListProps {
    show: boolean
    handleClose: (updatedContactList?: SimCardContactWithCheckedItems[]) => void
}

/**
 * Форма редактирования привязки контактов к номеру
 */
export const ContactListForm = ({msisdn, contactList, show, handleClose}: IContactListFormProps) => {
    const [, refresh] = useState(0)
    const [message, setMessage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const diffMap = useRef<Map<number, ActionOrNull>>(new Map())
    const initialContactList = useRef<SimCardContactWithCheckedItems[]>([])
    const [updatedContactList, setUpdatedContactList] = useState<SimCardContactWithCheckedItems[] | undefined>()

    /**
     * 1. Добавление в контакты свойства checked согласно привязке номера к контактам при первоначальной загрузке
     * 2. Заполнение initialContactList и diffMap
     */
    useEffect(() => {
            const getCheckedState = (result: SimCardContact[], updatedItem: SimCardContactWithCheckedItems): boolean => {
                if (contactList && contactList.length !== 0) {
                    return contactList.filter((initialItem, index) => initialItem.id === updatedItem.id).length !== 0
                }
                return false
            }

            const getResponse = async () => {
                const response = await listContact()
                switch (response.status) {
                    case "ok":
                        const contactList: SimCardContact[] = response.data.data.items
                        if (contactList) {
                            if (contactList.length !== 0) {
                                setUpdatedContactList(
                                    contactList.map((item, index) => {
                                        diffMap.current.set(item.id, null)
                                        initialContactList.current[index] = {
                                            ...item,
                                            checked: getCheckedState(contactList, item)
                                        }
                                        let clone: SimCardContactWithCheckedItems = {id: 0, name: '', email: ''}
                                        Object.assign(clone, initialContactList.current[index])
                                        return clone
                                    })
                                )
                            } else {
                                // Для вывода сообщения об отсутствии контактов лишь после того, как отработает запрос
                                setUpdatedContactList([])
                            }
                        }
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер сообщил об ошибке. Попробуйте выполнить операцию позднее.")
                        return false
                }
            }

            getResponse()
        },
        [contactList]
    )

    const buildDiffMap = () => {
        updatedContactList?.forEach((item, index) => {
            const diff = +item.checked! - +initialContactList.current[index].checked!
            switch (diff) {
                case 1:
                    diffMap.current.set(item.id, Action.ADD)
                    break
                case -1:
                    diffMap.current.set(item.id, Action.DELETE)
                    break
                default: {
                    diffMap.current.set(item.id, null)
                }
            }
        })
    }

    useEffect(() => {
        const getResponse = () => {
            // Список ID групп, по которым имеются изменения
            const diff = Array.from(diffMap.current)
                .filter((item) => item[1] !== null)

            if (diff.length !== 0) {
                let countDown = diff.length
                updatedContactList?.forEach(async (item) => {
                    if (diffMap.current.get(item.id) !== null) {
                        const response = await editContactAssignments(item.id, diffMap.current.get(item.id)!, [msisdn!])
                        switch (response.status) {
                            case "ok":
                                if (--countDown === 0) {
                                    handleClose(updatedContactList?.filter((item) => item.checked === true))
                                }
                                return
                            case "error":
                                console.log(response)
                                setMessage("Сервер не смог обработать запрос. Попробуйте выполнить операцию позднее.")
                                return false
                        }
                    }
                })
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])   // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    const onGroupSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetContact = updatedContactList?.find((item) => item.id.toString() === event.currentTarget.value) as SimCardContactWithCheckedItems
        targetContact.checked = !targetContact.checked
        buildDiffMap()
        setButtonStatus()
        refresh(Math.random())
    }

    /**
     * Дизейблим кнопку сохранения при отсутствии изменений
     */
    const setButtonStatus = () => {
        const diff = Array.from(diffMap.current.values()).filter((item) => item !== null).length
        setDisabled(diff === 0)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={handleClose}
        >
            <>
                <div className='modal-header py-4'>
                    <h3>Привязки номера к контактам</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose()}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body  pt-5 px-10 center'>
                    {updatedContactList && updatedContactList.length !== 0 &&
                        <>
                            <div className="d-flex flex-column mb-5">
                                {updatedContactList?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <input
                                                type='checkbox'
                                                className='btn-check'
                                                name='activityStatus'
                                                id={'_' + index}
                                                value={item.id}
                                                onFocus={(event) => event.stopPropagation()}
                                                onChange={onGroupSelect}
                                                checked={updatedContactList && updatedContactList[index].checked}
                                            />
                                            <label
                                                className={`btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary  border border-active px-4 m-2
                                            ${
                                                    updatedContactList[index].checked ? 'active' : ''
                                                }
                                            `}
                                                htmlFor={'_' + index}
                                            >
                                                <span className='text-gray-800 fw-bold'>{item.name}</span>
                                            </label>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" onClick={() => setIsSubmitted(true)} disabled={disabled}>Сохранить</button>
                            </div>
                        </>
                    }

                    {updatedContactList && updatedContactList.length === 0 &&
                        <Alert
                            additionalClassNames={"p-5 mb-0"}
                            type={AlertType.INFO}
                            message={`Вы пока не создали ни одного контакта. Это можно сделать в разделе <a href="/simcard/contacts">Контакты</a>.`}
                        />
                    }

                    <div>
                        {message && <Alert message={message}/>}
                    </div>
                </div>
            </>
        </Modal>
    )
}
