import {useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {IHandleCloseProps} from "../../models/Modal";
import {DeleteDeviceTypeForm} from "./DeleteDeviceTypeForm";

export interface IDeleteDeviceTypeProps {
    deviceTypeIdList: number[]
    onFinish: () => void
}

/**
 * Удаление типов устройств
 */
export function DeleteDeviceType({deviceTypeIdList = [], onFinish}: IDeleteDeviceTypeProps) {
    const [show, setShow] = useState(false)

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        isSubmitted && onFinish()
    }

    return (
        <>
            <div className="px-5 mt-6">
                <div>
                <span className="d-flex align-items-center text-gray-600 fs-6" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                    <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                    <span>Удалить типы устройств</span>
                </span>
                </div>
                <DeleteDeviceTypeForm deviceTypeIdList={deviceTypeIdList} show={show} handleClose={closeModal}/>
            </div>
        </>
    )
}
