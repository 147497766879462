import clsx from "clsx";
import * as Yup from "yup";
import {useFormik} from "formik";
import React, {useState} from "react";
import {Modal} from "react-bootstrap-v5";
import InputMask from "react-input-mask";
import {KTSVG} from "../../../_metronic/helpers";
import Alert from "../../components/alerts/Alert";
import {sendFeedback} from "./api";
import {ModalProps} from "../simcard/models/Modal";
import {Feedback} from "../simcard/models/Models";
import {AlertType} from "../../components/alerts";

/**
 * Здесь проверка взаимоисключающих полей `email` и `phone` такова, что если одно поле пустое, то второе должно быть обязательным
 */
const schema = Yup.object().shape({
    name: Yup.string()
        .required("Обязательное поле"),
    phone: Yup.string()
        .when(['email'], {
            is: (email: string) => !email,
            then: Yup.string().required("Обязательное поле")
        })
        .matches(/^(?:.*?\d){11}$/, {
            message: 'Введите номер телефона полностью'
        }),
    email: Yup.string()
        .when(['phone'], {
            is: (email: string) => !email,
            then: Yup.string().required("Обязательное поле")
        })
        .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, {
            message: 'Введите корректный E-mail.'
        }),
    text: Yup.string()
        .required("Обязательное поле")
}, [['email', 'phone']])

/**
 * Возможные способы связи
 */
enum FeedbackContactSelector {
    EMAIL = 'email',
    PHONE = 'phone'
}

interface IFeedbackFormFields extends Feedback {
    // Селектор способа связи
    feedbackContactSelector: FeedbackContactSelector
}

const initialValues: IFeedbackFormFields = {
    name: '',
    phone: '',
    email: '',
    text: '',
    feedbackContactSelector: FeedbackContactSelector.EMAIL
}

/**
 * Форма для отправки обратной связи
 */
export const SendFeedbackForm = ({show, handleClose}: ModalProps) => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                setLoading(true)
                const response = await sendFeedback({
                    name: values.name,
                    phone: values.phone || undefined,
                    email: values.email || undefined,
                    text: values.text
                })
                switch (response.status) {
                    case "ok":
                        setLoading(false)
                        setSuccessMessage("Ваше сообщение успешно отправлено.")
                        return true
                    default:
                        setLoading(false)
                        console.log(response)
                        setErrorMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                        return false;
                }
            } catch (error) {
                setLoading(false)
                console.log(error)
                setErrorMessage("Что-то пошло не так. Попробуйте зайти на страницу позднее.")
                return false;
            }
        },
    })

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setErrorMessage('')
        setSuccessMessage('')
        setLoading(false)
    }

    /**
     * При выборе одного из полей очищаем второе для корректного выполнения правил валидации
     * @param form
     */
    const cleanOppositeValue = (form: React.FormEvent<HTMLFormElement>) => {
        const phoneSelected = formik.values.feedbackContactSelector === FeedbackContactSelector.EMAIL && (form.currentTarget.elements as any).feedbackPhoneSelector.checked
        const emailSelected = formik.values.feedbackContactSelector === FeedbackContactSelector.PHONE && (form.currentTarget.elements as any).feedbackEmailSelector.checked
        if (phoneSelected || emailSelected) {
            formik.setFieldValue('phone', '')
            formik.setFieldValue('email', '')
        }
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-400px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-content'>
                <div className='modal-header py-4'>
                    <h3>Обратная связь</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body pt-5 px-10 center'>

                    <form
                        className='form'
                        onSubmit={formik.handleSubmit}
                        onChange={cleanOppositeValue}
                        noValidate
                    >

                        <div className='fv-row mb-5'>
                            <label className='form-label fs-6 fw-bolder text-dark px-1'>Ваше имя</label>
                            <input
                                type='text'
                                autoComplete='off'
                                {...formik.getFieldProps('name')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.name && formik.errors.name
                                    },
                                    {
                                        'is-valid': formik.touched.name && !formik.errors.name,
                                    }
                                )}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block error-color'>
                                        <span role='alert'>{formik.errors.name}</span>
                                    </div>
                                </div>
                            )}
                        </div>


                        <div className='fv-row mb-5'>
                            <label className='form-label fs-6 fw-bolder text-dark px-1'>Способ связи: </label>
                            <input
                                type='radio'
                                id='feedbackEmailSelector'
                                className='btn-check'
                                {...formik.getFieldProps('feedbackContactSelector')}
                                value={FeedbackContactSelector.EMAIL}
                            />
                            <label
                                className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                    formik.values.feedbackContactSelector === FeedbackContactSelector.EMAIL ? 'active' : ''
                                }`}
                                htmlFor='feedbackEmailSelector'
                            >
                                <span className='text-gray-800 fw-bold'>E-mail</span>
                            </label>

                            <input
                                type='radio'
                                id='feedbackPhoneSelector'
                                className='btn-check'
                                {...formik.getFieldProps('feedbackContactSelector')}
                                value={FeedbackContactSelector.PHONE}
                            />
                            <label
                                className={`btn btn-sm btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                    formik.values.feedbackContactSelector === FeedbackContactSelector.PHONE ? 'active' : ''
                                }`}
                                htmlFor='feedbackPhoneSelector'
                            >
                                <span className='text-gray-800 fw-bold'>Телефон</span>
                            </label>
                        </div>

                        {
                            formik.values.feedbackContactSelector === FeedbackContactSelector.EMAIL
                                ?
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1'>E-mail</label>
                                    <input
                                        type='email'
                                        autoComplete='off'
                                        {...formik.getFieldProps('email')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.email && formik.errors.email
                                            },
                                            {
                                                'is-valid': formik.touched.email && !formik.errors.email,
                                            }
                                        )}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block error-color'>
                                                <span role='alert'>{formik.errors.email}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1'>Телефон</label>
                                    <InputMask
                                        type='tel'
                                        mask={"+7 (999) 999-99-99"}
                                        autoComplete='off'
                                        {...formik.getFieldProps('phone')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.phone && formik.errors.phone
                                            },
                                            {
                                                'is-valid': formik.touched.phone && !formik.errors.phone,
                                            }
                                        )}
                                    />
                                    {formik.touched.phone && formik.errors.phone && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block error-color'>
                                                <span role='alert'>{formik.errors.phone}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                        }

                        <div className='fv-row mb-5'>
                            <label className='form-label fs-6 fw-bolder text-dark px-1'>Сообщение</label>
                            <textarea
                                {...formik.getFieldProps('text')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.text && formik.errors.text
                                    },
                                    {
                                        'is-valid': formik.touched.text && !formik.errors.text,
                                    }
                                )}
                            />
                            {formik.touched.text && formik.errors.text && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block error-color'>
                                        <span role='alert'>{formik.errors.text}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='d-flex justify-content-end'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                style={{width: '106px'}}
                                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                            >
                                {!loading && <span className='indicator-label'>Отправить</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                                    </span>
                                )}
                            </button>

                        </div>

                    </form>

                    <div>
                        {errorMessage && <Alert message={errorMessage}/>}
                        {successMessage && <Alert type={AlertType.SUCCESS} message={successMessage}/>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
