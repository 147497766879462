import {Modal} from "react-bootstrap-v5";
import {useEffect, useState} from "react";
import {ModalProps} from "../../../models/Modal";
import {KTSVG} from "../../../../../../_metronic/helpers";
import Alert from "../../../../../components/alerts/Alert";
import {deactivateServices} from "../../../api";

interface IDeactivateServicesFormProps extends ModalProps {
    msisdn: number
    serviceIdList: number[]
}

/**
 * Форма деактивации услуг
 */
export const DeactivateServicesForm = ({msisdn, serviceIdList, show, handleClose}: IDeactivateServicesFormProps) => {
    const [message, setMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(() => {
        const getResponse = async () => {
            if (serviceIdList && serviceIdList.length !== 0) {
                const response = await deactivateServices(msisdn, serviceIdList.map(id => Number(id)))
                switch (response.status) {
                    case "ok":
                        handleClose()
                        return true;
                    case "error":
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте выполнить операцию позднее.")
                        return false;
                }
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-content'>
                <div className='modal-header py-4'>
                    <h3>Деактивация услуг</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body pt-5 px-10 center'>
                    <div className="d-flex justify-content-between mb-5">
                        Подтвердите деактивацию
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary" onClick={() => setIsSubmitted(true)}>Подтвердить</button>
                    </div>
                    <div>
                        {message && <Alert message={message}/>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
