import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {SectionWrapper as SimcardSection} from "../modules/simcard/SectionWrapper";
import {SectionWrapper as EventSection} from "../modules/event/SectionWrapper";
import {SectionWrapper as ReportSection} from "../modules/report/SectionWrapper";

export function PrivateRoutes() {
  const ProfileSection = lazy(() => import('../modules/profile/settings/ProfileSettingsWrapper'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/simcard' component={SimcardSection} />
        <Route path='/profile' component={ProfileSection} />
        <Route path='/event' component={EventSection} />
        <Route path='/report' component={ReportSection} />
        <Redirect from='/auth' to='/simcard' />
        <Redirect from='/simcard' to='/simcard/list' />
        <Redirect from='/simcard/' to='/simcard/list' />
        <Redirect exact from='/' to='/simcard/list' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
