import {useEffect, useState} from "react";
import {ContactForm} from "../create/ContactForm";
import {SimCardContact} from "../../models/Models";
import {IHandleCloseProps} from "../../models/Modal";

export interface IEditContactProps {
    showEditForm: boolean
    onFinish: () => void
    onClose: () => void
    contact: SimCardContact | undefined
}

/**
 * Редактирование контакта
 */
export function EditContact({contact, showEditForm, onClose, onFinish}: IEditContactProps) {
    const [show, setShow] = useState(showEditForm)

    useEffect(() => {
        setShow(showEditForm)
    }, [showEditForm])

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        onClose()
        isSubmitted && onFinish()
    }

    return  <ContactForm contact={contact} show={show} handleClose={closeModal}/>
}
