/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from "../../../_metronic/helpers";
import {SendFeedbackForm} from "./SendFeedbackForm";

export function SendFeedback() {
    const [show, setShow] = useState(false)

    return (
        <>
            <div className='cursor-pointer mt-1' onClick={() => setShow(true)}>
                <KTSVG
                    path='/media/icons/duotune/communication/com002.svg'
                    className='svg-icon-2tx svg-icon-white me-4'
                />
            </div>
            {show &&
            <SendFeedbackForm show={show} handleClose={() => setShow(false)}/>
            }
        </>
    )
}
