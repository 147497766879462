import {GridApiRef, GridColumnVisibilityModel} from "@mui/x-data-grid";
import {SimCardGroup} from "../../modules/simcard/models/Models";
import {GridSimCard} from "../../modules/simcard/list/SimCardListDataGridConfig";
import {dateTimeFormatterWithFilter, formatListPlainText} from "../../helpers/Formatter";

type DownloadExcelFileProps = {
    apiRef: GridApiRef
    simCardList?: Partial<GridSimCard>[] | undefined
}

/**
 * Компонент скачивания списка на главной в формате Excel
 *
 * @param apiRef
 * @param simCardList
 */
export const downloadExcelFile = async ({apiRef, simCardList}: DownloadExcelFileProps) => {

    // Формируем список строк, отфильтрованных с помощью фильтров MUI, которые другим способом получить невозможно
    let filteredDataRows = simCardList;
    const filteredRecords = apiRef.current?.state?.filter?.filteredRowsLookup
    // Список видимости столбцов
    const columnVisibility = apiRef.current.state.columns.columnVisibilityModel

    /**
     * Формирует одну строку отчёта с учётом видимости столбцов
     * @param item
     * @param columnVisibility
     */
    const excelRow = (item: Partial<GridSimCard>, columnVisibility: GridColumnVisibilityModel) => {
        let filteredRow = {}
        for (const columnName in columnVisibility) {
            if (columnVisibility[columnName]) {
                switch (columnName) {
                    case 'msisdn':
                        filteredRow[columnName] = item.msisdn?.toString()
                        break
                    case 'balance':
                        filteredRow[columnName] = item.balance
                        break
                    case 'moboperator':
                        filteredRow[columnName] = item.moboperator
                        break
                    case 'status':
                        filteredRow[columnName] = item.status === 'active' ? 'Активен' : 'Блокирован'
                        break
                    case 'tarif':
                        filteredRow[columnName] = item.tarif
                        break
                    case 'device_name':
                        filteredRow[columnName] = item.device_name
                        break
                    case 'traffic':
                        filteredRow[columnName] = item.traffic
                        break
                    case 'trafficKb':
                        filteredRow[columnName] = item.trafficKb
                        break
                    case 'trafficMb':
                        filteredRow[columnName] = item.trafficMb
                        break
                    case 'trafficGb':
                        filteredRow[columnName] = item.trafficGb
                        break
                    case 'device_address':
                        filteredRow[columnName] = item.device_address
                        break
                    case 'imsi':
                        filteredRow[columnName] = item.imsi
                        break
                    case 'imei':
                        filteredRow[columnName] = item.imei?.toString()
                        break
                    case 'iccid':
                        filteredRow[columnName] = item.iccid
                        break
                    case 'apn':
                        filteredRow[columnName] = item.apn
                        break
                    case 'ip':
                        filteredRow[columnName] = item.ip
                        break
                    case 'activation_date':
                        filteredRow[columnName] = item.activation_date ? new Date(item.activation_date as string).toLocaleDateString() : ''
                        break
                    case 'last_activity_ts':
                        filteredRow[columnName] = dateTimeFormatterWithFilter(item.last_activity_ts)
                        break
                    case 'groups':
                        filteredRow[columnName] = formatListPlainText(item.groups as SimCardGroup[], 'name')
                        break
                    case 'description':
                        filteredRow[columnName] = item.description
                        break
                }
            }
        }
        return filteredRow
    }

    /**
     * Заголовки видимых столбцов
     * @param columnVisibility
     */
    const excelHeader = (columnVisibility: GridColumnVisibilityModel) => {
        let filteredHeader: string[] = []
        for (const columnName in columnVisibility) {
            if (columnVisibility[columnName]) {
                switch (columnName) {
                    case 'msisdn':
                        filteredHeader.push('MSISDN')
                        break
                    case 'balance':
                        filteredHeader.push('Баланс')
                        break
                    case 'moboperator':
                        filteredHeader.push('Оператор')
                        break
                    case 'status':
                        filteredHeader.push('Статус')
                        break
                    case 'tarif':
                        filteredHeader.push('Тарифный план')
                        break
                    case 'device_name':
                        filteredHeader.push('Устройство')
                        break
                    case 'traffic':
                        filteredHeader.push('Трафик (байт)')
                        break
                    case 'trafficKb':
                        filteredHeader.push('Трафик (Кб)')
                        break
                    case 'trafficMb':
                        filteredHeader.push('Трафик (Мб)')
                        break
                    case 'trafficGb':
                        filteredHeader.push('Трафик (Гб)')
                        break
                    case 'device_address':
                        filteredHeader.push('Адрес устройства')
                        break
                    case 'imsi':
                        filteredHeader.push('IMSI')
                        break
                    case 'imei':
                        filteredHeader.push('IMEI')
                        break
                    case 'iccid':
                        filteredHeader.push('ICCID')
                        break
                    case 'apn':
                        filteredHeader.push('APN')
                        break
                    case 'ip':
                        filteredHeader.push('IP')
                        break
                    case 'activation_date':
                        filteredHeader.push('Дата активации')
                        break
                    case 'last_activity_ts':
                        filteredHeader.push('Последняя активность')
                        break
                    case 'groups':
                        filteredHeader.push('Группы')
                        break
                    case 'description':
                        filteredHeader.push('Комментарий')
                        break
                }
            }
        }
        return filteredHeader
    }

    /**
     * Ширины столбцов
     * @param columnVisibility
     */
    const excelColumnWidth = (columnVisibility: GridColumnVisibilityModel) => {
        let columnWidth: { wch: number }[] = []
        for (const columnName in columnVisibility) {
            if (columnVisibility[columnName]) {
                switch (columnName) {
                    case 'msisdn':
                        columnWidth.push({wch: 10})
                        break
                    case 'balance':
                        columnWidth.push({wch: 10})
                        break
                    case 'moboperator':
                        columnWidth.push({wch: 10})
                        break
                    case 'status':
                        columnWidth.push({wch: 10})
                        break
                    case 'tarif':
                        columnWidth.push({wch: 40})
                        break
                    case 'device_name':
                        columnWidth.push({wch: 15})
                        break
                    case 'traffic':
                        columnWidth.push({wch: 15})
                        break
                    case 'trafficKb':
                        columnWidth.push({wch: 15})
                        break
                    case 'trafficMb':
                        columnWidth.push({wch: 15})
                        break
                    case 'trafficGb':
                        columnWidth.push({wch: 15})
                        break
                    case 'device_address':
                        columnWidth.push({wch: 20})
                        break
                    case 'imsi':
                        columnWidth.push({wch: 22})
                        break
                    case 'imei':
                        columnWidth.push({wch: 22})
                        break
                    case 'iccid':
                        columnWidth.push({wch: 22})
                        break
                    case 'apn':
                        columnWidth.push({wch: 20})
                        break
                    case 'ip':
                        columnWidth.push({wch: 15})
                        break
                    case 'activation_date':
                        columnWidth.push({wch: 14})
                        break
                    case 'last_activity_ts':
                        columnWidth.push({wch: 20})
                        break
                    case 'groups':
                        columnWidth.push({wch: 20})
                        break
                    case 'description':
                        columnWidth.push({wch: 100})
                        break
                }
            }
        }
        return columnWidth
    }

    if (Object.keys(filteredRecords).length !== 0) {
        const filteredIds = Object.entries(filteredRecords).filter((item) => item[1]).map(item => Number(item[0]))
        filteredDataRows = simCardList?.filter((item) => filteredIds.some(msisdn => msisdn === item.msisdn))
    }

    if (filteredDataRows !== undefined) {
        const XLSX = await import ("./xlsxWrapper");
        const wb = XLSX.utils.book_new();

        // Отсортированный и отформатированный список свойств
        const simCardListFormatted = filteredDataRows.map((item) => (excelRow(item, columnVisibility)))

        const ws = XLSX.utils.json_to_sheet(simCardListFormatted);

        // Заголовки столбцов
        XLSX.utils.sheet_add_aoa(ws, [excelHeader(columnVisibility)], {origin: "A1"});

        // Ширины столбцов
        ws["!cols"] = excelColumnWidth(columnVisibility)

        const now = new Date()
        const dateTime = now.toLocaleDateString() + '_' + now.toLocaleTimeString().replace(/:/g, '.')
        const fileName = 'SimCardList_' + dateTime
        XLSX.utils.book_append_sheet(wb, ws, dateTime);
        XLSX.writeFileXLSX(wb, fileName + '.xlsx');
    }
}
