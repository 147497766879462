import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import {authPersistReducer, authSaga} from "../../app/modules/auth";
import {settingsPersistReducer} from "../../app/modules/settings/redux/SettingsRedux";

export type RootState = ReturnType<typeof rootReducer>

export const rootReducer = combineReducers({
  auth: authPersistReducer,
  settings: settingsPersistReducer
})

export function* rootSaga() {
  yield all([authSaga()])
}
