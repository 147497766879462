/* eslint-disable jsx-a11y/anchor-is-valid */
import {Redirect, Route, Switch} from "react-router-dom";
import {SimCardContactListPage} from "./SimCardContactListPage";

export function SimCardContactListWrapper() {
    return (
        <Switch>
            <Route exact path='/simcard/contacts'>
                <SimCardContactListPage/>
            </Route>
            <Redirect from='/simcard/contacts/' to='/simcard/contacts'/>
        </Switch>
    )
}
