import {FC, useRef, useEffect, useState} from 'react'
import {RootState} from '../../../../setup'
import * as auth from './AuthRedux'
import {IAuthState} from "./AuthRedux";
import {OkResponse} from "../../../api/CommonApi";
import {AuthRequest, AuthResponse} from "../Models";
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {fetchTokenByRefreshToken, getUserByToken} from "../api";
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
    const dispatch = useDispatch()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    const {accessToken, refreshToken: refresh_token} = useSelector<RootState>(({auth}) => auth, shallowEqual) as IAuthState

    // Сперва необходимо авторизоваться под пользователем с помощью токена авторизации прежде чем что-либо рендерить
    useEffect(() => {
        const requestUser = async () => {
            try {
                if (!didRequest.current) {
                    const {data: {user}} = await getUserByToken()

                    dispatch(props.fulfillUser(user))
                }
            } catch (error) {
                // Если по текущему токену авторизация не проходит, запрашиваем новую пару "accessToken, refreshToken" по рефреш-токену
                if (!didRequest.current) {
                    const {
                        data: {
                            access_token: accessToken,
                            refresh_token: refreshToken
                        }
                    } = await fetchTokenByRefreshToken({refresh_token} as Partial<AuthRequest>) as OkResponse<AuthResponse>
                    if (accessToken) {
                        dispatch(props.fetchTokens(accessToken, refreshToken))

                        const {data: {user}} = await getUserByToken()
                        dispatch(props.fulfillUser(user))
                    } else {
                        // Если и рефреш-токен не валиден, разлогиниваемся
                        dispatch(props.logout())
                    }
                }
            } finally {
                setShowSplashScreen(false)
            }

            return () => (didRequest.current = true)
        }

        if (accessToken) {
            requestUser()
        } else {
            dispatch(props.logout())
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{props.children}</>
}

export default connector(AuthInit)
