import {textToHtml} from "../../../utils";
import { AlertProps, AlertType } from "./index";

/**
 * Сообщение без возможности его удаления
 */
const Alert = ({ message, type = AlertType.DANGER, additionalClassNames = "mb-5 mt-5" }: AlertProps) =>
  message.trim() !== "" ? (
    <div className={`alert alert-${type} d-flex align-items-center ${additionalClassNames}`}>
        <i className={`fas ${AlertType.getIconClass(type)} fs-1 me-4 alert-${type}`}></i>
        <div className="d-flex flex-column">
        <span>{textToHtml(message)}</span>
      </div>
    </div>
  ) : (
    <></>
  );

export default Alert;
