import {createTheme} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {InternalEvent, InternalEventType} from "../models/InternalEvent";
import {dateTimeFormatter} from "../../../helpers/Formatter";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem'
                },
                selectLabel: {
                    fontSize: '1.075rem',
                    marginTop: '12px'
                },
                displayedRows: {
                    fontSize: '1.075rem',
                    marginTop: '14px'
                }
            }
        }
    },
});

export const getColumns = (): GridColDef[] => [
    {
        field: 'msisdn',
        headerName: 'MSISDN',
        width: 145,
        valueFormatter: (params) => params.value ?? ''
    },
    {
        field: 'date',
        headerName: 'Дата и время',
        type: "string",
        width: 190
    },
    // {
    //     field: 'source_name',
    //     headerName: 'Источник события',
    //     width: 200
    // },
    {
        field: 'type',
        headerName: 'Тип события',
        type: 'singleSelect',
        valueOptions: [...InternalEventType.getList()],
        width: 250,
        valueFormatter: (params) => InternalEventType.getLabel(params.value as InternalEventType)
    },
    // {
    //     field: 'descr',
    //     headerName: 'Описание',
    //     width: 200
    // }
]

export const getRows = (eventList: InternalEvent[] | undefined): any => eventList?.map((event) =>
    ({
        ...event,
        date: dateTimeFormatter(event.date as string)
    })
)
