import {toAbsoluteUrl} from '../../../helpers'
import {Spinner} from "react-bootstrap-v5";

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img src={toAbsoluteUrl('/media/logos/eko-logo-new.png')} alt='Экомобайл' />
      <Spinner animation="grow"/>
    </div>
  )
}
