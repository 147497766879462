import {createTheme} from "@mui/material";
import {GridColumnHeaderParams} from "@mui/x-data-grid";
import {KTSVG} from "../../../../../../../_metronic/helpers";
import React from "react";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = (deleteImei: () => Promise<boolean>, showButton: boolean) => [
    {
        field: 'imei',
        headerName: 'IMEI',
        width: 230
    },
    {
        field: 'deleteIcon',
        width: 50,
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) =>
            <>
                {showButton &&
                <span className="text-gray-600 fs-6 cursor-pointer" onClick={deleteImei}>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                </span>
                }
            </>
    }
]

export const getRows = (imeiList: string[] | undefined): any => imeiList?.map((item, index) =>
    ({
        id: item,
        imei: item,
        deleteIcon: ''
    })
)
