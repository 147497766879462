import React from 'react'
import {GridCellValue} from "@mui/x-data-grid";

/**
 * Преобразует десятизначный номер в формат +7 (123) 456-78-90
 * @param msisdn
 */
export const formatPhoneNumberFrom10Digits = (msisdn?: string | number) => {
    if (msisdn) {
        const stringifiedMsisdn = msisdn.toString()
        return '+7 ('
            + stringifiedMsisdn[0] + stringifiedMsisdn[1] + stringifiedMsisdn[2] + ') '
            + stringifiedMsisdn[3] + stringifiedMsisdn[4] + stringifiedMsisdn[5] + '-'
            + stringifiedMsisdn[6] + stringifiedMsisdn[7] + '-'
            + stringifiedMsisdn[8] + stringifiedMsisdn[9]
    }
    return ''
}

/**
 * Преобразует номер из формата +7 (123) 456-78-90 в десятизначный.
 * Если количество цифр в полученном результате отличается от 10, возвращает пустую строку
 * Удобен при работе в паре с плагином InputMask
 *
 * @param msisdn
 */
export const formatPhoneNumberTo10Digits = (msisdn?: string | number) => {
    if (msisdn) {
        const result = msisdn.toString().slice(2).replace(/\D/g, '')
        return result?.length === 10 ? result : ''
    }
    return ''
}

/**
 * Преобразует символ переноса строки "/n" в тег "<br>"
 */
export const nl2br = (text?: string) => {
    return text?.split('\n').map((item, key) => <React.Fragment key={key}>{item}<br/></React.Fragment>)
}

/**
 * Создаёт список пунктов по одному на строке в HTML формате
 * Если список создаётся из массива объектов, то необходимо указать название поля, значения которого будут формировать этот список
 *
 * @param list
 * @param itemFieldName
 */
export const formatListHTML = (list: any[] | undefined | null, itemFieldName: string = ''): any[] => {
    if (!list) {
        return []
    }

    let output: any[] = []
    list.forEach((item, index) => {
        if (typeof item === "string" || typeof item === "number") {
            output.push(<React.Fragment key={index}>{item}<br/></React.Fragment>)
        } else {
            output.push(<React.Fragment key={index}>{item[itemFieldName]}<br/></React.Fragment>)
        }
    })
    return output
}

/**
 * Создаёт список пунктов в текстовом формате с произвольным разделителем
 *
 * @param list
 * @param itemFieldName Поле, из которого извлекается текст для каждого элемента списка
 * @param delimiter
 */
export const formatListPlainText = (list: any[] | undefined | null, itemFieldName: string | null = null, delimiter: string = ", "): string => {
    if (!list) {
        return ''
    }

    let output: string = ''
    list.forEach((item) => {
        if (typeof item === "object") {
            if (itemFieldName === null) {
                return ''
            }
            output += output ? delimiter + item[itemFieldName] : item[itemFieldName]
        } else {
            output += output ? delimiter + item : item
        }
    })
    return output
}

/**
 * Приводит строковую дату к виду "dd.mm.YYYY"
 */
export const dateFormatter = (date: string) => date.trim() ? new Date(date).toLocaleDateString() : ''

/**
 * Приводит строковую дату к виду "dd.mm.YYYY H:i:s"
 */
export const dateTimeFormatter = (dateTime: string) => dateTime && dateTime.trim() ? new Date(dateTime).toLocaleDateString() + ' ' + new Date(dateTime).toLocaleTimeString() : ''

/**
 * Приводит строковую дату к виду "dd.mm.YYYY H:i:s" с фильтрацией null и значений типа '0000-00-00'
 */
export const dateTimeFormatterWithFilter = (value: string | GridCellValue) => {
    if (!value || /0000/.test(value as string)) {
        return ''
    }
    const dateTime = new Date(value as string)
    return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString()
}
