import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {getUserByToken} from '../api'
import {UserModel} from "../Models";

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

/*
 * Структура стейта авторизации
 */
export interface IAuthState {
  user?: UserModel
  accessToken?: string
  refreshToken?: string
}

/*
 * Стейт авторизации по умолчанию
 */
const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined
}

/*
 * Типы экшнов авторизации
 * Тип Экшна: '[Название генератора экшна] расшифровка (краткое пояснение) экшна'
 */
export const actionTypes = {
  FetchTokens: '[FetchTokens] Action',
  Logout: '[Logout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

/*
 * Генераторы экшнов авторизации
 */
export const actions = {
  fetchTokens: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.FetchTokens,
    payload: {accessToken, refreshToken}
  }),
  logout: () => ({
    type: actionTypes.Logout
  }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({
    type: actionTypes.UserLoaded,
    payload: {user}
  }),
}

/**
 * Редьюсер экшнов авторизации
 */
const authReducer = (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
  switch (action.type) {
    case actionTypes.FetchTokens: {
      return {
        accessToken: action.payload?.accessToken,
        refreshToken: action.payload?.refreshToken,
        user: undefined
      }
    }

    case actionTypes.Logout: {
      return initialAuthState
    }

    case actionTypes.UserRequested: {
      return {...state, user: undefined}
    }

    case actionTypes.UserLoaded: {
      const user = action.payload?.user
      return {...state, user}
    }

    default:
      return state
  }
}

/**
 * Редьюсер экшнов авторизации для работы с Redux-store
 */
export const authPersistReducer = persistReducer(
  {
    storage,
    key: 'm2m.ekomobile.ru',
    whitelist: ['user', 'accessToken', 'refreshToken'] // Эти значения или сущности разрешено хранить в сторе
  },
  authReducer
)

/**
 * Хуки Saga для тюнинга экшнов авторизации
 */
export function* authSaga() {
  yield takeLatest(actionTypes.FetchTokens, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: {user}} = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })
}
