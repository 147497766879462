/**
 * Типы отчётов
 */
export enum ReportType {
    INTERNET = 'internet',
    SMS = 'sms',
    STATUS = 'status',
    DIAGNOSTICS = 'diagnostics'
}

export namespace ReportType {
    /**
     * Карта названий отчётов
     */
    const labelMap: Map<ReportType, string> = new Map([
        [ReportType.INTERNET, "Потребление интернет трафика"],
        [ReportType.SMS, "Потребление СМС трафика"],
        [ReportType.STATUS, "Отчет по статусам"],
        [ReportType.DIAGNOSTICS, "Отчет по инструментам диагностики"],
    ])

    /**
     * Название отчёта
     */
    export function getLabel(reportType: ReportType) {
        return labelMap.get(reportType) ?? ''
    }

    /**
     * Список всех названий отчётов
     */
    export function getLabelList() {
        return Array.from(labelMap.keys())
    }
}
