/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react";
import {Link, useLocation, useParams} from 'react-router-dom'

const SimCardHeader: FC = () => {
    const location = useLocation()
    let params: { msisdn?: string | undefined } = useParams();

    return (
        <div className="card-header pt-5 pb-0">
            <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `/simcard/list/${params.msisdn}/details` && 'active')
                            }
                            to={`/simcard/list/${params.msisdn}/details`}
                        >
                            Общая информация
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `/simcard/list/${params.msisdn}/services` && 'active')
                            }
                            to={`/simcard/list/${params.msisdn}/services`}
                        >
                            Услуги
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `/simcard/list/${params.msisdn}/balance` && 'active')
                            }
                            to={`/simcard/list/${params.msisdn}/balance`}
                        >
                            Баланс
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `/simcard/list/${params.msisdn}/limit` && 'active')
                            }
                            to={`/simcard/list/${params.msisdn}/limit`}
                        >
                            Лимиты
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `/simcard/list/${params.msisdn}/geo` && 'active')
                            }
                            to={`/simcard/list/${params.msisdn}/geo`}
                        >
                            Геолокация устройства
                        </Link>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `/simcard/list/${params.msisdn}/detail` && 'active')
                            }
                            to={`/simcard/list/${params.msisdn}/detail`}
                        >
                            Расходы
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export {SimCardHeader}
