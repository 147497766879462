import {useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {LimitForm} from "./LimitForm";
import {IHandleCloseProps} from "../../../models/Modal";

export interface ICreateLimitProps {
    msisdn: number | string | undefined
    onFinish: () => void
}

/**
 * Создание лимитного правила
 */
export function CreateLimit({msisdn, onFinish}: ICreateLimitProps) {
    const [show, setShow] = useState(false)

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        isSubmitted && onFinish()
    }

    return (
        <div className="px-5 mt-5">
            <span className="d-flex align-items-center text-gray-600 fs-6" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                <span>Создать лимитное правило</span>
            </span>
            {show &&
            <LimitForm msisdn={msisdn} show={show} handleClose={closeModal}/>
            }
        </div>
    )
}
