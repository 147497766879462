export enum SimCardStatus {
    'ACTIVE' = 'active',
    'BLOCKED' = 'blocked'
}

export namespace SimCardStatus {
    const labelMap = new Map<SimCardStatus, string>([
        [SimCardStatus.ACTIVE, 'Активен'],
        [SimCardStatus.BLOCKED, 'Блокирован']
    ])

    export const getLabel = (status: SimCardStatus): string | undefined => {
        return labelMap.get(status) ?? ''
    }
}
