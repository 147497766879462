import {createTheme} from "@mui/material";
import {GridRenderCellParams} from "@mui/x-data-grid";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

const getNameLink = (params: GridRenderCellParams) => {
    return <span className="pseudoLink">{params.row.name}</span>
}

export const getColumns = () => [
    {
        field: 'name',
        headerName: 'Наименование контакта',
        width: 200,
        renderCell: getNameLink,
    },
    {
        field: 'phone',
        headerName: 'Телефон',
        width: 145
    },
    {
        field: 'email',
        headerName: 'E-mail',
        width: 200
    },
    {
        field: 'descr',
        headerName: 'Описание',
        width: 200
    }
]
