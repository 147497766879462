import {useEffect, useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {formatListHTML} from "../../../../../helpers/Formatter";
import {ContactListForm, SimCardContactWithCheckedItems} from "./ContactListForm";

export interface IContactListProps {
    msisdn?: string | undefined
    contactList?: SimCardContactWithCheckedItems[]
}

/**
 * Компонент для привязки/отвязки контактов к номеру MSISDN
 */
export function ContactList({msisdn, contactList}: IContactListProps) {
    const [show, setShow] = useState(false)
    const [updatedContactList, setUpdatedContactList] = useState<SimCardContactWithCheckedItems[] | undefined>([])

    const closeModal = (updatedContactList?: SimCardContactWithCheckedItems[]) => {
        setShow(false)
        updatedContactList && setUpdatedContactList(updatedContactList)
    }

    useEffect(() => {
        setUpdatedContactList(contactList)
    }, [contactList])

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0 pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">Контакты
                    <span
                        style={{cursor: "pointer"}}
                        onClick={() => setShow(true)}
                    ><KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-1 me-3 mx-3'/></span>
                </h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                <span>{updatedContactList?.length !== 0 && formatListHTML(updatedContactList, 'name')}</span>
            </div>
            {show &&
            <ContactListForm msisdn={msisdn} contactList={updatedContactList} show={show} handleClose={closeModal}/>
            }
        </div>
    )
}
