import React, {useEffect, useState} from "react";
import {ModalProps} from "../../../models/Modal";
import {activateServices, fetchAvailableServiceList} from "../../../api";
import {DataGrid, GridSelectionModel, ruRU} from "@mui/x-data-grid";
import {Modal} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../../_metronic/helpers";
import Alert from "../../../../../components/alerts/Alert";
import {ThemeProvider} from "@mui/material";
import {getColumns, getRows, theme} from "./AvailableServiceListDataGridConfig";

export interface IActivateServicesProps extends ModalProps {
    msisdn: number
}

/**
 * Активация услуг
 */
export function ActivateServices({msisdn, show, handleClose}: IActivateServicesProps) {
    const [serviceList, setServiceList] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    useEffect(() => {
        const getAvailableServiceList = async () => {
            if (msisdn) {
                const response = await fetchAvailableServiceList(msisdn)
                switch (response.status) {
                    case "ok":
                        setServiceList(response.data.data.items)
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу и попробовать снова.")
                        return false;
                }
            }
        }

        getAvailableServiceList()
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const activateService = async () => {
        setLoading(true)
        const response = await activateServices(msisdn!, selectionModel as number[])
        switch (response.status) {
            case "ok":
                handleClose()
                return true
            default:
                console.log(response)
                setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу и попробовать снова.")
                return false;
        }
    }

    return (
        <Modal
            size={'lg'}
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            style={{maxWidth: "unset"}}
            dialogClassName="modal-dialog-scrollable w-700px"
            onHide={handleClose}
        >
            <div className='modal-header py-4'>
                <h3>Добавление услуг</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-10 center'>

                {message !== '' && <Alert additionalClassNames='mt-0 mb-5' message={message}/>}

                <div className="card-body px-0 pt-0 pb-0">
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            autoHeight
                            hideFooter
                            disableColumnMenu
                            disableSelectionOnClick
                            checkboxSelection
                            loading={loading}
                            columns={getColumns()}
                            rows={getRows(serviceList)}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                        />
                    </ThemeProvider>
                    <div style={{textAlign: "right"}}>
                        <button
                            type='submit'
                            className='btn btn-primary mt-5 mb-0'
                            onClick={activateService}
                        > Добавить
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
