import axios from "axios";
import { API_URL } from "../../../../config";
import {SimCardStatus} from "../models/SimCardStatus";
import {SimCardLimitRequestProps, SimCardLimitResponseError} from "../models/SimCardLimit";
import {CommonError, ErrorResponse, OkResponse, processResponse} from "../../../api/CommonApi";
import {BillItemizationExtendedRequestProps, BillItemizationRequestProps, BillItemizationResponse} from "../models/BillItemization";
import {DeviceType, SimCardBalanceHistoryResponse, SimCardContact, SimCardListRequest, SimCardListResponse} from "../models/Models";

export const SIM_CARD_LIST = `${API_URL}/m2m/api/sim_cards.cgi`
export const SIM_CARD_HISTORY = `${API_URL}/m2m/api/sim_cards/balance.cgi`
export const SET_STATUS = `${API_URL}/m2m/api/sim_cards/set_status.cgi`
export const DO_ACTIVATION = `${API_URL}/m2m/api/sim_cards/do_activation.cgi`
export const SEND_TEST_SMS = `${API_URL}/m2m/api/diagnostic/sms_test.cgi`
export const SET_DEVICE_DESCRIPTION = `${API_URL}/m2m/api/sim_cards/set_device_descr.cgi`
export const SIM_CARD_REPLACE = `${API_URL}/m2m/api/sim_cards/change_sim.cgi`
export const SIM_CARD_SET_GEO_POSITION = `${API_URL}/m2m/api/sim_cards/set_device_address.cgi`
export const SIM_CARD_SET_GEO_POSITION_COMMENT = `${API_URL}/m2m/api/sim_cards/set_device_address_comment.cgi`
export const SIM_CARD_SET_INVENTORY_NUMBER = `${API_URL}/m2m/api/sim_cards/set_device_inventory_number.cgi`

/** Группы */
export const GROUP_LIST = `${API_URL}/m2m/api/sim_groups/list.cgi`
export const GROUP_CREATE = `${API_URL}/m2m/api/sim_groups/create.cgi`
export const GROUP_EDIT = `${API_URL}/m2m/api/sim_groups/edit.cgi`
export const GROUP_DELETE = `${API_URL}/m2m/api/sim_groups/delete.cgi`
export const GROUP_RENAME = `${API_URL}/m2m/api/sim_groups/rename.cgi`

/** Контакты */
export const CONTACT_LIST = `${API_URL}/m2m/api/contacts/list.cgi`
export const CONTACT_CREATE = `${API_URL}/m2m/api/contacts/create.cgi`
export const CONTACT_EDIT = `${API_URL}/m2m/api/contacts/edit_instance.cgi`
export const CONTACT_DELETE = `${API_URL}/m2m/api/contacts/delete.cgi`
export const EDIT_CONTACT_ASSIGNMENTS = `${API_URL}/m2m/api/contacts/edit.cgi`
export const LIST_CONTACT_ASSIGNMENTS = `${API_URL}/m2m/api/sim_cards/contacts.cgi`

/** Типы устройств */
export const DEVICE_TYPE_LIST = `${API_URL}/m2m/api/device_type/list.cgi`
export const DEVICE_TYPE_CREATE = `${API_URL}/m2m/api/device_type/create.cgi`
export const DEVICE_TYPE_EDIT = `${API_URL}/m2m/api/device_type/edit_instance.cgi`
export const DEVICE_TYPE_DELETE = `${API_URL}/m2m/api/device_type/delete.cgi`
export const DEVICE_TYPE_SET = `${API_URL}/m2m/api/sim_cards/set_device_type.cgi`

/** Лимитные правила */
export const LIMIT_LIST = `${API_URL}/m2m/api/quote_rules/list.cgi`
export const LIMIT_CREATE = `${API_URL}/m2m/api/quote_rules/create.cgi`
export const LIMIT_DELETE = `${API_URL}/m2m/api/quote_rules/delete.cgi`

/** IMEI */
export const IMEI_LIST = `${API_URL}/m2m/api/fraud/imei_lock_list.cgi`
export const IMEI_ADD = `${API_URL}/m2m/api/fraud/imei_lock_append.cgi`
export const IMEI_DELETE = `${API_URL}/m2m/api/fraud/imei_lock_delete.cgi`
export const IMEI_HISTORY = `${API_URL}/m2m/api/fraud/imei_history.cgi`

/** Детализация */
export const BILL_ITEMIZATION = `${API_URL}/m2m/api/detalization/detail_list.cgi`
export const BILL_ITEMIZATION_EXTENDED = `${API_URL}/m2m/api/detalization/detail_list_extended.cgi`

/** Услуги */
export const SERVICE_LIST_ACTIVE = `${API_URL}/m2m/api/services/list_connected.cgi`
export const SERVICE_LIST_AVAILABLE = `${API_URL}/m2m/api/services/list_available_for_activation.cgi`
export const SERVICE_LIST_ACTIVATE = `${API_URL}/m2m/api/services/activate.cgi`
export const SERVICE_LIST_DEACTIVATE = `${API_URL}/m2m/api/services/deactivate.cgi`

/**
 * Список симкарт
 */
export function fetchSimCardList({
                                     msisdn,
                                     group_id,
                                     limit = 1000,
                                     offset = 0
                                 }: Partial<SimCardListRequest>): Promise<OkResponse<SimCardListResponse> | ErrorResponse<CommonError>> {
    const response = axios.post<SimCardListResponse>(SIM_CARD_LIST, {msisdn, group_id, limit, offset})
    return processResponse<SimCardListResponse, CommonError>(response)
}

/**
 * Информация по симкарте
 */
export function fetchSimCardInfo({msisdn}: Partial<SimCardListRequest>): Promise<OkResponse<SimCardListResponse> | ErrorResponse<CommonError>> {
    const response = axios.post<SimCardListResponse>(SIM_CARD_LIST, {msisdn})
    return processResponse<SimCardListResponse, CommonError>(response)
}

/**
 * История изменения баланса по симкарте
 */
export function fetchSimCardBalanceHistory({msisdn}: Partial<SimCardListRequest>): Promise<OkResponse<SimCardBalanceHistoryResponse> | ErrorResponse<CommonError>> {
    const response = axios.post<SimCardBalanceHistoryResponse>(SIM_CARD_HISTORY, {msisdn})
    return processResponse<SimCardBalanceHistoryResponse, CommonError>(response)
}

/**
 * Отправка тестовой SMS
 */
export function sendTestSms(msisdn: number, text: string = '') {
    const response = axios.post(SEND_TEST_SMS, {msisdn, sms_text: text})
    return processResponse(response)
}

/**
 * Установка активности симкарты
 */
export function setStatus(msisdn: number, status: SimCardStatus) {
    const response = axios.post(SET_STATUS, {msisdn, status})
    return processResponse(response)
}

/**
 * Активация симкарты
 */
export function activateSim(msisdn: number) {
    const response = axios.post(DO_ACTIVATION, {msisdn})
    return processResponse(response)
}

/**
 * Добавление коментария к симкарте (по факту - описание устройства)
 */
export function setDeviceDescription(msisdn: string, description?: string) {
    const response = axios.post(SET_DEVICE_DESCRIPTION, {msisdn, description})
    return processResponse(response)
}

/**
 * Создание группы
 */
export function createGroup(groupName: string) {
    const response = axios.post(GROUP_CREATE, {group_name: groupName})
    return processResponse(response)
}

/**
 * Удаление группы
 */
export function deleteGroup(groupId: string) {
    const response = axios.post(GROUP_DELETE, {group_id: groupId})
    return processResponse(response)
}

/**
 * Переименование группы
 */
export function renameGroup(groupId: string, newGroupName?: string) {
    const response = axios.post(GROUP_RENAME, {group_id: groupId, new_group_name: newGroupName})
    return processResponse(response)
}

/**
 * Список групп абонента
 */
export function fetchGroupList() {
    const response = axios.post(GROUP_LIST)
    return processResponse(response)
}

/**
 * Редактирование состава группы
 */
export function editGroup(groupId: number, action: string, msisdnList: string[]) {
    const response = axios.post(GROUP_EDIT, {
        action,
        group_id: groupId,
        msisdn_list: msisdnList,
        // С этим флагом операция массового добавления/удаления не будет прервана сообщением об ошибке,
        // если добавляемые номера уже находились в группе или если удаляемые номера в ней изначально отсутствовали
        flag_no_check_existed: 1
    })
    return processResponse(response)
}

/**
 * Создание контакта
 */
export function createContact({name, phone, email = '', descr = ''}: Partial<SimCardContact>) {
    const response = axios.post(CONTACT_CREATE, {name, phone, email, descr})
    return processResponse(response)
}

/**
 * Удаление контакта
 */
export function deleteContact(id: string) {
    const response = axios.post(CONTACT_DELETE, {id})
    return processResponse(response)
}

/**
 * Список контактов абонента
 */
export function listContact() {
    const response = axios.post(CONTACT_LIST)
    return processResponse(response)
}

/**
 * Редактирование контакта
 */
export function editContact({id, name, phone, email = '', descr = ''}: Partial<SimCardContact>) {
    const response = axios.post(CONTACT_EDIT, {id, name, phone, email, descr})
    return processResponse(response)
}

/**
 * Редактирование привязки контактов к номеру
 */
export function editContactAssignments(contactId: number, action: string, msisdnList: string[]) {
    const response = axios.post(EDIT_CONTACT_ASSIGNMENTS, {
        action,
        contact_id: contactId,
        msisdn_list: msisdnList,
        // С этим флагом операция массового добавления/удаления не будет прервана сообщением об ошибке,
        // если добавляемые контакты уже были привязаны к номеру или если удаляемые контакты изначально не были к нему привязаны
        flag_no_check_existed: 1
    })
    return processResponse(response)
}

/**
 * Получение списка контактов, привязанных к номеру
 */
export function listContactAssignments(msisdn: number) {
    const response = axios.post(LIST_CONTACT_ASSIGNMENTS, {msisdn})
    return processResponse(response)
}

/**
 * Создание типа устройств
 */
export function createDeviceType({name, device_brand = '', device_model = '', generation = [], descr = ''}: Partial<DeviceType>) {
    const response = axios.post(DEVICE_TYPE_CREATE, {name, device_brand, device_model, generation, descr})
    return processResponse(response)
}

/**
 * Редактирование типа устройств
 */
export function editDeviceType({id, name, device_brand = '', device_model = '', generation = [], descr = ''}: Partial<DeviceType>) {
    const response = axios.post(DEVICE_TYPE_EDIT, {id, name, device_brand, device_model, generation, descr})
    return processResponse(response)
}

/**
 * Удаление типа устройств
 */
export function deleteDeviceType(id: number) {
    const response = axios.post(DEVICE_TYPE_DELETE, {id})
    return processResponse(response)
}

/**
 * Список типов устройств
 */
export function listDeviceType() {
    const response = axios.post(DEVICE_TYPE_LIST)
    return processResponse(response)
}

/**
 * Привязка (отвязка) номера к типу устройств
 */
export function setDeviceType(msisdn: string, id?: string | undefined) {
    const response = axios.post(DEVICE_TYPE_SET, {msisdn, id})
    return processResponse(response)
}

/**
 * Список лимитных правил на номере
 */
export function limitList(msisdn: number | string) {
    const response = axios.post(LIMIT_LIST, {msisdn: Number(msisdn)})
    return processResponse(response)
}

/**
 * Создание лимитного правила
 */
export function limitCreate({msisdn, quote, limitPeriod, intervalDuration, limitMeasure, limitAction}: Partial<SimCardLimitRequestProps>) {
    const response = axios.post(LIMIT_CREATE, {
        quote,
        msisdn: Number(msisdn),
        period_type: limitPeriod,
        interval_duration: intervalDuration,
        limit_measure: limitMeasure,
        action_type: limitAction
    })
    return processResponse<unknown, SimCardLimitResponseError>(response)
}

/**
 * Удаление лимитного правила
 */
export function limitDelete(id: string) {
    const response = axios.post(LIMIT_DELETE, {id})
    return processResponse(response)
}

/**
 * Список доверенных IMEI
 */
export function imeiList(msisdn: number | string) {
    const response = axios.post(IMEI_LIST, {msisdn: Number(msisdn)})
    return processResponse(response)
}

/**
 * Добавление IMEI в список доверенных
 */
export function imeiAdd(msisdn: number | string, imeiList: string[]) {
    const response = axios.post(IMEI_ADD, {msisdn: Number(msisdn), imei: imeiList})
    return processResponse(response)
}

/**
 * Удаление IMEI из списка доверенных
 */
export function imeiDelete(msisdn: number | string, imeiIdList: string[]) {
    const response = axios.post(IMEI_DELETE, {msisdn, imei: imeiIdList})
    return processResponse(response)
}

/**
 * История изменения IMEI
 */
export function imeiHistory(msisdn: number | string) {
    const response = axios.post(IMEI_HISTORY, {msisdn: Number(msisdn)})
    return processResponse(response)
}

/**
 * Замена SIM-карты
 */
export function replaceSim(msisdn: number | string, iccid: string) {
    const response = axios.post(SIM_CARD_REPLACE, {msisdn: Number(msisdn), sim: iccid})
    return processResponse(response)
}

/**
 * Местоположение устройства
 */
export function updateGeoPosition(msisdn: number | string, device_address: string) {
    const response = axios.post(SIM_CARD_SET_GEO_POSITION, {msisdn: Number(msisdn), device_address})
    return processResponse(response)
}

/**
 * Комментарий к местоположению устройства
 */
export function updateGeoPositionComment(msisdn: number | string, device_address_comment: string) {
    const response = axios.post(SIM_CARD_SET_GEO_POSITION_COMMENT, {msisdn: Number(msisdn), device_address_comment})
    return processResponse(response)
}

/**
 * Детализация расходов по номеру для вывода в интерфейсе
 */
export function fetchBillItemization({
                                         msisdn,
                                         date_from,
                                         date_to,
                                         limit = 1000,
                                         offset = 0
                                     }: Partial<BillItemizationRequestProps>): Promise<OkResponse<BillItemizationResponse> | ErrorResponse<CommonError>> {
    const response = axios.post<BillItemizationResponse>(BILL_ITEMIZATION, {msisdn, date_from, date_to, limit, offset})
    return processResponse<BillItemizationResponse, CommonError>(response)
}

/**
 * Расширенная детализация расходов по номеру для отправки на e-mail
 */
export function sendBillItemization({
                                        msisdn,
                                        email,
                                        period
                                    }: Partial<BillItemizationExtendedRequestProps>) {
    const response = axios.post(BILL_ITEMIZATION_EXTENDED, {msisdn, email, period})
    return processResponse(response)
}

/**
 * Задать инвентарный номер
 */
export function updateInventoryNumber(msisdn: number | string, inventory_number: string) {
    const response = axios.post(SIM_CARD_SET_INVENTORY_NUMBER, {msisdn: Number(msisdn), inventory_number})
    return processResponse(response)
}

/**
 * Список активных услуг
 */
export function fetchActiveServiceList(msisdn: number | string) {
    const response = axios.post(SERVICE_LIST_ACTIVE, {msisdn: Number(msisdn)})
    return processResponse(response)
}

/**
 * Список услуг, доступных для подключения
 */
export function fetchAvailableServiceList(msisdn: number | string) {
    const response = axios.post(SERVICE_LIST_AVAILABLE, {msisdn: Number(msisdn)})
    return processResponse(response)
}

/**
 * Активация услуг
 */
export function activateServices(msisdn: number | string, serviceIdList: number[]) {
    const response = axios.post(SERVICE_LIST_ACTIVATE, {msisdn, service: serviceIdList})
    return processResponse(response)
}

/**
 * Деактивация услуг
 */
export function deactivateServices(msisdn: number | string, serviceIdList: number[]) {
    const response = axios.post(SERVICE_LIST_DEACTIVATE, {msisdn, service: serviceIdList})
    return processResponse(response)
}
