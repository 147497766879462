import {SimCard} from "../../../models/Models";

export interface INetwork {
    cardInfo: SimCard | undefined
}

/**
 * Информация о сети
 */
export function Network({cardInfo}: INetwork) {

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0 pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">Сеть</h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                <div className=" mb-2">
                    <span className="fw-bolder text-gray-800">APN: </span> <span> {cardInfo?.apn || '—'}</span>
                </div>
                <div className=" mb-2">
                    <span className="fw-bolder text-gray-800">IP: </span> <span> {cardInfo?.ip || '—'}</span>
                </div>
            </div>
        </div>
    )
}
