import {sendTestSms} from "../../api";
import React, {useEffect, useRef, useState} from "react";
import {Modal, Spinner, Table} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../_metronic/helpers";
import {ModalProps} from "../../models/Modal";
import {formatPhoneNumberFrom10Digits} from "../../../../helpers/Formatter";
import Alert from "../../../../components/alerts/Alert";
import {SimCard} from "../../models/Models";
import {AlertType} from "../../../../components/alerts";

interface SendTestSmsModalProps extends ModalProps {
    selectedRows: Partial<SimCard>[]
}

/**
 * Модальное окно для отправки тестовых SMS
 */
export const SendTestSmsModal = ({selectedRows, show, handleClose}: SendTestSmsModalProps) => {
    let refreshHandler = useRef()
    let someSmsNotSent = useRef(false)
    const [, setRefresher] = useState<number>(0)
    const [smsText, setSmsText] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [responseMap, setResponseMap] = useState<Map<number, any>>(new Map())

    /**
     * Проверка наличия в выборке заблокированных номеров
     */
    let noBlockedMsisdn = true
    for (const row of selectedRows) {
        if (row.status === 'blocked') {
            noBlockedMsisdn = false
            break
        }
    }

    useEffect(() => {
        /**
         * Отправка SMS
         */
        const sendSms = async (msisdn: number) => {
            const response = await sendTestSms(msisdn, smsText)
            switch (response.status) {
                case "ok":
                    return <KTSVG path='/media/icons/duotune/general/gen043.svg' className='svg-icon svg-icon-1 svg-icon-success me-3'/>
                default:
                    someSmsNotSent.current = true
                    return <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon svg-icon-1 svg-icon-danger me-3'/>
            }
        }

        /**
         * Получение ответа от сервера по всем номерам
         */
        const getResponse = () => {
            setResponseMap(new Map());
            selectedRows.map(async (simCard) => {
                let response = await sendSms(simCard.msisdn!)
                setResponseMap((prevMap) => prevMap.set(simCard.msisdn!, response))
            })
            setSubmitDisabled(true)
            /** После сетапа ответов сервера в responseMap необходима периодическая перерисовка компонента для отражения последних изменений */
            refreshHandler.current = setInterval(() => {
                setRefresher(Date.now())
            }, 1000) as any
        }

        isSubmitted && getResponse()
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Перед отправкой SMS показываем пустую таблицу с номерами
     */
    const tableBodyBeforeSubmit = () => {
        return selectedRows.map((simCard, index) => {
            return <tr key={index}>
                <td className="fs-4">{formatPhoneNumberFrom10Digits(simCard.msisdn)}</td>
                <td>&nbsp;</td>
            </tr>
        })
    }

    /**
     * После сабмита заполняем таблицу результатами ответа сервера
     */
    const tableBodyAfterSubmit = () => {
        return selectedRows.map((simCard, index) => {
            return <tr key={index}>
                <td className="fs-4">{formatPhoneNumberFrom10Digits(simCard.msisdn)}</td>
                <td>{responseMap.get(simCard.msisdn!) || <Spinner className="text-gray-600" animation='border' style={{width: "1.5rem", height: "1.5rem"}}/>}</td>
            </tr>
        })
    }

    const submit = () => {
        setIsSubmitted(true)
        setSubmitDisabled(true)
    }

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setIsSubmitted(false)
        setSubmitDisabled(false)
        someSmsNotSent.current = false
    }

    /**
     * Содержимое модального окна
     */
    const ModalBodyContent = () => {
        return (
            <>
                <p>По умолчанию будет отправлен текст <strong>test</strong></p>
                <div className="d-flex justify-content-between mb-5">
                    <input
                        placeholder="test"
                        style={{marginRight: "5px"}}
                        className="form-control form-control-solid"
                        value={smsText}
                        onInput={(event) => setSmsText(event.currentTarget.value)}
                        /* Фикс непонятного глюка с переполнением стека вызовов */
                        onFocus={(event) => event.stopPropagation()}
                    />
                    <button className="btn btn-primary" onClick={submit} disabled={submitDisabled}>Отправить</button>
                </div>
                <Table striped bordered className="gs-5">
                    <tbody>
                    {!isSubmitted
                        ? tableBodyBeforeSubmit()
                        : tableBodyAfterSubmit()
                    }
                    </tbody>
                </Table>
            </>
        )
    }

    return (
        <Modal
            className='modal fade'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-400px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-header py-4'>
                <h3>Отправка тестовых SMS</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-10'>
                {noBlockedMsisdn
                    ? <ModalBodyContent/>
                    : <Alert
                        type={AlertType.WARNING}
                        additionalClassNames={'p-5 mb-0'}
                        message={'Среди выбранных номеров имеются блокированные. Поскольку SMS могут быть отправлены только на активные номера, необходимо скорректировать выборку.'}
                    />
                }
            </div>
            {someSmsNotSent.current &&
                <Alert
                    type={AlertType.WARNING}
                    additionalClassNames={'p-5 mb-0'}
                    message={'Сервер сообщает об ошибке при отправке SMS на некоторые номера. Попробуйте заново отправить SMS на эти номера.'}
                />
            }
        </Modal>
    )
}
