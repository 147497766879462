/* eslint-disable jsx-a11y/anchor-is-valid */
import {fetchEventSubscriptionList} from "../api";
import React, {useEffect, useState} from 'react'
import {ThemeProvider} from "@mui/material";
import {SectionHeader} from "../SectionHeader";
import {PageTitle} from "../../../../_metronic/layout/core";
import AlertDismissible from "../../../components/alerts/Alert";
import {EventSubscription} from "../models/EventSubscription";
import {CreateEventSubscription} from "./create/CreateEventSubscription";
import {DeleteEventSubscription} from "./delete/DeleteEventSubscription";
import {getColumns, theme} from "./EventSubscriptionListDataGridConfig";
import {DataGrid, GridRowHeightParams, GridSelectionModel, ruRU} from "@mui/x-data-grid";


export function EventSubscriptionListPage() {
    const [refresh, setRefresh] = useState(Date.now())
    const [message, setMessage] = useState('')
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [subscriptionList, setSubscriptionList] = useState<EventSubscription[] | undefined>(undefined)

    useEffect(() => {
        const getResponse = async () => {
            const response = await fetchEventSubscriptionList()
            switch (response.status) {
                case "ok":
                    setSubscriptionList(response.data.data.items as EventSubscription[])
                    return true
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        }

        getResponse()
    }, [refresh])

    const refreshPage = () => {
        setRefresh(Date.now())
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Подписки на события</PageTitle>

            <div className="card">
                <SectionHeader/>

                {message && <AlertDismissible message={message}/>}

                <div className="card-body d-flex justify-content">
                    <div className="w-750px">
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                autoHeight
                                hideFooter
                                checkboxSelection
                                disableColumnMenu
                                getRowHeight={({densityFactor, model}: GridRowHeightParams) => {
                                    switch (model.events.length) {
                                        case 3:
                                            return 65 * densityFactor;
                                        case 4:
                                            return 80 * densityFactor;
                                        case 5:
                                            return 100 * densityFactor;
                                        default:
                                            return null
                                    }
                                }}
                                disableSelectionOnClick
                                columns={getColumns()}
                                rows={subscriptionList || []}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div>
                        <CreateEventSubscription onFinish={refreshPage}/>
                        {selectionModel.length !== 0 &&
                        <DeleteEventSubscription subscriptionIdList={selectionModel as number[]} onFinish={refreshPage}/>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
