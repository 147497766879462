/* eslint-disable jsx-a11y/anchor-is-valid */
import {Redirect, Route, Switch} from "react-router-dom";
import {SimCardListWrapper} from "./list/SimCardListWrapper";
import {SimCardGroupListWrapper} from "./group/SimCardGroupListWrapper";
import {DeviceTypeListWrapper} from "./deviceTypes/DeviceTypeListWrapper";
import {SimCardContactListWrapper} from "./contact/SimCardContactListWrapper";

export function SectionWrapper() {
    return (
        <Switch>
            <Route path='/simcard/groups'>
                <SimCardGroupListWrapper/>
            </Route>
            <Route path='/simcard/list'>
                <SimCardListWrapper/>
            </Route>
            <Route path='/simcard/contacts'>
                <SimCardContactListWrapper/>
            </Route>
            <Route path='/simcard/deviceTypes'>
                <DeviceTypeListWrapper/>
            </Route>
            <Redirect from='/simcard' to='/simcard/list'/>
            <Redirect from='/simcard/' to='/simcard/list'/>
            <Redirect to='/simcard/list'/>
        </Switch>
    )
}
