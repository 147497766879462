import {useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {DeleteContactForm} from "./DeleteContactForm";
import {IHandleCloseProps} from "../../models/Modal";

export interface IDeleteContactProps {
    contactIdList: number[]
    onFinish: () => void
}

/**
 * Удаление групп
 */
export function DeleteContact({contactIdList = [], onFinish}: IDeleteContactProps) {
    const [show, setShow] = useState(false)

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        isSubmitted && onFinish()
    }

    return (
        <>
            <div className="px-5 mt-6">
                <div>
                <span className="d-flex align-items-center text-gray-600 fs-6" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                    <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                    <span>Удалить контакты</span>
                </span>
                </div>
                <DeleteContactForm contactIdList={contactIdList} show={show} handleClose={closeModal}/>
            </div>
        </>
    )
}
