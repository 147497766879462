import {textToHtml} from "../../../utils";
import {AlertProps, AlertType} from "./index";
import {KTSVG} from "../../../_metronic/helpers";

/**
 * Сообщение с возможностью его удаления
 */
const AlertDismissible = ({message, type = AlertType.DANGER, additionalClassNames = 'p-5 mb-0 mt-5 mx-5'}: AlertProps) =>
    message.trim() !== "" ? (
        <div className={`alert alert-${type} d-flex align-items-center justify-content-between ${additionalClassNames}`}>
            <div className="d-flex align-items-center">
                <i className={`fas ${AlertType.getIconClass(type)} fs-1 me-4 alert-${type}`}></i>
                <div className="d-flex flex-column">
                    <span>{textToHtml(message)}</span>
                </div>
            </div>
            <button type="button" className="btn btn-icon h-30px" data-bs-dismiss="alert">
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-1 svg-icon-gray-600 me-3'/>
            </button>
        </div>
    ) : (
        <></>
    );

export default AlertDismissible;
