import {useState} from "react";
import {SimCard} from "../../models/Models";
import {SendTestSmsModal} from "./SendTestSmsModal";
import {Rest} from "../../../../models/common";

export interface ISendTestSmsProps extends Rest {
    label: string
    selectedRows: Partial<SimCard>[]
}

/**
 * Отправка тестовых SMS
 */
export const SendTestSms = ({selectedRows, label, ...rest}: ISendTestSmsProps) => {
    const [show, setShow] = useState(false)
    const closeModal = () => setShow(false)

    return (
        <>
            <button
                type='button'
                className={rest.className || ""}
                onClick={() => setShow(true)}
            >
                {label}
            </button>
            {show &&
                <SendTestSmsModal selectedRows={selectedRows} show={show} handleClose={closeModal}/>
            }
        </>
    )
}
