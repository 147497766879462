import {createTheme} from "@mui/material";
import {GridCellParams, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {LimitAction, LimitMeasure, LimitPeriod, SimCardLimit} from "../../models/SimCardLimit";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = (): GridColDef[] => [
    {
        field: 'quote',
        headerName: 'Значение лимита',
        headerAlign: 'center',
        sortable: false,
        description: 'Поддерживаются финансовый лимит и лимит по трафику',
        align: 'center',
        width: 170,
        renderCell: (params: GridRenderCellParams) => formatQuote(params)
    },
    {
        field: 'limitPeriod',
        headerName: 'Период действия',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        sortable: false,
        description: '1 месяц или интервал в днях',
        renderCell: (params: GridRenderCellParams) => formatLimitPeriod(params)
    },
    {
        field: 'limitAction',
        headerName: 'Тип действия',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        sortable: false,
        description: 'При достижении лимита генерируется событие (info), которое может дополнительно сопровождаться блокировкой номера (block)',
        renderCell: (params: GridRenderCellParams) => LimitAction.getLabel(params.row.limitAction)
    }
]

const formatQuote = (params: GridCellParams) => params.row.limitMeasure === LimitMeasure.MONEY
    ? params.row.quote + " " + LimitMeasure.getLabel(LimitMeasure.MONEY)
    : SimCardLimit.getLimitValueFromApi(params.row.quote)[0] + " " + LimitMeasure.getLabel(SimCardLimit.getLimitValueFromApi(params.row.quote)[1])

const formatLimitPeriod = (params: GridCellParams) => params.row.limitPeriod === LimitPeriod.INTERVAL
    ? LimitPeriod.getLabelInDays(params.row.intervalDuration)
    : LimitPeriod.ONE_MONTH + " месяц"