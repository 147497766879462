import {useState} from "react";
import {SetMultipleStatusForm} from "./SetMultipleStatusForm";
import {SimCard} from "../../models/Models";
import {Rest} from "../../../../models/common";

interface ISetStatusProps extends Rest {
    label: string
    selectedRows: Partial<SimCard>[]
    onFinish: () => void
}

/**
 * Установка/снятие блокировки номера (SIM-карты)
 */
export const SetMultipleStatus = ({selectedRows, label, onFinish, ...rest}: ISetStatusProps) => {
    const [show, setShow] = useState(false)
    const closeModal = () => setShow(false)

    return (
        <>
            <button
                type='button'
                className={rest.className || 'btn btn-primary'}
                onClick={() => setShow(true)}
            >
                {label}
            </button>
            {show &&
                <SetMultipleStatusForm
                    selectedRows={selectedRows}
                    show={show}
                    handleClose={closeModal}
                    onChange={onFinish}/>
            }
        </>
    )
}
