import {useEffect, useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {DeviceType} from "../../../models/Models";
import {DeviceAssignmentForm} from "./DeviceAssignmentForm";

export interface IDeviceProps {
    msisdn?: string | undefined
    deviceType?: DeviceType
}

/**
 * Привязка номера к типу устройств
 */
export function Device({msisdn, deviceType}: IDeviceProps) {
    const [show, setShow] = useState(false)
    const [updatedDeviceType, setUpdatedDeviceType] = useState<DeviceType | undefined>(undefined)

    const closeModal = (updatedDeviceType?: DeviceType) => {
        setShow(false)
        setUpdatedDeviceType(updatedDeviceType)
    }

    useEffect(() => {
        setUpdatedDeviceType(deviceType)
    }, [deviceType])

    return (
        <div className="card card-xl-stretch">
            <div className="card-header border-0  pt-5 pb-0 px-5">
                <h3 className="card-title fw-bolder text-gray-800">Устройство
                    <span
                        style={{cursor: "pointer"}}
                        onClick={() => setShow(true)}
                    ><KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon svg-icon-1 me-3 mx-3'/></span>
                </h3>
            </div>
            <div className="card-body px-5 pt-0 pb-0 fs-5">
                {updatedDeviceType?.id &&
                    <>
                        <div className=" mb-2">
                            <span className="fw-bolder text-gray-800">Наименование: </span> <span> {updatedDeviceType?.name}</span>
                        </div>
                        <div className=" mb-2">
                            <span className="fw-bolder text-gray-800">Марка: </span> <span> {updatedDeviceType?.device_brand}</span>
                        </div>
                        <div className=" mb-2">
                            <span className="fw-bolder text-gray-800">Модель: </span> <span> {updatedDeviceType?.device_model}</span>
                        </div>
                        <div className=" mb-2">
                            <span className="fw-bolder text-gray-800">Поколения связи: </span> <span> {updatedDeviceType?.generation?.join(',')}</span>
                        </div>
                        <div className=" mb-2">
                            <span className="fw-bolder text-gray-800">Описание: </span> <span> {updatedDeviceType?.descr}</span>
                        </div>
                    </>
                }
                {show &&
                    <DeviceAssignmentForm msisdn={msisdn} deviceType={updatedDeviceType} show={show} handleClose={closeModal}/>
                }
            </div>
        </div>
    )
}
