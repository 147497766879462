import clsx from "clsx";
import * as Yup from "yup";
import {useFormik} from "formik";
import React, {useState} from "react";
import {Modal} from "react-bootstrap-v5";
import InputMask from "react-input-mask";
import {KTSVG} from "../../../../../_metronic/helpers";
import Alert from "../../../../components/alerts/Alert";
import {eventSubscriptionAdd} from "../../api";
import {ExtendedModalProps} from "../../../simcard/models/Modal";
import {formatPhoneNumberTo10Digits} from "../../../../helpers/Formatter";
import {AddEventSubscriptionProps, EventSubscription, SendDays} from "../../models/EventSubscription";
import {InternalEventType} from "../../models/InternalEvent";

interface IEventSubscriptionForm extends ExtendedModalProps {
    eventSubscription?: EventSubscription | undefined
}

const schema = Yup.object().shape({
    name: Yup.string().required('Обязательное поле'),
    events: Yup.array().min(1, 'Выберите хотя бы один тип'),
    sms: Yup.object().shape({
        send_sms: Yup.boolean(),
        periodicity: Yup.number()
            .when('send_sms', {
                is: true,
                then: Yup.number().required("Обязательное поле")
            }),
        phones: Yup.string()
            .when('send_sms', {
                is: true,
                then: Yup.string()
                    .matches(/^(?:.*?\d){11}$/, {
                        message: 'Обязательное поле из 11 цифр',
                        excludeEmptyStrings: true
                    })
                    .required('Обязательное поле'),
            })
    }),
    email: Yup.object().shape({
        send_email: Yup.boolean(),
        periodicity: Yup.number()
            .when('send_email', {
                is: true,
                then: Yup.number().required("Обязательное поле")
            }),
        emails: Yup.string()
            .when('send_email', {
                is: true,
                then: Yup.string().required("Обязательное поле")
            })
    }),
})

const initialValues: AddEventSubscriptionProps = {
    name: '',
    events: [],
    sms: {
        send_sms: false,
        periodicity: 30,
        send_days: SendDays.WEEKDAYS,
        phones: ''
    },
    email: {
        send_email: false,
        periodicity: 30,
        send_days: SendDays.WEEKDAYS,
        emails: ''
    }
}

/**
 * Форма создания подписки
 */
export const EventSubscriptionForm = ({eventSubscription, show, handleClose}: IEventSubscriptionForm) => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setLoading(false)
        formik.resetForm()
    }

    const formik = useFormik({
        initialValues: eventSubscription || initialValues,
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values, {setSubmitting}) => {
            values.sms.phones = formatPhoneNumberTo10Digits(values.sms.phones)
            setLoading(true)
            const response = await eventSubscriptionAdd(values)
            switch (response.status) {
                case "ok":
                    setLoading(false)
                    handleClose({isSubmitted: true})
                    return true
                default:
                    setLoading(false)
                    setSubmitting(false)
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте выполнить операцию позднее.")
                    return false
            }
        },
    })

    /**
     * Возвращает состояние чекбокса (отмечен/не отмечен)
     *
     * @param checkboxValue
     */
    const getCheckedState = (checkboxValue: InternalEventType): boolean => formik.values.events!.includes(checkboxValue)

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted: false})
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-300px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-header py-4'>
                <h3>Создание подписки</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-5 center'>

                {message !== "" && <Alert additionalClassNames='p-5 mb-5 mt-0' message={message}/>}

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Наименование</label>
                        <input
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('name')}
                            className={clsx(
                                'form-control form-control-solid',
                                {
                                    'is-invalid': formik.touched.name && formik.errors.name
                                },
                                {
                                    'is-valid': formik.touched.name && !formik.errors.name,
                                }
                            )}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block error-color'>
                                    <span role='alert'>{formik.errors.name}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Типы событий</label>
                        {InternalEventType.getList().map((item, index) => {
                            return (
                                <div key={index} className="form-check form-check-custom form-check-solid pt-1">
                                    <input
                                        id={item}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="events"
                                        value={item}
                                        checked={getCheckedState(item)}
                                        onChange={formik.handleChange}
                                    />
                                    <label className="form-check-label" htmlFor={item}>
                                        {InternalEventType.getLabel(item)}
                                    </label>
                                </div>
                            )
                        })
                        }
                        {formik.errors.events && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block error-color'>
                                    <span role='alert'>{formik.errors.events}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-5'>
                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Способы уведомления</label>
                        <div className="form-check form-check-custom form-check-solid pt-1">
                            <input
                                id="eventSubscriptionSMS"
                                className="form-check-input"
                                type="checkbox"
                                name="sms.send_sms"
                                value="0"
                                checked={formik.values.sms.send_sms}
                                onChange={formik.handleChange}
                            />
                            <label className="form-check-label" htmlFor="eventSubscriptionSMS">По SMS</label>
                        </div>

                        {
                            formik.values.sms.send_sms &&
                            <div style={{
                                marginLeft: '27px',
                                background: '#eff2f5',
                                padding: '5px 10px',
                                borderRadius: '0.475rem',
                                marginBottom: '10px'
                            }} className='mt-2'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1'>Интервал отправки (мин.)</label>
                                    <input
                                        type='number'
                                        min={30}
                                        placeholder='От 30 мин.'
                                        autoComplete='off'
                                        {...formik.getFieldProps('sms.periodicity')}
                                        className={clsx(
                                            'form-control',
                                            {
                                                'is-invalid': formik.touched.sms && formik.touched.sms.periodicity && formik.errors.sms && formik.errors.sms.periodicity
                                            },
                                            {
                                                'is-valid': formik.touched.sms && formik.touched.sms.periodicity && !formik.errors.sms?.periodicity
                                            }
                                        )}
                                    />
                                    {formik.touched.sms && formik.touched.sms.periodicity && formik.errors.sms && formik.errors.sms.periodicity && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block error-color'>
                                                <span role='alert'>{formik.errors.sms.periodicity}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1 w-100'>Дни отправки</label>
                                    <input
                                        type='radio'
                                        id='smsWeekdays'
                                        className='btn-check'
                                        {...formik.getFieldProps('sms.send_days')}
                                        value={SendDays.WEEKDAYS}
                                    />
                                    <label
                                        className={`btn btn-sm btn-outline btn-bg-white btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                            formik.values.sms.send_days === SendDays.WEEKDAYS ? 'active' : ''
                                        }`}
                                        htmlFor='smsWeekdays'
                                    >
                                        <span className='text-gray-800 fw-bold'>По будням</span>
                                    </label>

                                    <input
                                        type='radio'
                                        id='smsEveryday'
                                        className='btn-check'
                                        {...formik.getFieldProps('sms.send_days')}
                                        value={SendDays.EVERYDAY}
                                    />
                                    <label
                                        className={`btn btn-sm btn-outline btn-bg-white btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                            formik.values.sms.send_days === SendDays.EVERYDAY ? 'active' : ''
                                        }`}
                                        htmlFor='smsEveryday'
                                    >
                                        <span className='text-gray-800 fw-bold'>Ежедневно</span>
                                    </label>
                                </div>

                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1'>Телефон</label>
                                    <InputMask
                                        mask={"+7 (999) 999-99-99"}
                                        autoComplete='off'
                                        {...formik.getFieldProps('sms.phones')}
                                        className={clsx(
                                            'form-control',
                                            {
                                                'is-invalid': formik.touched.sms && formik.touched.sms.phones && formik.errors.sms && formik.errors.sms.phones
                                            },
                                            {
                                                'is-valid': formik.touched.sms && formik.touched.sms.phones && !formik.errors.sms?.phones
                                            }
                                        )}
                                    />
                                    {formik.touched.sms && formik.touched.sms.phones && formik.errors.sms && formik.errors.sms.phones && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block error-color'>
                                                <span role='alert'>{formik.errors.sms.phones}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        }

                        <div className="form-check form-check-custom form-check-solid pt-1">
                            <input
                                id="eventSubscriptionEmail"
                                className="form-check-input"
                                type="checkbox"
                                name="email.send_email"
                                value="0"
                                checked={formik.values.email.send_email}
                                onChange={formik.handleChange}
                            />
                            <label className="form-check-label" htmlFor="eventSubscriptionEmail">На E-mail</label>
                        </div>

                        {
                            formik.values.email.send_email &&
                            <div style={{
                                marginLeft: '27px',
                                background: '#eff2f5',
                                padding: '5px 10px',
                                borderRadius: '0.475rem',
                                marginBottom: '10px'
                            }} className='mt-2'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1'>Интервал отправки (мин.)</label>
                                    <input
                                        type='number'
                                        min={30}
                                        placeholder='От 30 мин.'
                                        autoComplete='off'
                                        {...formik.getFieldProps('email.periodicity')}
                                        className={clsx(
                                            'form-control',
                                            {
                                                'is-invalid': formik.touched.email && formik.touched.email.periodicity && formik.errors.email && formik.errors.email.periodicity
                                            },
                                            {
                                                'is-valid': formik.touched.email && formik.touched.email.periodicity && !formik.errors.email?.periodicity
                                            }
                                        )}
                                    />
                                    {formik.touched.email && formik.touched.email.periodicity && formik.errors.email && formik.errors.email.periodicity && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block error-color'>
                                                <span role='alert'>{formik.errors.email.periodicity}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1 w-100'>Дни отправки</label>
                                    <input
                                        type='radio'
                                        id='emailWeekdays'
                                        className='btn-check'
                                        {...formik.getFieldProps('email.send_days')}
                                        value={SendDays.WEEKDAYS}
                                    />
                                    <label
                                        className={`btn btn-sm btn-outline btn-bg-white btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                            formik.values.email.send_days === SendDays.WEEKDAYS ? 'active' : ''
                                        }`}
                                        htmlFor='emailWeekdays'
                                    >
                                        <span className='text-gray-800 fw-bold'>По будням</span>
                                    </label>

                                    <input
                                        type='radio'
                                        id='emailEveryday'
                                        className='btn-check'
                                        {...formik.getFieldProps('email.send_days')}
                                        value={SendDays.EVERYDAY}
                                    />
                                    <label
                                        className={`btn btn-sm btn-outline btn-bg-white btn-color-gray-600 btn-active-light-primary border-active px-4 m-2 ${
                                            formik.values.email.send_days === SendDays.EVERYDAY ? 'active' : ''
                                        }`}
                                        htmlFor='emailEveryday'
                                    >
                                        <span className='text-gray-800 fw-bold'>Ежедневно</span>
                                    </label>
                                </div>

                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-6 fw-bolder text-dark px-1'>E-mail адреса через запятую</label>
                                    <input
                                        type='text'
                                        autoComplete='off'
                                        {...formik.getFieldProps('email.emails')}
                                        className={clsx(
                                            'form-control',
                                            {
                                                'is-invalid': formik.touched.email && formik.touched.email.emails && formik.errors.email && formik.errors.email.emails
                                            },
                                            {
                                                'is-valid': formik.touched.email && formik.touched.email.emails && !formik.errors.email?.emails
                                            }
                                        )}
                                    />
                                    {formik.touched.email && formik.touched.email.emails && formik.errors.email && formik.errors.email.emails && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block error-color'>
                                                <span role='alert'>{formik.errors.email.emails}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        }

                    </div>

                    <div className='text-center d-flex justify-content-end'>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !(formik.values.sms.send_sms || formik.values.email.send_email)}
                        >
                            {!loading && <span className='indicator-label'>Сохранить</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>Обрабатываем...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                        </span>
                            )}
                        </button>

                    </div>
                </form>
            </div>
        </Modal>
    )
}
