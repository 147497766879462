/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import {EventSubscriptionListPage} from "./EventSubscriptionListPage";

export const EventSubscriptionListWrapper: FC = (props) => {
    return (
        <Switch>
            <Route exact path='/event/subscriptions'>
                <EventSubscriptionListPage/>
            </Route>
            <Redirect from='/event/subscriptions/' to='/event/subscriptions' />
        </Switch>
    )
}
