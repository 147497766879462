import React from 'react'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem title="SIM-карты" to='/simcard' />
      <MenuItem title="События" to='/event' />
      <MenuItem title="Отчёты" to='/report' />
    </>
  )
}
