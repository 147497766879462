/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import * as Yup from 'yup'
import {Button, ProgressBar} from "react-bootstrap-v5";
import {SectionHeader} from "./SectionHeader";
import {PageTitle} from "../../../_metronic/layout/core";
import Alert from "../../components/alerts/Alert";
import AlertString from "../../components/alerts/AlertString";
import {Field, Form, Formik, FormikProps} from "formik";
import {Tooltip, Zoom} from "@mui/material";
import {KTSVG} from "../../../_metronic/helpers";
import {ReportType} from "./models/ReportType";
import MsisdnListFile from "./components/MsisdnListFile";
import {ExcelReportHelper} from "./helpers/ExcelReportHelper";
import {ReportStatusExtended} from "./models/ReportStatus";
import {DateRangePicker} from "react-nice-dates";
import {ru} from "date-fns/locale";
import {formatRFC3339} from "date-fns";
import {getReportDiagnostics, getReportInternet, getReportSMS, getReportStatus, getSimCardInfo} from "./api";

export function ReportPage() {
    const [pbCounter, setPbCounter] = useState(0)
    const [msisdnList, setMsisdnList] = useState<number[]>([])
    const [messages, setMessages] = useState<Set<string>>(new Set())
    const [alertStringMessage, setAlertStringMessage] = useState('')
    const [, refreshPage] = useState(new Date())

    /**
     * Добавляет предупреждение к уже имеющимся. Повторные предупреждения игнорируются.
     * При вызове без аргумента все предупреждения очищаются
     * @param message
     */
    const setMessage = (message: string = '') => {
        if (!message.trim()) {
            messages.clear() // setMessages(new Set()) здесь не работает
        } else (
            setMessages(messages.add(message))
        )
    }

    /**
     * Выводит все предупреждения, разделяя их символом переноса строки
     */
    const getMessages = () => {
        let output = ''
        let aMessages = Array.from(messages)
        switch (messages.size) {
            case 0:
                return ''
            case 1:
                return aMessages[0]
            case 2:
                return aMessages[0] + "\n" + aMessages[1]
            default:
                let value: string
                let arrayedMessages = Array.from(messages)
                for (let key = 0; key < messages.size; key++) {
                    value = arrayedMessages[key]
                    switch (key) {
                        case 0:
                        case messages.size - 1:
                            output += value
                            break
                        default:
                            output += "\n" + value
                    }
                }
        }
        return output
    }

    const currentReportType = useRef(ReportType.INTERNET)

    const START_DATE = "startDate"
    const END_DATE = "endDate"
    const FORMAT = "dd.MM.yyyy"

    const now = new Date()
    const year = now.getFullYear()
    const month = ('0' + (now.getMonth() + 1)).slice(-2)
    const day = ('0' + now.getDate()).slice(-2)

    const firstDayOfMonth = formatRFC3339(Date.parse(year + '-' + month + '-01 00:00:00'));
    const currentDay = formatRFC3339(Date.parse(year + '-' + month + '-' + day + ' 23:59:59'));
    const [startDate, setStartDate] = useState<string>(firstDayOfMonth)
    const [endDate, setEndDate] = useState<string>(currentDay)
    const [dateRangeValid, setDateRangeValid] = useState(true)

    /**
     * В схеме валидации участвует только поле input типа "file"
     * Поле выбора периода отчёта валидации средствами Formik не поддаётся и имеет свой валидатор
     */
    const validationSchema = Yup.object().shape({
        reportType: Yup.string(),
        file: Yup.mixed()
            .when('reportType', {
                is: (reportType) => reportType !== ReportType.DIAGNOSTICS,
                then: Yup.mixed().required()
            })
    })

    const onSubmit = async (values, actions) => {
        let output: any[][] = []
        // Очистка имеющихся предупреждений
        setMessage()

        if (msisdnRequired(values.reportType)) {
            for (const key in msisdnList) {
                switch (values.reportType) {
                    case ReportType.INTERNET:
                        output.push(await getReportInternet({
                            msisdn: msisdnList[key],
                            date_from: startDate,
                            date_to: endDate,
                            errorHandler: setMessage
                        }))
                        break
                    case ReportType.SMS:
                        output.push(await getReportSMS({
                            msisdn: msisdnList[key],
                            date_from: startDate,
                            date_to: endDate,
                            errorHandler: setMessage
                        }))
                        break
                    case ReportType.STATUS:
                        const reportStatus = await getReportStatus({
                            msisdn: msisdnList[key],
                            errorHandler: setMessage
                        })
                        if (reportStatus.length !== 0) {
                            const simCardInfo = await getSimCardInfo({
                                msisdn: msisdnList[key],
                                errorHandler: setMessage
                            })
                            if (simCardInfo.length !== 0) {
                                const reportStatusExtended = reportStatus.map((item) => {
                                    return {
                                        ...item,
                                        msisdn: simCardInfo[0].msisdn,
                                        last_activity_ts: simCardInfo[0].last_activity_ts
                                    } as ReportStatusExtended
                                })
                                output.push(reportStatusExtended)
                            }
                        }
                        break
                }
                setPbCounter(Number(key) + 1)
            }
        } else {
            output.push(await getReportDiagnostics({
                date_from: startDate,
                date_to: endDate,
                errorHandler: setMessage
            }))
        }

        output = output.filter(item => item.length)
        if (output.length === 0) {
            setMessage("По запрошенным параметрам данные отсутствуют.")
        } else {
            ExcelReportHelper.buildReport({
                reportType: values.reportType,
                startDate: startDate,
                endDate: endDate,
                rows: output
            })
        }

        refreshPage(new Date())
    }

    /**
     * Сброс состояния прогресс-бара
     */
    const resetProgressBar = () => {
        if (pbCounter !== 0) {
            setPbCounter(0)
        }
    }

    /**
     * После разбора файла в прогресс-баре отображается кол-во загруженных номеров
     */
    const onFileSelect = (extractedMsisdnList: number[]) => {
        setMsisdnList(extractedMsisdnList)
        resetProgressBar()
    }

    const onStartDateChange = (startDate: Date | null) => {
        // Если одно из полей пустое
        if (!startDate || !endDate) {
            setAlertStringMessage('Начало и конец периода должны быть заполнены!')
            setDateRangeValid(false)
        } else if (startDate.getMonth() !== new Date(Date.parse(endDate)).getMonth()) {
            setAlertStringMessage('Даты должны находиться в пределах одного календарного месяца!')
            setDateRangeValid(false)
        } else {
            alertStringMessage && setAlertStringMessage('')
            setDateRangeValid(true)
        }

        setStartDate(startDate ? formatRFC3339(startDate) : firstDayOfMonth)
        resetProgressBar()
        setMessage()
    }

    const onEndDateChange = (endDate: Date | null) => {
        // Если одно из полей пустое
        if (!startDate || !endDate) {
            setAlertStringMessage('Начало и конец периода должны быть заполнены!')
            setDateRangeValid(false)
        } else if (new Date(Date.parse(startDate)).getMonth() !== endDate.getMonth()) {
            setAlertStringMessage('Даты должны находиться в пределах одного календарного месяца!')
            setDateRangeValid(false)
        } else {
            alertStringMessage && setAlertStringMessage('')
            setDateRangeValid(true)
        }

        setEndDate(endDate ? formatRFC3339(endDate) : currentDay)
        resetProgressBar()
        setMessage()
    }

    /**
     * Проверка типа отчёта на необходимость указания временного диапазона для его составления
     *
     * @param reportType
     */
    const dateRangeRequired = (reportType: ReportType): boolean => reportType !== ReportType.STATUS

    /**
     * Проверка типа отчёта на необходимость загрузки файла с номерами для его составления
     *
     * @param reportType
     */
    const msisdnRequired = (reportType: ReportType): boolean => reportType !== ReportType.DIAGNOSTICS

    /**
     * Проверка валидности формы для активации/деактивации кнопки создания отчёта
     * @param props
     */
    const formIsValid = (props: FormikProps<any>) => {
        // Отключаем проверку периода детализации, когда он не нужен
        const dateRangeIsValid = dateRangeValid || !dateRangeRequired(props.values.reportType)
        // Отключаем проверку наличия файла, когда он не нужен
        const fileInputIsValid = !props.errors.file || !msisdnRequired(props.values.reportType)
        return dateRangeIsValid && fileInputIsValid
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Отчёты</PageTitle>

            <div className="card">
                <SectionHeader/>

                <Alert message={getMessages()} additionalClassNames="mb-0 mt-5 mx-5"/>

                <div className="card-body">
                    <Formik
                        initialValues={{
                            reportType: ReportType.INTERNET,
                            file: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        validateOnMount
                        validateOnChange
                    >
                        {(props: FormikProps<any>) => {

                            // Реинициализация формы при смене типа отчёта
                            if (props.values.reportType !== currentReportType.current) {
                                currentReportType.current = props.values.reportType

                                // Сброс инпута типа "file"
                                props.setFieldValue('file', '');

                                // Этот кусок кода необходим для очистки названия файла после сброса инпута для загрузки файла,
                                // так как никакими средствами React и Formik оно не очищается до конца
                                const domInputElement = (document.querySelector('input[type="file"]') as HTMLInputElement)
                                if (domInputElement) {
                                    domInputElement.value = ''
                                }

                                // Сброс периода отчёта
                                setStartDate(firstDayOfMonth)
                                setEndDate(currentDay)

                                // Сброс сообщений об ошибках, иначе при возникновении аналогичной повторной ошибки оно закешируется и не будет показано
                                setMessage('');

                                // Сброс счётчика обработанных номеров
                                resetProgressBar()

                                setMsisdnList([])
                            }

                            return (
                                <Form className='form w-350px'>
                                    <div className='fv-row mb-10'>
                                        <label className='form-label fs-6 fw-bolder text-dark px-1'>Тип отчёта: </label>
                                        <Field as="select" name="reportType" className={'form-select form-select-solid'}>
                                            {ReportType.getLabelList().map((item, index) =>
                                                <option key={index} value={item}>
                                                    {ReportType.getLabel(item)}
                                                </option>
                                            )}
                                        </Field>
                                    </div>

                                    {dateRangeRequired(props.values.reportType) &&
                                    <div className='fv-row mb-10'>
                                        <label className='form-label fs-6 fw-bolder text-dark px-1'>
                                            Период детализации (в пределах одного месяца):
                                        </label>
                                        {alertStringMessage && <AlertString additionalClassNames={"mb-5 mt-0 mx-3"} message={alertStringMessage}/>}
                                        <div className="d-flex align-items-center justify-content-between w-700px mb-5">
                                            <DateRangePicker
                                                locale={ru}
                                                startDate={new Date(Date.parse(startDate)) || undefined}
                                                endDate={new Date(Date.parse(endDate)) || undefined}
                                                onStartDateChange={onStartDateChange}
                                                onEndDateChange={onEndDateChange}
                                                format={FORMAT}
                                            >
                                                {({startDateInputProps, endDateInputProps, focus}) => (
                                                    <div className='date-range'>
                                                        <input
                                                            className={'form-control form-control-solid nice-dates-report input' + (focus === START_DATE ? ' -focused' : '')}
                                                            {...startDateInputProps}
                                                            placeholder='От'
                                                        />
                                                        <input
                                                            className={'form-control form-control-solid nice-dates-report input' + (focus === END_DATE ? ' -focused' : '')}
                                                            {...endDateInputProps}
                                                            placeholder='До'
                                                        />
                                                    </div>
                                                )}
                                            </DateRangePicker>
                                        </div>
                                    </div>
                                    }

                                    {msisdnRequired(props.values.reportType) &&
                                    <div className='fv-row mb-10'>
                                        <label className='form-label fs-6 fw-bolder text-dark px-1'>
                                            Файл с номерами:
                                            <Tooltip
                                                placement={'top'}
                                                TransitionComponent={Zoom}
                                                title={(
                                                    <div className={'tooltipPopup'}>
                                                        <ul>
                                                            <li> В формате CSV - через запятую</li>
                                                            <li> В формате Excel - построчно</li>
                                                        </ul>
                                                    </div>
                                                )}
                                            >
                                        <span className="fs-6">
                                            <KTSVG path='/media/icons/duotune/general/gen044.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                                        </span>
                                            </Tooltip>
                                        </label>

                                        <MsisdnListFile onFileSelect={onFileSelect}/>

                                        {msisdnList.length !== 0 &&
                                        <div className="progressBar">
                                            {pbCounter === 0 &&
                                            <ProgressBar className="initialProgressBar" now={100} label={`Загружено номеров: ${msisdnList.length}`}/>
                                            }
                                            {pbCounter !== 0 && pbCounter !== msisdnList.length &&
                                            <ProgressBar animated className="animatedProgressBar" style={{}} now={(pbCounter / msisdnList.length) * 100} label={`${pbCounter}`}/>
                                            }
                                            {pbCounter === msisdnList.length && msisdnList.length !== 0 &&
                                            <ProgressBar className="finalProgressBar" now={100} label={`${pbCounter}`}/>
                                            }
                                        </div>
                                        }

                                    </div>
                                    }

                                    <div className={'d-flex justify-content-end'}>
                                        <Button type={'submit'} disabled={!formIsValid(props)}> Сформировать отчёт</Button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}
