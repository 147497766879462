import {textToHtml} from "../../../utils";
import {AlertProps} from "./index";

/**
 * Сообщение об ошибке без возможности его удаления в виде строки
 */
export const AlertString = ({message, additionalClassNames = 'mb-0 mt-5'}: AlertProps) =>
    message.trim() !== ''
        ? <div className={`error-color ${additionalClassNames}`}>
            <span>{textToHtml(message)}</span>
        </div>
        : <></>

export default AlertString;
