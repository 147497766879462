import axios from "axios";
import {API_URL} from "../../../../config";
import {ReportInternet} from "../models/ReportInternet";
import {ReportSms} from "../models/ReportSms";
import {ReportStatus} from "../models/ReportStatus";
import {ReportDiagnostics} from "../models/ReportDiagnostics";
import {fetchSimCardInfo} from "../../simcard/api";
import {SimCard} from "../../simcard/models/Models";
import {ReportRequestProps, ReportRequestPropsWithErrorHandling} from "../models/CommonTypes";
import {CommonError, ErrorResponse, GenericResponse, GenericSlicedResponse, OkResponse, processResponse} from "../../../api/CommonApi";

/** Отчёты */
export const REPORT_INTERNET = `${API_URL}/m2m/api/detalization/detail_report_inet.cgi`
export const REPORT_SMS = `${API_URL}/m2m/api/detalization/detail_report_sms.cgi`
export const REPORT_STATUS = `${API_URL}/m2m/api/sim_cards/status_hist.cgi`
export const REPORT_DIAGNOSTICS = `${API_URL}/m2m/api/events/diagnostic_stat.cgi`

/**
 * Универсальная обработка ответа для запросов с указанием номеров телефонов
 */
const processReportResponse = <T>(response: any, errorHandler: (string) => void) => {
    switch (response.status) {
        case "ok":
            return response.data.data.items as T[]
        case "error":
            if (response.data.error === 'permission_denied') {
                errorHandler(`У вас нет доступа к информации по некоторым номерам!`)
            }
            return []
        default:
            console.log(response)
            errorHandler("Сервер не отвечает. Попробуйте обновить страницу.")
            return [];
    }
}

/**
 * Потребление интернет трафика
 */
function fetchReportInternet({msisdn, date_from, date_to}: ReportRequestProps): Promise<OkResponse<GenericSlicedResponse<ReportInternet>> | ErrorResponse<CommonError>> {
    const response = axios.post(REPORT_INTERNET, {msisdn, date_from, date_to})
    return processResponse<GenericSlicedResponse<ReportInternet>, CommonError>(response)
}

export const getReportInternet = async ({msisdn, date_from, date_to, errorHandler}: ReportRequestPropsWithErrorHandling) => {
    const response = await fetchReportInternet({msisdn, date_from, date_to})
    return processReportResponse<ReportInternet>(response, errorHandler)
}

/**
 * Потребление SMS трафика
 */
function fetchReportSMS({msisdn, date_from, date_to}: ReportRequestProps): Promise<OkResponse<GenericSlicedResponse<ReportSms>> | ErrorResponse<CommonError>> {
    const response = axios.post(REPORT_SMS, {msisdn, date_from, date_to})
    return processResponse<GenericSlicedResponse<ReportSms>, CommonError>(response)
}

export const getReportSMS = async ({msisdn, date_from, date_to, errorHandler}: ReportRequestPropsWithErrorHandling) => {
    const response = await fetchReportSMS({msisdn, date_from, date_to})
    return processReportResponse<ReportSms>(response, errorHandler)
}

/**
 * Отчёт по статусам
 */
function fetchReportStatus({msisdn}: ReportRequestProps): Promise<OkResponse<GenericResponse<ReportStatus>> | ErrorResponse<CommonError>> {
    const response = axios.post(REPORT_STATUS, {msisdn})
    return processResponse<GenericResponse<ReportStatus>, CommonError>(response)
}

export const getReportStatus = async ({msisdn, errorHandler}: ReportRequestPropsWithErrorHandling) => {
    const response = await fetchReportStatus({msisdn})
    return processReportResponse<ReportStatus>(response, errorHandler)
}

export const getSimCardInfo = async ({msisdn, errorHandler}: ReportRequestPropsWithErrorHandling) => {
    const response = await fetchSimCardInfo({msisdn: msisdn!.toString()})
    return processReportResponse<SimCard>(response, errorHandler)
}

/**
 * Отчёт по инструментам диагностики
 */
function fetchReportDiagnostics({date_from, date_to}: ReportRequestProps): Promise<OkResponse<GenericResponse<ReportDiagnostics>> | ErrorResponse<CommonError>> {
    const response = axios.post(REPORT_DIAGNOSTICS, {date_from, date_to})
    return processResponse<GenericResponse<ReportDiagnostics>, CommonError>(response)
}

export const getReportDiagnostics = async ({date_from, date_to, errorHandler}: ReportRequestPropsWithErrorHandling) => {
    const response = await fetchReportDiagnostics({date_from, date_to})
    switch (response.status) {
        case "ok":
            return response.data.data.items as ReportDiagnostics[]
        default:
            console.log(response)
            errorHandler("Сервер не отвечает. Попробуйте обновить страницу.")
            return [];
    }
}
