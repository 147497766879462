import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {Routes} from './routing/Routes'
import AuthInit from './modules/auth/redux/AuthInit'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'

const App: React.FC<{ basename: string }> = ({basename}) => {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <BrowserRouter basename={basename}>
                <LayoutProvider>
                    <AuthInit>
                        <Routes/>
                    </AuthInit>
                </LayoutProvider>
            </BrowserRouter>
        </Suspense>
    )
}

export {App}
