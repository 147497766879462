import {Modal} from "react-bootstrap-v5";
import React, {useEffect, useRef, useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {ModalProps} from "../../models/Modal";
import Alert from "../../../../components/alerts/Alert";
import {editContactAssignments, listContact, listContactAssignments} from "../../api";
import {Action, ActionOrNull, Scope, ScopeOrNull, SimCard, SimCardContact} from "../../models/Models";
import {AlertType} from "../../../../components/alerts";

type ContactId = number;

interface SimCardContactWithScopedItems extends SimCardContact {
    scope?: ScopeOrNull
}

interface ISetContactAssignmentsForm extends ModalProps {
    selectedRows: Partial<SimCard>[]
}

/**
 * Форма редактирования привязок контактов к номерам
 */
export const SetContactAssignmentsForm = ({selectedRows, show, handleClose}: ISetContactAssignmentsForm) => {
    const [, refresh] = useState(0)
    const [message, setMessage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const diffMap = useRef<Map<number, ActionOrNull>>(new Map())
    const [isSubmitted, setIsSubmitted] = useState(false)
    const initialContactList = useRef<SimCardContactWithScopedItems[]>([])
    const [updatedContactList, setUpdatedContactList] = useState<SimCardContactWithScopedItems[]>()

    /**
     * 1. Добавление в контакты свойства scope согласно привязкам контактов к номерам при первоначальной загрузке
     * 2. Заполнение референсного списка контактов initialContactList и создание нулевой карты различий diffMap для сравнения с обновляемым списком контактов updatedContactList
     */
    useEffect(() => {
            /**
             * Список контактов для номера
             */
            const getContactList = async (msisdn: string): Promise<SimCardContact[] | null> => {
                const response = await listContactAssignments(Number(msisdn))
                switch (response.status) {
                    case "ok":
                        return response.data.data.items as SimCardContact[]
                    default:
                        console.log(response)
                        setMessage(`Сервер не смог получить список контактов для номера ${msisdn}. Попробуйте обновить страницу.`)
                        return null;
                }
            }

            /**
             * Карта степени привязки контактов к номерам
             * Расшифровка значений привязок аналогична описанию к методу getMsisdnToGroupAssignmentsScopeMap
             */
            const getMsisdnToContactAssignmentsScopeMap = async () => {
                // Подсчитываем кол-во номеров для каждого контакта
                const getContactIdCounterMap = async (): Promise<Map<ContactId, number>> => {
                    const contactIdCounterMap = new Map<ContactId, number>()
                    for (const item of selectedRows) {
                        const contacts = await getContactList(item.msisdn!!.toString())
                        if (contacts && contacts.length !== 0) {
                            for (const contact of contacts) {
                                if (contactIdCounterMap.get(contact.id) === undefined) {
                                    contactIdCounterMap.set(contact.id, 1)
                                } else {
                                    contactIdCounterMap.set(contact.id, contactIdCounterMap.get(contact.id)! + 1)
                                }
                            }
                        }
                    }
                    return contactIdCounterMap
                }

                // Проставляем статусы в зависимости от подсчитанного кол-ва номеров
                const contactIdCounterMap = await getContactIdCounterMap()
                const countAssignmentsScopeMap = new Map<ContactId, ScopeOrNull>()
                for (const [contactId] of contactIdCounterMap) {
                    countAssignmentsScopeMap.set(contactId, contactIdCounterMap.get(contactId) === selectedRows.length ? Scope.FULL : Scope.PARTIAL)
                }
                return countAssignmentsScopeMap
            }

            const getResponse = async () => {
                const response = await listContact()
                const scoreMap = await getMsisdnToContactAssignmentsScopeMap()

                switch (response.status) {
                    case "ok":
                        const contactList: SimCardContact[] = response.data.data.items
                        if (contactList) {
                            if (contactList.length !== 0) {
                                setUpdatedContactList(
                                    contactList.map((item, index) => {
                                        diffMap.current.set(item.id, null)
                                        initialContactList.current[index] = {
                                            ...item,
                                            scope: scoreMap.get(item.id) || null
                                        }
                                        let clone: SimCardContactWithScopedItems = {id: 0, name: '', email: ''}
                                        Object.assign(clone, initialContactList.current[index])
                                        return clone
                                    })
                                )
                            } else {
                                // Для вывода сообщения об отсутствии контактов лишь после того, как отработает запрос
                                setUpdatedContactList([])
                            }
                        }
                        return true
                    default:
                        console.log(response)
                        setMessage("Сервер сообщил об ошибке. Попробуйте выполнить операцию позднее.")
                        return false
                }
            }

            getResponse()
        },
        [selectedRows]
    )

    /**
     * Карта изменений [[contactId => Action]]
     */
    const buildDiffMap = () => {
        let action: ActionOrNull = null
        updatedContactList?.forEach((item, index) => {
            switch (initialContactList.current[index].scope) {
                case Scope.FULL:
                    action = item.scope === Scope.FULL ? null : Action.DELETE
                    break
                case Scope.PARTIAL:
                    if (item.scope === Scope.PARTIAL) {
                        action = null
                    } else {
                        action = item.scope === Scope.FULL ? Action.ADD : Action.DELETE
                    }
                    break
                default:
                    action = item.scope === Scope.FULL ? Action.ADD : null
            }
            diffMap.current.set(item.id, action)
        })
    }

    useEffect(() => {
        const updateContactAssignments = () => {
            // Список ID контактов, по которым имеются изменения
            const diff = Array.from(diffMap.current)
                .filter((item) => item[1] !== null)

            if (diff.length !== 0) {
                const msisdnList = selectedRows.map((simCard) => simCard.msisdn!.toString())
                let countDown = diff.length
                updatedContactList?.forEach(async (item) => {
                    if (diffMap.current.get(item.id) !== null) {
                        const response = await editContactAssignments(item.id, diffMap.current.get(item.id)!, msisdnList)
                        switch (response.status) {
                            case "ok":
                                if (--countDown === 0) {
                                    handleClose()
                                }
                                return
                            case "error":
                                console.log(response)
                                setIsSubmitted(false)
                                setMessage("Сервер не смог обработать запрос. Попробуйте выполнить сохранение привязок к контактам позднее.")
                                return false
                        }
                    }
                })
            }
        }

        isSubmitted && updateContactAssignments()
    }, [isSubmitted])   // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Вносит изменения в набор состояний контактов согласно нажатию по кнопке контактов и строит карту изменений по сравнению с первоначальным состоянием.
     * При наличии наполовину активных кнопок ('partial') и нажатию на одной из них возврат в состояние 'partial' будет невозможен.
     */
    const onContactSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetContact = updatedContactList?.find((item) => item.id.toString() === event.currentTarget.value) as SimCardContactWithScopedItems
        // При выборе scope возможны лишь 2 варианта - "все номера привязаны к контакту" или "ни один не привязан".
        if (event.currentTarget.checked) {
            targetContact.scope = Scope.FULL
        } else {
            targetContact.scope = null
        }
        buildDiffMap()
        setButtonStatus()
        refresh(Math.random())
    }

    /**
     * Дизейблим кнопку сохранения при отсутствии изменений
     */
    const setButtonStatus = () => {
        const diff = Array.from(diffMap.current.values()).filter((item) => item !== null).length
        setDisabled(diff === 0)
    }

    /**
     * Стилизация кнопок контактов в зависимости от состояния параметра scope
     */
    const getButtonStyle = (index: number) => {
        switch (updatedContactList!![index].scope) {
            case 'full':
                return ' border border-active active btn-active-light-primary'
            case 'partial':
                return ' border-dashed border-dashed-active active btn-active-light-info'
            default:
                return ''
        }
    }

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-header py-4'>
                <h3>Привязки контактов к номерам</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose()}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body  pt-5 px-10 center'>
                {updatedContactList && updatedContactList.length !== 0 &&
                <>
                    <div className="d-flex flex-column mb-5">
                        {updatedContactList?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <input
                                        type='checkbox'
                                        className='btn-check border-'
                                        name='activityStatus'
                                        id={'_' + index}
                                        value={item.id}
                                        onFocus={(event) => event.stopPropagation()}
                                        onChange={onContactSelect}
                                        checked={updatedContactList[index].scope === 'full'}
                                    />
                                    <label
                                        className={`btn btn-lg btn-outline btn-bg-light btn-color-gray-600 px-4 m-2 ${getButtonStyle(index)}`}
                                        htmlFor={'_' + index}
                                    >
                                        <span className='text-gray-800 fw-bold'>{item.name}</span>
                                    </label>
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary" onClick={() => setIsSubmitted(true)} disabled={disabled}>Сохранить</button>
                    </div>
                </>
                }

                {updatedContactList && updatedContactList.length === 0 &&
                    <Alert
                        type={AlertType.INFO}
                        message={'Вы пока не создали ни одного контакта. Это можно сделать в разделе <a href="/simcard/contacts">Контакты</a>'}
                    />
                }

                <div>
                    {message && <Alert message={message}/>}
                </div>
            </div>
        </Modal>
    )
}
