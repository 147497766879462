import {createTheme} from "@mui/material";
import {SimCardImeiHistoryItem} from "../../../../models/Models";
import {GridRenderCellParams} from "@mui/x-data-grid";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = () => [
    {
        field: 'imei',
        headerName: 'IMEI',
        width: 150
    },
    {
        field: 'date_start',
        headerName: 'Дата первой и последней записи',
        width: 250,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => params.row.date_start + ' - ' + params.row.date_end
    },
]

export const getRows = (simCardImeiHistory: SimCardImeiHistoryItem[] | undefined): any => simCardImeiHistory?.map((item, index) =>
    ({
        id: index,
        imei: item.imei,
        date_start: new Date(item.date_start).toLocaleDateString(),
        date_end: new Date(item.date_end).toLocaleDateString()
    })
)
