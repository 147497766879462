import {setStatus} from "../../../api";
import React, {useEffect, useState} from "react";
import {ExtendedModalProps} from "../../../models/Modal";
import {Modal} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {SimCardStatus} from "../../../models/SimCardStatus";
import Alert from "../../../../../components/alerts/Alert";
import {AlertType} from "../../../../../components/alerts";

interface ISetStatusFormProps extends ExtendedModalProps {
    msisdn: string | undefined
    status: SimCardStatus | undefined
    statusChangeAllowed: boolean
}

/**
 * Модальное окно для установки/снятия блокировки номера (SIM-карты)
 */
export const SetSingleStatusForm = ({msisdn, status, statusChangeAllowed, show, handleClose}: ISetStatusFormProps) => {
    const [message, setMessage] = useState('')
    const [activityStatus, setActivityStatus] = useState<SimCardStatus | undefined>(status)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(false)

    useEffect(() => {
        /**
         * Установка статуса
         */
        const setSimCardStatus = async (msisdn: string, status: SimCardStatus | undefined) => {
            const response = await setStatus(Number(msisdn), status || SimCardStatus.BLOCKED)
            switch (response.status) {
                case "ok":
                    return handleClose({isSubmitted})
                default:
                    setMessage("Не удалось изменить статус активности. Попробуйте обновить страницу и выполнить эту операцию повторно.")
                    return <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon svg-icon-1 svg-icon-danger me-3'/>
            }
        }

        isSubmitted && setSimCardStatus(msisdn!!, activityStatus)
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {
        setActivityStatus(status === SimCardStatus.ACTIVE ? SimCardStatus.BLOCKED : SimCardStatus.ACTIVE)
        setIsSubmitted(true)
        setSubmitDisabled(true)
    }

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
        setSubmitDisabled(false)
    }

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted})
    }

    const simCardIsActive = status === SimCardStatus.ACTIVE

    return (
        <Modal
            className='modal fade'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-300px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-header py-4'>
                <h3>{simCardIsActive ? 'Блокировка' : 'Разблокировка'} номера</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-10 pb-0'>
                {statusChangeAllowed
                    ? <>
                        <p>Подтвердите {simCardIsActive ? 'блокировку' : 'разблокировку'} номера</p>
                        {simCardIsActive &&
                            <Alert
                            type={AlertType.INFO}
                            additionalClassNames='mb-5 mt-0'
                            message='Максимальный срок блокировки номера — 270 дней'
                            />
                        }
                        <div className="d-flex justify-content-evenly align-items-center mb-5">
                            <div>
                                <button className="btn btn-primary px-4 mx-3" onClick={submit} disabled={submitDisabled}>
                                    {simCardIsActive ? 'Блокировать' : 'Разблокировать'}
                                </button>
                            </div>
                        </div>
                    </>
                    : <Alert
                        type={AlertType.WARNING}
                        additionalClassNames='mb-5 mt-0'
                        message='Для блокировки/разблокировки данного номера необходимо обратиться в контактный центр по номеру 0522'
                    />
                }
                {message && <Alert message={message}/>}
            </div>
        </Modal>
    )
}
