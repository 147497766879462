import {useState} from "react";
import {SimCard} from "../../models/Models";
import {ITopSliderMenuItemProps} from "../topSliderMenu";
import {SetContactAssignmentsForm} from "./SetContactAssignmentsForm";
import {Rest} from "../../../../models/common";

export interface ISetContactAssignmentsProps extends ITopSliderMenuItemProps, Rest {
    selectedRows: Partial<SimCard>[]
}

/**
 * Управление привязками контактов к номерам
 */
export const SetContactAssignments = ({label, selectedRows, ...rest}: ISetContactAssignmentsProps) => {
    const [show, setShow] = useState(false)
    const closeModal = () => setShow(false)

    return (
        <>
            <button
                type='button'
                className={rest.className || ""}
                onClick={() => setShow(true)}
            >
                {label}
            </button>
            {show &&
            <SetContactAssignmentsForm
                selectedRows={selectedRows}
                show={show}
                handleClose={closeModal}
            />
            }
        </>
    )
}
