/* eslint-disable jsx-a11y/anchor-is-valid */
import {Redirect, Route, Switch} from "react-router-dom";
import {SimCardGroupListPage} from "./SimCardGroupListPage";

export function SimCardGroupListWrapper() {
    return (
        <Switch>
            <Route exact path='/simcard/groups'>
                <SimCardGroupListPage/>
            </Route>
            <Redirect from='/simcard/groups/' to='/simcard/groups'/>
        </Switch>
    )
}
