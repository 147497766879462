import {KTSVG} from "../../../../_metronic/helpers";
import {createTheme, Tooltip, Zoom} from "@mui/material";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {formatListHTML, formatPhoneNumberFrom10Digits} from "../../../helpers/Formatter";
import {InternalEventType} from "../models/InternalEvent";
import {SendDays} from "../models/EventSubscription";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.05rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'},
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'unset',
                    lineHeight: '1.2'
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = (): GridColDef[] => [
    {
        field: 'name',
        headerName: 'Наименование',
        width: 250
    },
    {
        field: 'notification_method',
        headerName: 'Уведомления',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params) => {
            let sms: JSX.Element | null = null
            let email: JSX.Element | null = null
            if (params.row.sms.send_sms) {
                sms = (
                    <Tooltip
                        placement={'top'}
                        TransitionComponent={Zoom}
                        title={(
                            <>
                                <div style={{fontSize: '1.1rem'}}>
                                    Уведомления по SMS
                                </div>
                                <hr style={{margin: '0'}} />
                                <div style={{fontSize: '1rem', fontWeight: 'normal'}}>
                                    Интервал отправки: {params.row.sms.periodicity} мин.
                                </div>
                                <div style={{fontSize: '1rem'}}>
                                    Дни отправки: {params.row.sms.send_days === SendDays.WEEKDAYS ? 'по будням' : 'ежедневно'}
                                </div>
                                <div style={{fontSize: '1rem'}}>
                                    Телефон: {formatPhoneNumberFrom10Digits(params.row.sms.phones)}
                                </div>
                            </>
                        )}
                    >
                        <span className='cursor-pointer'>
                        <KTSVG
                            path='/media/icons/duotune/communication/com012.svg'
                            className='svg-icon svg-icon-2tx'
                        />
                        </span>
                    </Tooltip>
                )
            }
            if (params.row.email.send_email) {
                email = (
                    <Tooltip
                        placement={'top'}
                        TransitionComponent={Zoom}
                        title={(
                            <>
                                <div style={{fontSize: '1.1rem'}}>
                                    Уведомления на E-mail
                                </div>
                                <hr style={{margin: '0'}} />
                                <div style={{fontSize: '1rem', fontWeight: 'normal'}}>
                                    Интервал отправки: {params.row.email.periodicity} мин.
                                </div>
                                <div style={{fontSize: '1rem'}}>
                                    Дни отправки: {params.row.email.send_days === SendDays.WEEKDAYS ? 'по будням' : 'ежедневно'}
                                </div>
                                <div style={{fontSize: '1rem'}}>
                                    E-mail: {params.row.email.emails}
                                </div>
                            </>
                        )}
                    >
                        <span className='cursor-pointer'>
                        <KTSVG
                            path='/media/icons/duotune/communication/com011.svg'
                            className='svg-icon svg-icon-2tx'
                        />
                        </span>
                    </Tooltip>
                )
                if (sms) {
                    return <>{sms}
                    {<KTSVG
                        path='/media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon svg-icon-1tx'
                    />
                    } {email}</>
                } else {
                    return email
                }
            }
            return sms
        }
    },
    {
        field: 'events',
        headerName: 'Типы событий',
        sortable: false,
        width: 250,
        renderCell: (params: GridRenderCellParams) => formatListHTML(params.row.events.map((item: InternalEventType) => InternalEventType.getLabel(item))),
    }
]
