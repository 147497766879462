import {createTheme} from "@mui/material";
import {SimCardBalance} from "../../models/Models";

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = () => [
    {
        field: 'date',
        headerName: 'Дата изменения',
        width: 160
    },
    {
        field: 'value',
        headerName: 'Значение баланса',
        width: 170,
    },
]

export const getRows = (simCardBalanceHistory: SimCardBalance[] | undefined): any => simCardBalanceHistory?.map((simCardBalance, index) =>
    ({
        id: index,
        value: +simCardBalance.value,
        date: new Date(simCardBalance.date).toLocaleDateString()
    })
)
