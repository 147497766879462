/**
 * Запрос детализации по номеру для вывода в интерфейсе
 */
import {SlicedRequest, SlicedResponse} from "../../../models/common";

export interface BillItemizationRequestProps extends SlicedRequest{
    /**
     * Номер в десятизначном формате, по которому запрашивается детализация.
     */
    msisdn: number
    /**
     * Время, начиная с которого запрашивается детализация (YYYY-mm-dd H:i:s).
     * По умолчанию - начало текущего месяца.
     */
    date_from: string
    /**
     * Время, до которого запрашивается детализация (YYYY-mm-dd H:i:s).
     * По умолчанию - текущее время.
     */
    date_to: string
}

/**
 * Запрос расширенной детализации расходов по номеру для отпрвки на e-mail
 */
export interface BillItemizationExtendedRequestProps {
    /**
     * Номер в десятизначном формате, по которому запрашивается детализация.
     */
    msisdn: number
    /**
     * Время, начиная с которого запрашивается детализация (YYYYmm).
     * По умолчанию - начало текущего месяца.
     */
    period: string
    email: string
}

export interface BillItemizationResponse extends SlicedResponse{
    data: {
        items: BillItemizationItem[]
    }
    status: "ok" | "error"
}

export interface BillItemizationItem {
    connection_type: BillItemizationConnectionType
    value: number
    cost: number
}

export interface BillItemizationConnectionType {
    id: BillItemizationConnectionTypeItem
    name: string
}

/**
 * Основные статьи расходов согласно o_conn_type_ex
 */
export enum BillItemizationConnectionTypeItem {
    LONG_DISTANCE_CALLS = 1,
    LOCAL_CALLS = 2,
    SMS_OR_MMS = 3,
    INTERNATIONAL_CALLS = 4,
    GPRS = 5,
    ROAMING_CALLS = 6,
    ROAMING_GPRS = 7,
    OUTGOING_CALLS = 8,
    SMS = 10,
}

export const BillItemizationConnectionTypeItemLabels = new Map([
    [BillItemizationConnectionTypeItem.LONG_DISTANCE_CALLS, "Междугородние звонки"],
    [BillItemizationConnectionTypeItem.LOCAL_CALLS, "Местные звонки"],
    [BillItemizationConnectionTypeItem.SMS_OR_MMS, "SMS"],
    [BillItemizationConnectionTypeItem.INTERNATIONAL_CALLS, "Международные звонки"],
    [BillItemizationConnectionTypeItem.GPRS, "Передача данных"],
    [BillItemizationConnectionTypeItem.ROAMING_CALLS, "Роуминговые звонки"],
    [BillItemizationConnectionTypeItem.ROAMING_GPRS, "Передача данных в роуминге"],
    [BillItemizationConnectionTypeItem.OUTGOING_CALLS, "Исходящие звонки"],
    [BillItemizationConnectionTypeItem.SMS, "SMS"],
])

export const BillItemizationConnectionTypeItemMeasure = new Map([
    [BillItemizationConnectionTypeItem.LONG_DISTANCE_CALLS, "мин."],
    [BillItemizationConnectionTypeItem.LOCAL_CALLS, "мин."],
    [BillItemizationConnectionTypeItem.SMS_OR_MMS, "шт."],
    [BillItemizationConnectionTypeItem.INTERNATIONAL_CALLS, "мин."],
    [BillItemizationConnectionTypeItem.GPRS, "Мб"],
    [BillItemizationConnectionTypeItem.ROAMING_CALLS, "мин."],
    [BillItemizationConnectionTypeItem.ROAMING_GPRS, "Мб"],
    [BillItemizationConnectionTypeItem.OUTGOING_CALLS, "мин."],
    [BillItemizationConnectionTypeItem.SMS, "шт."],
])
