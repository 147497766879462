import {Modal} from "react-bootstrap-v5";
import React, {useState} from "react";
import clsx from "clsx";
import * as Yup from "yup";
import {useFormik} from "formik";
import {replaceSim} from "../../../api";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {ModalProps} from "../../../models/Modal";
import Alert from "../../../../../components/alerts/Alert";
import {AlertType} from "../../../../../components/alerts";

interface IReplaceSimFormProps extends ModalProps {
    msisdn: number | undefined
}

const schema = Yup.object().shape({
    iccid: Yup.string()
        .required("Введите корректный идентификатор SIM-карты")
})

/**
 * Форма для отправки заявки на замену номера SIM-карты
 */
export const ReplaceSimForm = ({msisdn, show, handleClose}: IReplaceSimFormProps) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: {iccid: ''},
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            setLoading(true)
            const response = await replaceSim(msisdn!!, values.iccid)
            switch (response.status) {
                case "ok":
                    setLoading(false)
                    setSuccessMessage("Заявка на замену SIM-карты успешно отправлена. Эта операция занимает от 5 минут до часа.")
                    return true
                default:
                    setLoading(false)
                    console.log(response)
                    setErrorMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false;
            }
        },
    })

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setErrorMessage('')
        setSuccessMessage('')
        setLoading(false)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-400px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-content'>
                <div className='modal-header py-4'>
                    <h3>Замена SIM-карты</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose()}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body pt-5 px-9 center'>
                    <div className="d-flex justify-content-between mb-5">
                        Введите новый номер SIM-карты
                    </div>

                    <form
                        className='form'
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <div className='d-flex justify-content-around align-items-center'>
                            <div className='fv-row addImei' style={{marginRight: "0.75rem"}}>
                                <input
                                    autoComplete='off'
                                    {...formik.getFieldProps('iccid')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.iccid && formik.errors.iccid
                                        },
                                        {
                                            'is-valid': formik.touched.iccid && !formik.errors.iccid,
                                        }
                                    )}
                                />
                            </div>

                            <div className='text-center'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{width: '106px'}}
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                                >
                                    {!loading && <span className='indicator-label'>Отправить</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'> </span>
                                    </span>
                                    )}
                                </button>

                            </div>
                        </div>
                        {formik.touched.iccid && formik.errors.iccid && (
                            <div className='fv-plugins-message-container px-3'>
                                <div className='fv-help-block error-color'>
                                    <span role='alert'>{formik.errors.iccid}</span>
                                </div>
                            </div>
                        )}

                    </form>

                    <div>
                        {errorMessage && <Alert message={errorMessage}/>}
                        {successMessage && <Alert type={AlertType.SUCCESS} message={successMessage}/>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
