import {SimCardGroup} from "../models/Models";
import {createTheme} from "@mui/material";
import type { } from '@mui/x-data-grid/themeAugmentation';

export const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '1.075rem',
                    border: 'none',
                    classes: "text-gray-600"
                },
                cell: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                columnHeaderTitle: {
                    color: '#7E8299' // "text-gray-800",
                },
                columnHeader: {
                    ":focus": {outline: 'none'},
                    ":focus-within": {outline: 'none'}
                },
                iconSeparator: {
                    display: 'none'
                },
                columnHeaderTitleContainer: {
                    padding: 0
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#B5B5C3'
                }
            }
        }
    },
});

export const getColumns = () => [
    {
        field: 'name',
        headerName: 'Наименование группы',
        editable: true,
        width: 400
    }
]

export const getRows = (simCardGroupList: SimCardGroup[] | undefined): any => simCardGroupList?.map((simCardGroup) =>
    ({
        id: simCardGroup.id,
        name: simCardGroup.name
    })
)
