import {deleteContact} from "../../api";
import {Modal} from "react-bootstrap-v5";
import React, {useEffect, useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import {ExtendedModalProps} from "../../models/Modal";
import Alert from "../../../../components/alerts/Alert";

interface IDeleteContactFormProps extends ExtendedModalProps {
    contactIdList: number[]
}

/**
 * Форма удаления комментариев
 */
export const DeleteContactForm = ({contactIdList, show, handleClose}: IDeleteContactFormProps) => {
    const [message, setMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(() => {
        const getResponse = async () => {
            if (contactIdList && contactIdList.length !== 0) {
                let countDown = contactIdList.length
                for (const contactId of contactIdList) {
                    const response = await deleteContact(contactId.toString())
                    switch (response.status) {
                        case "ok":
                            if (--countDown === 0) {
                                handleClose({isSubmitted})
                            }
                            continue;
                        case "error":
                            console.log(response)
                            setMessage("Сервер не смог обработать запрос. Попробуйте удалить контакты позднее.")
                            return false;
                    }
                }
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])   // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted})
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-content'>
                <div className='modal-header py-4'>
                    <h3>Удаление контактов</h3>

                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body pt-5 px-10 center'>
                    <div className="d-flex justify-content-between mb-5">
                        Подтвердите удаление выбранных контактов
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary" onClick={() => setIsSubmitted(true)}>Подтвердить</button>
                    </div>
                    <div>
                        {message && <Alert message={message}/>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
