import {useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {DeactivateServicesForm} from "./DeactivateServicesForm";

export interface IDeactivateServicesProps {
    msisdn: number
    serviceIdList: number[]
    onFinish: () => void
}

/**
 * Деактивация услуг
 */
export function DeactivateServices({msisdn, serviceIdList = [], onFinish}: IDeactivateServicesProps) {
    const [show, setShow] = useState(false)

    const closeModal = () => {
        setShow(false)
        onFinish()
    }

    return (
        <>
        <span className="d-flex align-items-center text-gray-600 fs-6 mt-3" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
            <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
            <span>Отключить</span>
        </span>
            {show &&
            <DeactivateServicesForm msisdn={msisdn} serviceIdList={serviceIdList} show={show} handleClose={closeModal}/>
            }
        </>
    )
}
