import {updateGeoPosition} from "../../../api";
import React, {useEffect, useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {Modal} from "react-bootstrap-v5";
import Alert from "../../../../../components/alerts/Alert";
import {IGeoPositionProps} from "./GeoPosition";

interface IGeoPositionFormProps extends IGeoPositionProps {
    show: boolean
    handleClose: (updatedGeoPosition?: string | undefined) => void
}

/**
 * Форма для задания местоположения устройства
 */
export const GeoPositionForm = ({msisdn, geoPosition, show, handleClose}: IGeoPositionFormProps) => {
    const [message, setMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [updatedGeoPosition, setUpdatedGeoPosition] = useState(geoPosition)

    useEffect(() => {
        const getResponse = async () => {
            if (msisdn) {
                const response = await updateGeoPosition(msisdn, updatedGeoPosition || '')
                switch (response.status) {
                    case "ok":
                        return handleClose(updatedGeoPosition)
                    case "error":
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте выполнить операцию позднее.")
                        return false
                }
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-header py-4'>
                <h3>Геолокация устройства</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => handleClose()}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-10 center'>
                <div className="d-flex justify-content-between mb-5">
                        <textarea
                            className="form-control form-control-solid"
                            value={updatedGeoPosition !== undefined ? updatedGeoPosition : geoPosition}
                            style={{width: "100%", height: "80px"}}
                            onFocus={(event) => event.stopPropagation()}
                            onInput={(event) => setUpdatedGeoPosition(event.currentTarget.value)}
                        >
                        </textarea>
                </div>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={() => setIsSubmitted(true)}>Сохранить</button>
                </div>
                <div>
                    {message && <Alert message={message}/>}
                </div>
            </div>
        </Modal>
    )
}
