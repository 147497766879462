import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

/*
 * Глобальные настройки приложения
 */
export interface ISettingsState {
    simCardListPage: {
        // Сохраняем настройки видимости колонок после их изменения вручную
        columnVisibility?: { hide: boolean | undefined }[]
    }
}

/*
 * Настройки по умолчанию
 */
const initialSettingsState: ISettingsState = {
    simCardListPage: {
        columnVisibility: undefined,
    },
}

/*
 * Типы экшнов настроек
 */
export const actionTypes = {
    SaveSettings: '[Save Settings] Action',
}

/*
 * Генераторы экшнов настроек
 */
export const actions = {
    saveSettings: (settings: ISettingsState) => ({
        type: actionTypes.SaveSettings,
        payload: settings
    }),
}

/**
 * Редьюсер экшнов настроек
 */
const settingsReducer = (state: ISettingsState = initialSettingsState, action: ActionWithPayload<ISettingsState>) => {
    switch (action.type) {
        case actionTypes.SaveSettings: {
            return {
                simCardListPage: {
                    columnVisibility: action.payload?.simCardListPage?.columnVisibility,
                }
            }
        }

        default:
            return state
    }
}

/**
 * Хранимый редьюсер экшнов настроек
 */
export const settingsPersistReducer = persistReducer(
    {
        storage,
        key: 'm2m.ekomobile.ru.settings',
        whitelist: ['simCardListPage']
    },
    settingsReducer
)
