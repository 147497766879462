import React from "react";
import {Activate} from "./activate/Activate";
import {SetSingleStatus} from "./setStatus/SetSingleStatus";
import {SimCard} from "../../models/Models";

interface IInnerButtonsProps {
    msisdn: string | undefined
    cardInfo: SimCard | undefined
    onFinish: () => void
}

/**
 * Внутренние кнопки
 */
export const InnerButtons = ({msisdn, cardInfo, onFinish}: IInnerButtonsProps) => {
    return (
        <div>
            {cardInfo &&
                <>
                    <SetSingleStatus
                        msisdn={msisdn}
                        status={cardInfo?.status}
                        statusChangeAllowed={cardInfo?.flag_can_status_change === 1}
                        onFinish={onFinish}
                    />
                    <Activate
                        msisdn={msisdn}
                        notActivated={!cardInfo?.activation_date}
                        onFinish={onFinish}
                    />
                </>
            }
        </div>
    )
}
