import {useState} from "react";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {DeleteLimitForm} from "./DeleteLimitForm";
import {IHandleCloseProps} from "../../../models/Modal";

export interface IDeleteLimitProps {
    limitIdList: number[]
    onFinish: () => void
}

/**
 * Удаление лимитных правил
 */
export function DeleteLimit({limitIdList = [], onFinish}: IDeleteLimitProps) {
    const [show, setShow] = useState(false)

    const closeModal = ({isSubmitted}: IHandleCloseProps) => {
        setShow(false)
        isSubmitted && onFinish()
    }

    return (
        <>
            <div className="px-5 mt-6">
                <div>
                <span className="d-flex align-items-center text-gray-600 fs-6" style={{cursor: "pointer"}} onClick={() => setShow(true)}>
                    <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon svg-icon-1 me-3 mx-3'/>
                    <span>Удалить лимиты</span>
                </span>
                </div>
                {show &&
                <DeleteLimitForm limitIdList={limitIdList} show={show} handleClose={closeModal}/>
                }
            </div>
        </>
    )
}
