import {setStatus} from "../../api";
import React, {useEffect, useState} from "react";
import {ModalProps} from "../../models/Modal";
import {Modal, Spinner, Table} from "react-bootstrap-v5";
import {KTSVG} from "../../../../../_metronic/helpers";
import {SimCardStatus} from "../../models/SimCardStatus";
import {formatPhoneNumberFrom10Digits} from "../../../../helpers/Formatter";
import Alert from "../../../../components/alerts/Alert";
import {SimCard} from "../../models/Models";
import {AlertType} from "../../../../components/alerts";

interface ISetStatusModalProps extends ModalProps {
    selectedRows: Partial<SimCard>[]
}

/**
 * Модальное окно для установки/снятия блокировки номера (SIM-карты)
 */
export const SetMultipleStatusForm = ({selectedRows, onChange, show, handleClose}: ISetStatusModalProps) => {
    const [message, setMessage] = useState('')
    const [activityStatus, setActivityStatus] = useState<SimCardStatus>(SimCardStatus.ACTIVE)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [responseMap, setResponseMap] = useState<Map<number, any>>(new Map())

    useEffect(() => {
        /**
         * Установка статуса
         */
        const setSimCardStatus = async (msisdnList: number, status: SimCardStatus) => {
            const response = await setStatus(msisdnList, status)
            switch (response.status) {
                case "ok":
                    return SimCardStatus.getLabel(status === SimCardStatus.ACTIVE ? SimCardStatus.ACTIVE : SimCardStatus.BLOCKED)
                default:
                    setMessage("При смене статуса некоторых номеров не был получен ответ от сервера. Проверить результат по этим номерам можно в основной таблице и при необходимости выполнить смену повторно.")
                    return <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon svg-icon-1 svg-icon-danger me-3'/>
            }
        }

        /**
         * Получение ответа от сервера по всем номерам
         */
        const getResponse = () => {
            setResponseMap(new Map());
            selectedRows.map(async (simCard) => {
                if (simCard.flag_can_status_change) {
                    let response = await setSimCardStatus(simCard.msisdn!, activityStatus)
                    setResponseMap((prevMap) => prevMap.set(simCard.msisdn!, response))
                }
            })
            // При отсутствии ошибок закрываем окно
            message === "" && handleClose()
            // Обновляем таблицу
            onChange && onChange()
        }

        isSubmitted && getResponse()
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Перед отправкой SMS показываем пустую таблицу с номерами
     */
    const tableBodyBeforeSubmit = () => {
        return selectedRows.map((simCard, index) => {
            return <tr key={index}>
                <td className="fs-4">{formatPhoneNumberFrom10Digits(simCard.msisdn)}</td>
                <td className="fs-4 text-center w-150px">
                    {
                        simCard.flag_can_status_change === 1
                            ? ''
                            : <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon svg-icon-1 svg-icon-danger me-3'/>
                    }
                </td>
            </tr>
        })
    }

    /**
     * После сабмита заполняем таблицу результатами ответа сервера
     */
    const tableBodyAfterSubmit = () => {
        return selectedRows.map((simCard, index) => {
            return <tr key={index}>
                <td className="fs-4">{formatPhoneNumberFrom10Digits(simCard.msisdn)}</td>
                <td className="fs-4 text-center w-150px">
                    {
                        simCard.flag_can_status_change === 1
                            ? responseMap.get(simCard.msisdn!) || <Spinner className="text-gray-600" animation='border' style={{width: "1.5rem", height: "1.5rem"}}/>
                            : <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon svg-icon-1 svg-icon-danger me-3'/>
                    }
                </td>
            </tr>
        })
    }

    const ifMsisdnWhichStatusCannotBeChanged = () => selectedRows.find((item) => item.flag_can_status_change === 0) !== undefined

    const onSelectStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActivityStatus(event.currentTarget.value as SimCardStatus)
        setIsSubmitted(false)
        setSubmitDisabled(false)
    }

    const submit = () => {
        setIsSubmitted(true)
        setSubmitDisabled(true)
    }

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
        setSubmitDisabled(false)
    }

    /**
     * Содержимое модального окна
     */
    const ModalBodyContent = () => {
        return (
            <>
                <div className="d-flex justify-content-evenly align-items-center mb-5">
                    <input
                        type='radio'
                        className='btn-check'
                        name='activityStatus'
                        id='activityStatusSetterActive'
                        value='active'
                        onFocus={(event) => event.stopPropagation()}
                        onChange={onSelectStatus}
                        checked={activityStatus === 'active'}
                    />
                    <label
                        className={`btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-4 m-2 ${
                            activityStatus === 'active' ? 'active' : ''
                        }`}
                        htmlFor='activityStatusSetterActive'
                    >
                        <span className='text-gray-800 fw-bold'>Активен</span>
                    </label>
                    <input
                        type='radio'
                        className='btn-check'
                        name='activityStatus'
                        id='activityStatusSetterBlocked'
                        value='blocked'
                        onFocus={(event) => event.stopPropagation()}
                        onChange={onSelectStatus}
                        checked={activityStatus === 'blocked'}
                    />
                    <label
                        className={`btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-4 m-2 ${
                            activityStatus === 'blocked' ? 'active' : ''
                        }`}
                        htmlFor='activityStatusSetterBlocked'
                    >
                        <span className='text-gray-800 fw-bold'>Блокирован</span>
                    </label>
                    <div>
                        <button className="btn btn-primary px-4 mx-3" onClick={submit} disabled={submitDisabled}>Сохранить</button>
                    </div>
                </div>
                <Table striped bordered className="gs-5">
                    <tbody>
                    {!isSubmitted
                        ? selectedRows.length !== 0 && tableBodyBeforeSubmit()
                        : selectedRows.length !== 0 && tableBodyAfterSubmit()
                    }
                    </tbody>
                </Table>
                {message && <Alert message={message}/>}
            </>
        )
    }

    return (
        <Modal
            className='modal fade'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-400px"
            onShow={reset}
            onHide={handleClose}
        >
            <div className='modal-header py-4'>
                <h3>Блокировка номеров</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-10'>
                <ModalBodyContent/>
                {
                    ifMsisdnWhichStatusCannotBeChanged() &&
                    <Alert type={AlertType.WARNING} message='Для блокировки/разблокировки некоторых номеров необходимо обратиться в контактный центр по номеру 0522'/>
                }
            </div>
        </Modal>
    )
}
