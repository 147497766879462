import {createGroup} from "../../api";
import {Modal} from "react-bootstrap-v5";
import React, {useEffect, useState} from "react";
import {KTSVG} from "../../../../../_metronic/helpers";
import Alert from "../../../../components/alerts/Alert";
import {ExtendedModalProps} from "../../models/Modal";

interface ICreateGroupFormProps extends ExtendedModalProps {
    groupName: string
}

/**
 * Форма комментария во всплывающем окне
 */
export const CreateGroupForm = ({groupName, show, handleClose}: ICreateGroupFormProps) => {
    const [message, setMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [updatedGroupName, setUpdatedGroupName] = useState(null)

    useEffect(() => {
        const getResponse = async () => {
            if (updatedGroupName) {
                const response = await createGroup(updatedGroupName)
                switch (response.status) {
                    case "ok":
                        return handleClose({isSubmitted, updatedGroupName})
                    case "error":
                        console.log(response)
                        setMessage("Сервер не смог обработать запрос. Попробуйте создать группу позднее.")
                        return false
                }
            }
        }

        isSubmitted && getResponse()
    }, [isSubmitted])  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Сброс формы при открытии модального окна
     */
    const reset = () => {
        setMessage('')
        setIsSubmitted(false)
    }

    /**
     * Предотвращение лишних запросов при обычном закрытии окна
     */
    const optimizedModalCloser = () => {
        handleClose({isSubmitted, updatedGroupName})
    }

    return (
        <Modal
            className='modal'
            role='dialog'
            aria-hidden='true'
            tabIndex='-1'
            show={show}
            dialogClassName="modal-dialog modal-dialog-scrollable w-350px"
            onShow={reset}
            onHide={optimizedModalCloser}
        >
            <div className='modal-header py-4'>
                <h3>Создание группы</h3>

                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={optimizedModalCloser}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                </div>
            </div>

            <div className='modal-body pt-5 px-10 center'>
                <div className="d-flex justify-content-between mb-5">
                    <input
                        className="form-control form-control-solid"
                        value={updatedGroupName || groupName}
                        style={{marginRight: "5px"}}
                        onFocus={(event) => event.stopPropagation()}
                        onInput={(event) => setUpdatedGroupName(event.currentTarget.value as any)}
                    >
                    </input>
                </div>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={() => setIsSubmitted(true)}>Создать</button>
                </div>
                <div>
                    {message && <Alert message={message}/>}
                </div>
            </div>
        </Modal>
    )
}
